import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, CardContent, Grid, Typography } from '@mui/material';
import Table from '../../../components/common/Table';
import { getList } from '../../../services/admin/integrationLogs';
import Loader from '../../../components/common/Loader';
import moment from 'moment';
import FilterPopper from '../../../components/common/FilterPopper';
import ActionMenu from '../../../components/common/ActionMenu';
import { Visibility } from '@mui/icons-material';
import LogDetailsDrawer from '../../../components/admin/integrationLogs/LogDetailsDrawer';

const IntgerationLogs = () => {
  const { admin } = useSelector((state) => state?.authSlice);
  const [loading, setLoading] = useState(false);
  const [perPageRecords, setPerPageRecords] = useState(100);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [anchor1, setAnchor1] = useState(null);
  const [anchor2, setAnchor2] = useState(null);
  const [anchor3, setAnchor3] = useState(null);
  const [dateRange, setDateRange] = useState([{ startDate: '', endDate: '', key: 'selection' }]);
  const [isDateAdded, setIsDateAdded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [curRecord, setCurRecord] = useState({});

  const tableData = useMemo(() => {
    if (list?.length) {
      return list?.map((log) => {
        return {
          ...log,
          actions: (props) => (
            <div>
              <ActionMenu
                actions={[
                  {
                    label: `View Log Details`,
                    logo: <Visibility className='action-menu-item' />,
                    onClick: () => {
                      setCurRecord(log);
                      setVisible(true);
                    },
                  },
                ]}
              />
            </div>
          ),

          log_date: () => (
            <div>{moment(log?.activity_timestamp).format('MM/DD/YYYY HH:mm:ss A')}</div>
          ),
        };
      });
    }
    return [];
  }, [list]);

  useEffect(() => {
    setTimeout(() => {
      if (
        dateRange[0]?.startDate &&
        dateRange[0]?.startDate !== 'Invalid date' &&
        dateRange[0]?.endDate &&
        dateRange[0]?.endDate !== 'Invalid date'
      ) {
        setIsDateAdded(true);
        fetchList();
      } else {
        setIsDateAdded(false);
      }
    }, [1000]);
  }, [dateRange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (Object.keys(filters || {})?.length) {
        fetchList();
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [filters]);

  useEffect(() => {
    fetchList();
  }, [currPage, perPageRecords]);

  const fetchList = async (isClear = false) => {
    setLoading(true);
    let str = `page=${currPage + 1}&per_page=${perPageRecords}&is_listing=yes`;
    Object.keys(filters).forEach((key) => {
      if (filters?.[key]?.length) {
        str = str + `&${key}=${encodeURIComponent(filters?.[key])}`;
      }
    });
    if (
      dateRange[0]?.startDate &&
      dateRange[0]?.startDate !== 'Invalid date' &&
      dateRange[0]?.endDate &&
      dateRange[0]?.endDate !== 'Invalid date' &&
      !isClear
    ) {
      str =
        str +
        `&log_date_from=${moment(
          dateRange?.[0]?.startDate,
          'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
        ).format('YYYY-MM-DD')}&log_date_to=${moment(
          dateRange?.[0]?.endDate,
          'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
        ).format('YYYY-MM-DD')}`;
    }
    const res = await getList(admin?.token, str);
    if (res?.success) {
      // setList(res?.response || []);
      setList(res?.response?.data || []);
      setTotal(res?.response?.meta?.total);
    } else {
      setList([]);
      setTotal(0);
    }
    setLoading(false);
  };
  const columns = [
    { id: 'actions', label: 'Actions', type: 'jsx' },

    {
      id: 'purpose',
      label: 'Purpose',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Purpose</div>
            <FilterPopper
              anchor={anchor1}
              setAnchor={setAnchor1}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='text'
            />
          </div>
        );
      },
    },
    {
      id: 'escrow_num',
      label: 'Escrow No.',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Escrow No.</div>
            <FilterPopper
              anchor={anchor3}
              setAnchor={setAnchor3}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='text'
            />
          </div>
        );
      },
    },
    {
      id: 'api_endpoint',
      label: 'API Endpoint',
    },
    {
      id: 'method',
      label: 'Method',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'status_code',
      label: 'Status Code',
    },
    {
      id: 'log_date',
      label: 'Log Date',
      type: 'jsx',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Activity Date</div>
            <FilterPopper
              anchor={anchor2}
              setAnchor={setAnchor2}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              dateRange={dateRange}
              setDateRange={setDateRange}
              fetchFormData={fetchList}
              type='date'
              isDateAdded={isDateAdded}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Box className='dashboard-main'>
      <LogDetailsDrawer
        visible={visible}
        setVisible={setVisible}
        curRecord={curRecord}
        setLoading={setLoading}
      />
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center'>
                <Typography variant='h4' className='mb-0'>
                  Integration Logs
                </Typography>
              </Grid>
            </CardContent>
            <Box>
              <CardContent>
                <Table
                  loader={loading}
                  columns={columns}
                  data={tableData}
                  count={total}
                  current_page={currPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                  isClickable={true}
                  onRowClick={(row) => {
                    setCurRecord(row);
                    setVisible(true);
                  }}
                  rowClassNames={(row) => {
                    return row?.integration_log_id === curRecord?.integration_log_id
                      ? 'highlighted-row'
                      : '';
                  }}
                />
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default IntgerationLogs;
