import { authorizedApi } from '../../utils/api';

export const getParticipantType = (token, clientId, officeId) => {
  let url = `/participant/types?`;
  if (clientId) url += `client_id=${clientId}`;
  if (officeId) url += `&client_office_id=${officeId}`;
  return authorizedApi(token).get(url);
};

export const getParticipantTypeById = (id, token) => {
  return authorizedApi(token).get(`/admin/participant/types/${id}`);
};

export const addParticipantType = (data, token) => {
  return authorizedApi(token).post(`/admin/participant/types`, data);
};

export const updateParticipantType = (id, data, token) => {
  return authorizedApi(token).put(`/admin/participant/types/${id}`, data);
};

export const deleteParticipantType = (id, token) => {
  return authorizedApi(token).delete(`/admin/participant/types/${id}`);
};
