import { authorizedApi } from '../../utils/api';

export const getList = (token, str) => {
  return authorizedApi(token).get(`/admin/reports?${str}`);
};


export const getListPHH = (token, str, clientId) => {
  return authorizedApi(token).get(`/admin/client/reports/${clientId}?${str}`);
};
