import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

const Breadcrumb = () => {
  const location = useLocation();
  const breadcrumb = useSelector((state) => state?.breadcrumbSlice)?.[location?.pathname];
  const navigate = useNavigate();
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
      {breadcrumb?.length
        ? breadcrumb?.map((crumb, index) => {
            return (
              <Link
                key={index}
                color={index === breadcrumb?.length - 1 ? 'inherit' : 'primary'}
                onClick={(e) => {
                  index === breadcrumb?.length - 1 ? e.preventDefault() : navigate(crumb?.url);
                }}
              >
                <span style={{ fontWeight: '600' }} className='bread-title'>
                  {crumb?.title}
                </span>
              </Link>
            );
          })
        : ''}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
