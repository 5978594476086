// authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    login: (state, action) => {
      const { data, code } = action.payload;
      state[code] = data;
    },
    logout: (state, action) => {
      const { code, url } = action.payload;
      state[code] = {};
      state['order'] = {};
      state['admin'] = {};
      if ((code === 'admin' || code === 'order') && state?.configuration?.[url])
        delete state?.configuration?.[url];
    },
    clearParticipant: (state, action) => {
      let tempState = {};
      tempState['order'] = state?.['order'];
      tempState['admin'] = state?.['admin'];
      tempState['configuration'] = state?.['configuration'];
      return tempState;
    },
    configuration: (state, action) => {
      const { url, data } = action.payload;
      state.configuration = { ...state.configuration, [url]: data };
    },
  },
});

export const { login, logout, configuration, clearParticipant } = authSlice.actions;
export default authSlice.reducer;
