import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Grid,
  CardContent,
  Box,
  Typography,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { addAdmin, updateAdmin, getAdmin } from '../../../services/admin/adminUsers';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/slices/breadcrumbSlice';
import { getTimeZones } from '../../../services/admin/users';

const AddEditAdmin = () => {
  const { state, pathname } = useLocation();
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state?.authSlice);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [adminDetails, setAdminDetails] = useState({});
  const [timeZones, setTimeZones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);

  useEffect(() => {
    if (id) fetchAdmin();
  }, [id]);

  useEffect(() => {
    fetchTimeZones();
  }, []);

  useEffect(() => {
    if (adminDetails?.first_name) {
      dispatch(
        setBreadcrumb({
          data: [
            {
              title: 'Admins',
              url: '/admin/list',
            },
            {
              title: adminDetails?.first_name + ' ' + adminDetails?.last_name,
              url: '/admin/admin/:id',
            },
          ],
          key: pathname,
        })
      );
    }
  }, [adminDetails?.first_name]);

  const schema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    password: id
      ? ''
      : yup
          .string()
          .required('Password is required')
          .min(6, 'Password must be at least 6 characters'),
    status: yup.string().required('Status is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchAdmin = async () => {
    setLoading(true);
    reset();
    const res = await getAdmin(id, admin?.token);
    if (res.success) {
      setAdminDetails(res.response);
      setSelectedTimeZone(res?.response?.user_timezone);
      reset(res.response);
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const fetchTimeZones = async () => {
    setLoading(true);
    const res = await getTimeZones(admin?.token);
    if (Object.keys(res || {})?.length) {
      setTimeZones(Object.keys(res || {})?.map((tz) => res[tz]) || []);
      if (!id) setSelectedTimeZone(res?.[0]);
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    if (!selectedTimeZone) return;
    setLoading(true);
    const res = id
      ? await updateAdmin(id, { ...data, timezone: selectedTimeZone }, admin?.token)
      : await addAdmin({ ...data, timezone: selectedTimeZone }, admin?.token);
    if (res?.success) {
      toast.success(res?.message);
      if (id) {
        fetchAdmin();
      } else {
        navigate(`/admin/list`);
      }
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <Grid item md={12} className='d-flex justify-between align-center sub-header'>
              <Typography variant='h4' className='mb-0'>
                {id ? (state?.disabled ? 'View Admin' : 'Edit Admin') : 'Add Admin'}
              </Typography>
            </Grid>
            <Box sx={{ mt: 2 }} className='client-user-form'>
              <CardContent>
                <form onSubmit={handleSubmit(onSubmit)} className='user-form'>
                  <fieldset
                    disabled={state?.disabled ? true : false}
                    className='m-0 p-0 border-none'
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <label>First Name</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='first_name'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter first name'
                              variant='outlined'
                              fullWidth
                              error={!!errors.first_name}
                              helperText={errors.first_name?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>Last Name</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='last_name'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter last name'
                              variant='outlined'
                              fullWidth
                              error={!!errors.last_name}
                              helperText={errors.last_name?.message}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <label>Email</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='email'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter email'
                              variant='outlined'
                              fullWidth
                              error={!!errors.email}
                              helperText={errors.email?.message}
                              autoComplete='off'
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} display={id ? 'none' : ''}>
                        <label>Password</label>
                        {!id && <label className='required'>{'*'}</label>}
                        <Controller
                          name='password'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={id ? true : false}
                              placeholder='Enter password'
                              autoComplete='new-password'
                              variant='outlined'
                              fullWidth
                              type={togglePassword ? 'text' : 'password'}
                              error={!!errors.password}
                              helperText={errors.password?.message}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    {!togglePassword ? (
                                      <Visibility
                                        fontSize={'small'}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setTogglePassword((toggle) => !toggle)}
                                      />
                                    ) : (
                                      <VisibilityOff
                                        fontSize={'small'}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setTogglePassword((toggle) => !toggle)}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>Timezone</label>
                        <label className='required'>{'*'}</label>

                        <FormControl fullWidth variant='outlined' error={!!errors.status}>
                          <Select
                            disabled={state?.disabled ? true : false}
                            onChange={(e) => {
                              setSelectedTimeZone(e.target.value);
                            }}
                            value={selectedTimeZone}
                          >
                            {timeZones?.map((tz) => (
                              <MenuItem value={tz} key={tz}>
                                {tz}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {!loading && !selectedTimeZone ? (
                          <span className='error f-12 mt-4' style={{ display: 'inherit' }}>
                            Timezone field is required.
                          </span>
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <label>Status</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='status'
                          control={control}
                          defaultValue='active'
                          render={({ field }) => (
                            <FormControl fullWidth variant='outlined' error={!!errors.status}>
                              <Select {...field} disabled={state?.disabled ? true : false}>
                                <MenuItem value='active'>Active</MenuItem>
                                <MenuItem value='inactive'>Inactive</MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </fieldset>
                  <Grid item xs={12}>
                    <div className='d-flex justify-end mt-16'>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                          reset();
                          navigate('/admin/list');
                        }}
                        className='mr-8'
                      >
                        Cancel
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={state?.disabled ? true : false}
                      >
                        {'Save'}
                      </Button>
                    </div>
                  </Grid>
                </form>
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddEditAdmin;
