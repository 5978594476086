import { Box, Card, Stack, Typography } from '@mui/material';
import { ManageAccounts } from '@mui/icons-material';
const CardWidget = ({
  title = 'Test card',
  total = '120',
  icon = <ManageAccounts />,
  color = 'primary',
  iconBg = 'primary',
  sx,
  ...other
}) => {
  return (
    <Card
      component={Stack}
      spacing={3}
      direction='row'
      sx={{
        p: 2,
        borderRadius: 2,
        backgroundColor: color,
        display: 'flex',
        justifyContent: 'center',
        width: '200px',
        ...sx,
      }}
      {...other}
    >
      <div className='d-flex flex-column'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              background: iconBg,
              width: 'fit-content',
              padding: '10px 12px',
              borderRadius: '49%',
              marginBottom: '10px',
            }}
          >
            {icon}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px',
              fontSize: '20px',
              fontWeight: '600',
            }}
          >
            {total}
          </Box>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Box
            variant='subtitle2'
            sx={{
              color: '#666262',
              display: 'flex',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: '550',
              width: 'max-content',
            }}
          >
            {title}
          </Box>
        </div>
      </div>
    </Card>
  );
};

export default CardWidget;
