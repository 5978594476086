import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SectionTabs from '../../../components/admin/templates/SectionTabs';
import GroupTabs from '../../../components/admin/templates/GroupTabs';
import ManageSectionDrawer from '../../../components/admin/templates/ManageSectionDrawer';
import ManageGroupDrawer from '../../../components/admin/templates/ManageGroupsDrawer';
import CreateQuestionsForm from '../../../components/admin/templates/CreateQuestionsForm';
import { getTemplate, updateTemplate } from '../../../services/admin/templates';
import Loader from '../../../components/common/Loader';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/slices/breadcrumbSlice';
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../../../utils/constants';

const TemplateForm = () => {
  const createQuestionFormRef = useRef();
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state?.authSlice);
  const isAdmin =
    admin?.user_role_name === SUPER_ADMIN_ROLE || admin?.user_role_name === ADMIN_ROLE
      ? true
      : false;
  const { id, clientId } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [currentSection, setCurrentSection] = useState(0);
  const [data, setData] = useState({});
  const [templateDetails, setTemplateDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(0);
  const [manageSectionDrawer, setManageSectionDrawer] = useState(false);
  const [manageGroupDrawer, setManageGroupDrawer] = useState(false);

  useEffect(() => {
    if (id) fetchTemplate();
  }, [id]);

  useEffect(() => {
    if (templateDetails?.participant_type_name) {
      if (clientId) {
        dispatch(
          setBreadcrumb({
            data: [
              {
                title: 'Clients',
                url: '/admin/clients',
              },
              {
                title: 'Form Configurations',
                url: `/admin/clients/templates/${clientId}`,
              },
              {
                title: templateDetails?.participant_type_name,
                url: '/admin/clients/templates/:clientId/:id',
              },
            ],
            key: pathname,
          })
        );
      } else {
        dispatch(
          setBreadcrumb({
            data: [
              {
                title: 'Form Configurations',
                url: '/admin/templates',
              },
              {
                title: templateDetails?.participant_type_name,
                url: `/admin/templates/:id`,
              },
            ],
            key: pathname,
          })
        );
      }
    }
  }, [templateDetails?.participant_type_name]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(
        setBreadcrumb({
          data: [
            {
              title: 'Clients',
              url: '/admin/clients',
            },
            {
              title: 'Form Configurations',
              url: `/admin/clients/templates/${clientId}`,
            },
            {
              title: templateDetails?.participant_type_name,
              url: '/admin/clients/templates/:clientId/:id',
            },
          ],
          key: pathname,
        })
      );
    }
  }, [isAdmin]);

  const fetchTemplate = async () => {
    setLoading(true);
    const res = await getTemplate(id, admin?.token);
    if (res?.success) {
      setData(res?.response?.template_json?.question);
      delete res?.response?.template_json;
      setTemplateDetails(res?.response);
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const onTabChange = () => {
    const isValidated = createQuestionFormRef?.current?.validateCurrentElements();
    if (!isValidated) {
      return false;
    }
    createQuestionFormRef?.current?.convertToElementData();
    return true;
  };
  const handleSave = async () => {
    const isValidated = createQuestionFormRef?.current?.validateCurrentElements();
    if (!isValidated) {
      return false;
    }
    setLoading(true);
    await createQuestionFormRef?.current?.convertToElementData();
    const bodyData = {
      user_participant_type_id: templateDetails?.participant_type_id,
      template_json: { question: data },
    };
    const res = await updateTemplate(id, bodyData, admin?.token);
    if (res.success) {
      await fetchTemplate();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <SectionTabs
              data={data}
              setData={setData}
              current={currentSection}
              setCurrent={setCurrentSection}
              setCurrentGroup={setCurrentGroup}
              setManageSectionDrawer={setManageSectionDrawer}
              onTabChange={onTabChange}
            />
            <GroupTabs
              groups={data?.sections?.[currentSection]?.groups?.map((grp) => grp.group_label)}
              current={currentGroup}
              setCurrent={setCurrentGroup}
              setManageGroupDrawer={setManageGroupDrawer}
              onTabChange={onTabChange}
              toShow={data?.sections?.length ? true : false}
            />
            <CreateQuestionsForm
              data={data?.sections?.[currentSection]?.groups?.[currentGroup]?.elements || []}
              setData={setData}
              currentGroup={currentGroup}
              currentSection={currentSection}
              details={data}
              templateDetails={templateDetails}
              ref={createQuestionFormRef}
            />
            {data?.sections?.length ? (
              <div className='d-flex justify-end mt-8'>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => {
                    navigate(-1);
                  }}
                  className='mr-8'
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </Grid>
      </Grid>
      <ManageSectionDrawer
        sections={data?.sections?.length ? data?.sections : []}
        visible={manageSectionDrawer}
        setVisible={setManageSectionDrawer}
        setData={setData}
        setCurrentSection={setCurrentSection}
        setCurrentGroup={setCurrentGroup}
      />
      <ManageGroupDrawer
        groups={
          data?.sections?.[currentSection]?.groups?.length
            ? data?.sections?.[currentSection]?.groups
            : []
        }
        visible={manageGroupDrawer}
        setVisible={setManageGroupDrawer}
        currentSection={currentSection}
        setData={setData}
        setCurrentGroup={setCurrentGroup}
      />
    </Box>
  );
};
export default TemplateForm;
