import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, styled, Button, Dialog, Box, CircularProgress, Grid } from '@mui/material';
import { css } from '@mui/system';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { toast } from 'react-toastify';
import logo from '../../../assets/images/Voxtur-verify.png';
import { downloadDocument } from '../../../services/questions/home';
import {
  MARITAL_STATUS_GROUP,
  SOI_SECTION_ID,
  SPOUSE_ADDED_FIELD_ID,
} from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import MediaLink from '../../common/fields/media-link';

const SplashTitle = styled('div')(css`
  font-weight: 900;
  font-size: 50px;
  display: inline-block;
  text-align: right;
  width: 100%;
  margin-top: 48px;
  span {
    display: inline-block;
    background: linear-gradient(
      to right,
      #65a8e7,
      #88ddd5,
      #1ab9e9,
      #b4bf97,
      #a9989d,
      #b77a97,
      #ecb09e,
      #c77fa8,
      #8e64ae
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-right: 50px;
    font-weight: 100;
    text-align: center;
    margin: 0 auto;
  }
  small {
    text-align: right;
    letter-spacing: -1px;
    font-size: 14px;
    display: block;
    font-weight: 500;
    padding: 0px 5px;
    margin-top: -12px;
  }
`);

const SplashBody = styled('div')(
  ({ theme }) => css`
    text-align: left;
    h4 {
      text-align: left;
      margin-top: 36px;
      font-weight: 700;
      font-size: 1.2rem;
    }
    p {
      margin-top: 4px;
      margin-bottom: 25px;
      font-size: 15px;
    }
  `
);

const SplashFooter = styled('div')(css`
  background-color: #f1f2f7;
  text-align: center;
  padding-top: 12px;
  .MuiTypography-body1 {
    margin: 2px;
    font-weight: 500;
    font-size: 0.4rem;
  }
`);

const SplashButton = styled('div')(css`
  border-top: 2px solid #f0f0f0;
  border-bottom: 2px solid #e0e0e0;
  padding: 18px 36px;
  margin-top: 24px;
  button {
    padding: 12px;
    width: 100%;
    font-size: 16px;
  }
`);

const SplashMain = styled('div')(css`
  padding: 0 36px;
`);

const SubmissionStep = ({
  show,
  setShow,
  getQuestionData,
  isAdditionalSeller,
  logoUrl,
  companyName,
  docList,
  getDocs,
  setIsLoading,
}) => {
  const { t } = useTranslation();
  const { code } = useParams();
  const question = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.questions;
  const [loading, setLoading] = useState(false);
  const [isMarried, setIsMarried] = useState(false);
  const authData = useSelector((state) => state?.authSlice);
  const handleClose = () => {
    setShow(false);
  };
  const additionalParticipants = useSelector(
    (state) => state.questionSlice.additionalParticipants
  )?.find((que) => que.tx_id === code);

  useEffect(() => {
    const secIndex = question?.sections?.findIndex(
      (section) => section.section_id == SOI_SECTION_ID
    );
    if (secIndex != -1) {
      const grpIndex = question?.sections?.[secIndex]?.groups?.findIndex(
        (grp) => grp.group_id == MARITAL_STATUS_GROUP
      );
      if (grpIndex != -1) {
        const elem = question?.sections?.[secIndex]?.groups?.[grpIndex]?.elements?.find(
          (elem) => elem.element_id == SPOUSE_ADDED_FIELD_ID
        );
        if (elem?.user_value == 'Married') setIsMarried(true);
      }
    }
  }, []);

  const handleAction = async () => {
    setLoading(true);
    const urlData = await downloadDocument(code, authData?.[code]?.token);
    if (urlData?.success) {
      const link = document.createElement('a');
      link.href = urlData?.response?.file;
      link.setAttribute('download', 'Certificate.pdf');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      handleClose();
      await getQuestionData(code);
    } else {
      toast.error(urlData.message);
    }
    setLoading(false);
  };

  const msgText = (() => {
    if (isAdditionalSeller) {
      return t('submissionMessage1');
    } else if (isMarried || additionalParticipants?.value) {
      return `${t('submissionMessage2')} ${companyName}.`;
    } else {
      return t('submissionMessage1');
    }
  })();
  return (
    <>
      <Dialog open={show} className='submission_dialog' onClose={handleClose}>
        <SplashMain className='final-container'>
          <SplashTitle className='mt-20'>
            <Box variant='div' component='div' className='welcome-logo d-flex justify-center'>
              <img
                src={logoUrl ? logoUrl : logo}
                alt='logo-main'
                loading='lazy'
                className='logo-size'
              />
            </Box>
          </SplashTitle>
          <SplashBody>
            <Typography variant='h4' className='head-txt mt-12 mb-0 color-p f-18'>
              {t('congratulations')}
            </Typography>

            <Typography className='body-txt mb-0 mt-12 f-14 fw-550'>{msgText}</Typography>
            {docList?.length ? (
              <Typography className='body-txt mb-0 mt-12 f-14 fw-550 color-g'>
                {'Additional Documents'}
              </Typography>
            ) : (
              ''
            )}
            <div className='mt-12 mb-8'>
              {docList?.map((doc, index) => {
                return (
                  <div>
                    <Grid container className='p-8'>
                      <Grid item md={9} className='f-13 fw-500' borderRight={'0px'}>
                        <MediaLink
                          element={{
                            label: doc?.label,
                            name: doc?.name,
                            disabled: false,
                          }}
                          getQuestionData={getQuestionData}
                          getDocs={getDocs}
                          skip={true}
                          setLoading={setIsLoading}
                        />
                      </Grid>
                    </Grid>
                    <hr className='mt-0 mb-0' style={{ backgroundColor: '#d2cece' }} />
                  </div>
                );
              })}
            </div>
          </SplashBody>
        </SplashMain>

        <SplashButton className='mt-12 final-btn'>
          <Button
            variant='contained'
            onClick={handleAction}
            className='start-btn'
            disabled={loading}
            endIcon={loading && <CircularProgress size={20} />}
          >
            {' '}
            {t('download')}
            <SimCardDownloadOutlinedIcon className='icon' />
          </Button>
        </SplashButton>
        <SplashFooter className='submission-footer'>
          {/* <img src={lightCycle} /> */}
          <Typography className='copy-right d-flex justify-center'>
            {t('submissionMessage3')}
          </Typography>
        </SplashFooter>
      </Dialog>
    </>
  );
};
export default SubmissionStep;
