import api, { authorizedApi } from '../../utils/api';

export const adminLogin = (data) => {
  return api().post(`/admin/login`, data);
};

export const userLogin = (data) => {
  return api().post(`/user/login`, data);
};

export const adminLogout = (token) => {
  return authorizedApi(token).post(`/user/logout`);
};

export const orderLogin = (data) => {
  return api().post(`/order/login`, data);
};

export const orderLogout = (token) => {
  return authorizedApi(token).post(`/user/logout`);
};

export const checkAuthToken = (data, token) => {
  return authorizedApi(token).post(`/verify-token`, data);
};

export const adminChangePassword = (data, token) => {
  return authorizedApi(token).post(`user/change/password`, data);
};

export const orderChangePassword = (data, token) => {
  return authorizedApi(token).post(`user/change/password`, data);
};

export const forgotPassword = (data) => {
  return api().post(`/forgot-password`, data);
};

export const resetForgotPassword = (data) => {
  return api().put(`/reset-password`, data);
};

export const getConfigurations = (data) => {
  return api().post(`/get/client/domain`, data);
};