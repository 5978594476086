import React, { useEffect, useState } from 'react';
import { Drawer, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const ManageGroupDrawer = (props) => {
  const [payload, setPayload] = useState([]);
  useEffect(() => {
    let tempPayload = [];
    let arr = props?.record?.question_form || [];
    if (arr?.length) {
      for (let que of arr) {
        for (let subPart of que?.participants) {
          let tempObj = {
            part_name: subPart?.participant_name ?? '-',
            part_type: que?.user_participant_type ?? '-',
            emailed_at: subPart?.created_date ?? '-',
            completed_at: subPart?.form_completed_date ?? '-',
            verified_at: que?.data_verified_at ?? '-',
            participant_number: subPart?.participant_number ?? 1,
            is_spouse: subPart?.is_spouse ? subPart?.is_spouse : false,
            display_type:
              subPart?.participant_number === 1
                ? que?.user_participant_type
                : subPart?.is_spouse
                ? 'Spouse of ' + que?.user_participant_type
                : 'Additional ' + que?.user_participant_type,
          };
          tempPayload.push(tempObj);
        }
      }
    }
    setPayload(tempPayload);
  }, [props?.record?.order_id, props.visible]);
  return (
    <Drawer
      sx={{
        width: 600,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 600,
          boxSizing: 'border-box',
        },
      }}
      anchor='right'
      open={props.visible}
      onClose={() => {
        props.setVisible(false);
      }}
    >
      <DrawerHeader>
        <Typography variant='h6'>Participant From Progress</Typography>
        <Close
          sx={{ position: 'absolute', right: '10px', top: '20px', color: 'grey' }}
          fontSize='small'
          onClick={() => {
            props.setVisible(false);
          }}
          className='cursor-pointer'
        />
      </DrawerHeader>
      <Divider />

      <div className='timeline mt-16'>
        {payload.map((part) => {
          return (
            <div className='mb-16'>
              <div className='f-16 fw-550 ml-16 mb-8'>
                {part?.part_name}({part?.display_type ? part?.display_type : ''})
              </div>
              <Timeline>
                <TimelineItem>
                  <TimelineOppositeContent
                    align='right'
                    minWidth={'150px'}
                    className='f-13 mr-0 p-6'
                  >
                    {part?.emailed_at ?? 'Pending'}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot>{/* <Email /> */}</TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent minWidth={'400px'} paddingRight={'50px'}>
                    <div className='f-14 fw-550'>Email Sent</div>
                    <div className='f-13'>
                      The email was sent to the participant with the invite link.
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    align='right'
                    minWidth={'150px'}
                    className='f-13 mr-0 p-6'
                  >
                    {part?.completed_at ? part?.completed_at : 'Pending'}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot>{/* <HowToReg /> */}</TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent minWidth={'400px'} paddingRight={'50px'}>
                    <div className='f-14 fw-550'>Form Completed</div>
                    <div className='f-13'>The participant completed filling out the form.</div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    align='right'
                    minWidth={'150px'}
                    className='f-13 mr-0 p-6'
                  >
                    {part?.verified_at ? part?.verified_at : 'Pending'}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot>{/* <VerifiedUser /> */}</TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent minWidth={'400px'} paddingRight={'50px'}>
                    <div className='f-14 fw-550'>Form Verified</div>
                    <div className='f-13'>
                      The admin user reviewed and verified the participant's inputs.
                    </div>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
          );
        })}
      </div>
    </Drawer>
  );
};

export default ManageGroupDrawer;
