import { authorizedApi } from '../../utils/api';

export const getAdmins = (token, currPage = 1, per_page = 10) => {
  return authorizedApi(token).get(`/admin/admin-user?page=${currPage}&per_page=${per_page}`);
};

export const getAdmin = (id, token) => {
  return authorizedApi(token).get(`/admin/admin-user/${id}`);
};

export const addAdmin = (data, token) => {
  return authorizedApi(token).post(`/admin/admin-user`, data);
};

export const updateAdmin = (id, data, token) => {
  return authorizedApi(token).put(`/admin/admin-user/${id}`, data);
};

export const deleteAdmin = (id, token) => {
  return authorizedApi(token).delete(`/admin/admin-user/${id}`);
};
