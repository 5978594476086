import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {
  return (
    <div>
      <div className='overlay'></div>
      <div className='spanner show' style={{ zIndex: '11111' }}>
        <div className='d-flex flex-column justify-center align-center loading-spinner'>
          <CircularProgress style={{ color: 'black' }} />
          <span className='mt-4'>Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Loader;
