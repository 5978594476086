import React, { useEffect, useState } from 'react';
import { Drawer, TextField, Grid, Typography, Divider, Button, Switch } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { arrayMoveImmutable } from 'array-move';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { Close } from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import { saveDocuments } from '../../../services/admin/configurations';
import { toast } from 'react-toastify';

const ManageDocumentDrawer = (props) => {
  const [documents, setDocuments] = useState(props?.record?.additional_docs || []);
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setDocuments(cloneDeep(props?.record?.additional_docs));
  }, [props?.record?.additional_docs, props.visible]);

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  const handleDocumentChange = (e, index) => {
    let tempDocuments = [...documents];
    tempDocuments[index].doc_name = e.target.value;
    setDocuments(tempDocuments);
  };
  const handleDocumentHideChange = (checked, index) => {
    let tempDocuments = [...documents];
    tempDocuments[index].is_hidden = checked;
    setDocuments(tempDocuments);
  };

  const handleDrag = (result) => {
    let tempDocuments = [...documents];
    if (tempDocuments.length > 1) {
      tempDocuments = arrayMoveImmutable(
        tempDocuments,
        result.source.index,
        result.destination.index
      );
      setDocuments(tempDocuments);
    }
  };

  const handleSave = async (data) => {
    props.setLoading(true);
    // Validation
    await trigger();
    if (Object.keys(errors || {})?.length) {
      props.setLoading(false);
      return;
    }
    let payload = {
      client_id: props?.clientId || '',
      user_participant_type_id: props.record?.user_participant_type_id,
      additional_docs: documents?.map((doc) => {
        return { id: doc.id, is_hidden: doc.is_hidden, doc_slug: doc.doc_slug };
      }),
    };
    if (props.selectedOffice && !props.isSame) payload.client_office_id = props.selectedOffice;
    const res = await saveDocuments(props.token, payload);
    if (res.success) {
      toast.success(res.message);
      props.setRecord({});
      props.setVisible(false);
      await props.fetchDocumentConfigurations();
    }
    props.setLoading(false);
  };

  const isUnique = (list, id, name) => {
    const filteredOptions = list?.filter((option) => option?.doc_slug !== id);
    return !filteredOptions?.some((option) => option?.doc_name === name);
  };
  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Drawer
        sx={{
          width: 600,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 600,
            boxSizing: 'border-box',
          },
        }}
        anchor='right'
        open={props.visible}
        onClose={() => {
          setDocuments(cloneDeep(props.documents));
          props.setVisible(false);
        }}
      >
        <DrawerHeader>
          <Typography variant='h6'>Update Documents</Typography>
          <Close
            size={20}
            sx={{ position: 'absolute', right: '10px', top: '20px', color: 'grey' }}
            fontSize='small'
            onClick={() => {
              setDocuments(cloneDeep(props.documents));
              props.setVisible(false);
            }}
            className='cursor-pointer'
          />
        </DrawerHeader>
        <Divider />
        <form onSubmit={handleSubmit(handleSave)} className='drawer-form'>
          <Droppable droppableId='test'>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ overflowX: 'hidden', overflowY: 'scroll', height: '570px' }}
              >
                <Grid container spacing={2} className='mt-4 f-14 fw-550 ml-8'>
                  <Grid item md={7}>
                    Document Label
                  </Grid>
                  <Grid item md={5}>
                    Included in Final Package
                  </Grid>
                </Grid>
                {documents?.map((doc, index) => (
                  <Draggable draggableId={`${doc.id}`} index={index} key={`${doc.id}`}>
                    {(provided, snapshot) => (
                      <Grid
                        container
                        spacing={2}
                        ref={provided.innerRef}
                        key={`${doc.id}`}
                        className='mt-0 ml-8'
                        {...provided.draggableProps}
                      >
                        {/* <Grid item md={2}>
                          <div
                            {...provided.dragHandleProps}
                            className='drag-icon'
                            style={{ cursor: 'grab' }}
                            key={`${doc.id}`}
                          >
                            <DragIndicatorIcon fontSize='small' />
                          </div>
                        </Grid> */}
                        {/* <Grid item className='f-13 fw-500' md={4}>
                          <div>{doc.doc_slug}</div>
                        </Grid> */}
                        <Grid item md={7} className='f-13 fw-500'>
                          <div>{doc.doc_name}</div>
                          {/* <TextField
                            id='outlined-basic'
                            name={doc?.doc_slug}
                            value={doc?.doc_name}
                            className='full-width'
                            variant='outlined'
                            inputProps={{
                              role: 'presentation',
                              autoComplete: 'off',
                            }}
                            disabled
                            {...register(doc?.doc_slug, {
                              required: true,
                              validate: {
                                isUnique: (value) => isUnique(documents, doc.doc_slug, value),
                              },
                            })}
                            // style={{ width: '280px' }}
                            onChange={(e) => handleDocumentChange(e, index)}
                          />
                          {errors[doc?.doc_slug] && (
                            <span className='error' style={{ fontSize: '13px' }}>
                              {errors[doc?.doc_slug]?.type === 'isUnique'
                                ? 'Section label must be unique'
                                : 'Section label is required'}
                            </span>
                          )} */}
                        </Grid>
                        <Grid item md={5}>
                          <Switch
                            checked={doc?.is_hidden ? false : true}
                            onChange={(e) => {
                              handleDocumentHideChange(!e.target.checked, index);
                            }}
                            color='primary'
                            size='small'
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <Divider className='mt-20' />
          <Grid item className='p-8 d-flex justify-end mr-8'>
            {/* <Button
              variant='outlined'
              className='p-4'
              onClick={() => {
                setDocuments(props.documents);
                props.setVisible(false);
              }}
            >
              Close
            </Button> */}

            <Button variant='contained' type='submit' className='ml-8 p-4'>
              Save
            </Button>
          </Grid>
        </form>
      </Drawer>
    </DragDropContext>
  );
};

export default ManageDocumentDrawer;
