import api, { wihoutPrefix } from '../../utils/api';

export const initiateLogin = (code, participantNo = 1, url) => {
  return api().get(`participant/login/${code}/${participantNo}?domain=${url}`);
};

export const skipMFA = (code) => {
  return api().get(`/participant/login/${code}/skip`);
};

export const loginParticipant = (data) => {
  return api().post(`/participant/login`, data);
};
export const autoLoginParticipant = (uuid) => {
  return api().post(`/participant/autologin/${uuid}`);
};

export const testEvent = () => {
  return api().get('/test/event');
};

export const resendCode = (code, participantNo = 1) => {
  return api().get(`/participant/login/${code}/${participantNo}?resend=true`);
};

export const getOtp = (code, data) => {
  return api().post(`/participant/code/${code}`, data);
};

export const csrfCookie = () => {
  return wihoutPrefix().get('/sanctum/csrf-cookie');
};
