import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Grid, CardContent, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { getSource } from '../../../services/admin/sources';

const ViewSource = () => {
  const { admin } = useSelector((state) => state?.authSlice);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState([]);

  useEffect(() => {
    if (id) fetchSourceData();
  }, [id]);

  const fetchSourceData = async () => {
    setLoading(true);
    const res = await getSource(id, admin?.token);
    if (res.success) {
      setSource({ ...res.response });
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <Grid item md={12} className='d-flex justify-between align-center sub-header'>
              <Typography variant='h4' className='mb-0'>
                {'View Mapping'}
              </Typography>
            </Grid>
            <Box sx={{ mt: 2 }} className='client-user-form'>
              <CardContent>
                <form className='view-client'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <label>Form Field</label>
                      <div className='f-13 mt-12 fw-500 color-g'>
                        {source?.source_form_field ?? '-'}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label>Source</label>
                      <div className='f-13 mt-12 fw-500 color-g'>{source?.source ?? '-'}</div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <label>Source Path</label>
                      <div className='f-13 mt-12 fw-500 color-g'>{source?.source_path ?? '-'}</div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label>Additional condition</label>
                      <div className='f-13 mt-12 fw-500 color-g'>
                        {source?.additional_condition ?? '-'}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label>Compare value</label>
                      <div className='f-13 mt-12 fw-500 color-g'>
                        {source?.compare_value ?? '-'}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label>Compare path</label>
                      <div className='f-13 mt-12 fw-500 color-g'>{source?.compare_path ?? '-'}</div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label>Return Path</label>
                      <div className='f-13 mt-12 fw-500 color-g'>
                        {source?.return_value_path ?? '-'}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label>Return value for true</label>
                      <div className='f-13 mt-12 fw-500 color-g'>{source?.return_true ?? '-'}</div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label>Return value for false</label>
                      <div className='f-13 mt-12 fw-500 color-g'>{source?.return_false ?? '-'}</div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className='d-flex justify-end mt-8'>
                        <Button
                          variant='outlined'
                          color='primary'
                          onClick={() => {
                            navigate('/admin/sources');
                          }}
                          className='mr-8'
                        >
                          Back
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ViewSource;
