import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, CardContent, Grid, Typography } from '@mui/material';
import Table from '../../../components/common/Table';
import { Edit, Visibility, Delete } from '@mui/icons-material';
import DeleteModal from '../../../components/common/DeleteModal';
import Loader from '../../../components/common/Loader';
import { toast } from 'react-toastify';
import { getSources, deleteSource } from '../../../services/admin/sources';
import ActionMenu from '../../../components/common/ActionMenu';

const Sources = () => {
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state?.authSlice);
  const [loading, setLoading] = useState(false);
  const [sourceDataList, setSourceDataList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [perPageRecords, setPerPageRecords] = useState(100);
  const [id, setId] = useState('');
  const [total, setTotal] = useState(0);
  const [currPage, setCurrPage] = useState(0);

  const tableData = useMemo(() => {
    if (sourceDataList?.length) {
      return sourceDataList?.map((source) => {
        return {
          ...source,
          actions: (props) => (
            <div>
              <ActionMenu
                actions={[
                  {
                    label: 'Edit Mapping',
                    logo: <Edit className='action-menu-item' />,
                    onClick: () => navigate(`/admin/sources/${props.id}`),
                  },
                  {
                    label: 'View Mapping',
                    logo: <Visibility className='action-menu-item' />,
                    onClick: () =>
                      navigate(`/admin/sources/${props.id}/view`, { state: { disabled: true } }),
                  },
                  {
                    label: 'Delete Mapping',
                    logo: <Delete className='action-menu-item' color='error' />,
                    onClick: () => {
                      setId(props?.id);
                      setShowDeleteModal(true);
                    },
                  },
                ]}
              />
            </div>
          ),
        };
      });
    }
    return [];
  }, [sourceDataList]);

  useEffect(() => {
    fetchSourceData();
  }, [currPage, perPageRecords]);

  const fetchSourceData = async () => {
    setLoading(true);
    const res = await getSources(admin?.token, currPage + 1, perPageRecords);
    if (res?.success) {
      setSourceDataList(res?.response?.data || []);
      setTotal(res?.response?.meta?.total);
    } else {
      setSourceDataList([]);
      setTotal(0);
    }
    setLoading(false);
  };
  const columns = [
    { id: 'actions', label: 'Actions', type: 'jsx' },
    // { id: 'id', label: 'ID' },
    { id: 'source_form_field', label: 'Field' },
    { id: 'source', label: 'Source' },
  ];

  const deleteHandler = async () => {
    setLoading(true);
    const res = await deleteSource(id, admin?.token);
    if (res.success) {
      fetchSourceData();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    setShowDeleteModal(false);
    setLoading(false);
  };

  const onRowClick = (row) => {
    navigate(`/admin/sources/${row.id}`);
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center'>
                <Typography variant='h4' className='mb-0'>
                  Source Mapping
                </Typography>
                <Button
                  autoFocus
                  onClick={() => {
                    navigate('/admin/sources/add');
                  }}
                  variant='contained'
                  color='primary'
                  style={{ padding: '4px 12px' }}
                >
                  + ADD mapping
                </Button>
              </Grid>
            </CardContent>
            <Box>
              <CardContent>
                <Table
                  loader={loading}
                  columns={columns}
                  data={tableData}
                  count={total}
                  current_page={currPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                  isClickable={true}
                  onRowClick={onRowClick}
                />
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        item={'Source mapping'}
        submitFunction={deleteHandler}
      />
    </Box>
  );
};
export default Sources;
