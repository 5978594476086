// CancelDialog.js
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

function CancelDialog({ open, onClose, onConfirm, heading }) {
  return (
    <Dialog open={open} onClose={onClose} className='common-cancel-dialog'>
      <DialogTitle className='place-order-title'>{heading}</DialogTitle>
      <DialogContent>Are you sure you want to cancel?</DialogContent>
      <DialogActions className='next-btn d-flex justify-center'>
        <Button onClick={onConfirm} color='primary' className='confirm-btn'>
          Yes
        </Button>
        <Button onClick={onClose} color='primary'>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelDialog;
