import { useEffect, useState } from 'react';
import Loader from '../../../components/common/Loader';
import { Box } from '@mui/system';
import { CardContent, Grid } from '@mui/material';
import CardWidget from '../../../components/admin/dashboard/CardWidget';
import {
  SupervisorAccount,
  ManageAccounts,
  ListAlt,
  QuestionAnswer,
  Source,
  AdminPanelSettings,
  Settings,
  Article,
  Assessment,
  RecentActors,
} from '@mui/icons-material';
import Chart from '../../../components/common/Chart';
import DashBoardListItem from '../../../components/common/DashboardListItem';
import ProfileWidget from '../../../components/admin/dashboard/ProfileWidget';
import { getStats, getOrdersGraph } from '../../../services/admin/dashboard';
import { useSelector } from 'react-redux';
import { CLIENT_ADMIN_ROLE, OFFICE_ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../../../utils/constants';

const DashBoard = () => {
  const { admin } = useSelector((state) => state?.authSlice);
  const isClientAdmin = admin?.user_role_name === CLIENT_ADMIN_ROLE ? true : false;
  const isSuperAdmin = admin?.user_role_name === SUPER_ADMIN_ROLE ? true : false;
  const isOfficeAdmin = admin?.user_role_name === OFFICE_ADMIN_ROLE ? true : false;

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});
  const [graphData, setGraphData] = useState([]);

  const cardWidgetsData = [
    {
      color: 'rgba(255, 165, 0, 0.3)',
      iconBg: 'rgba(255, 165, 0, 0.5)',
      icon: <QuestionAnswer style={{ color: 'black' }} />,
      total: stats?.all_order_count || '-',
      title: 'Total Orders',
      display: true, // Add the display property
    },
    {
      color: 'rgb(58, 131, 82,0.4)',
      iconBg: 'rgb(58, 131, 82,0.8)',
      icon: <Source style={{ color: 'black' }} />,
      total: stats?.completed_order_count || '-',
      title: 'Completed Orders',
      display: true,
    },
    {
      color: 'rgba(23, 162, 184, 0.3)',
      iconBg: 'rgb(115, 213, 228)',
      icon: <ListAlt style={{ color: 'black' }} />,
      total: stats?.template_Count || '-',
      title: 'Form Templates',
      display: true,
    },
    {
      color: 'rgba(149, 117, 229, 0.3)',
      iconBg: 'rgb(180, 159, 233)',
      icon: <ManageAccounts style={{ color: 'black' }} />,
      total: stats?.client_count || '-',
      title: 'Clients',
      display: isSuperAdmin,
    },
    {
      color: 'rgba(255, 138, 150, 0.3)',
      iconBg: 'rgb(250, 181, 188)',
      icon: <SupervisorAccount style={{ color: 'black' }} />,
      total: stats?.users_Count || '-',
      title: 'All Users',
      display: true,
    },
  ];

  useEffect(() => {
    fetchStats();
    fetchOrderGraphDetails();
  }, []);

  const fetchStats = async () => {
    setLoading(true);
    const res = await getStats(admin?.token);
    if (res.success) {
      setStats(res.response);
    }
    setLoading(false);
  };

  const fetchOrderGraphDetails = async () => {
    setLoading(true);
    const res = await getOrdersGraph(admin?.token);
    if (res?.success) {
      setGraphData(
        res?.response?.map((d) => {
          return { label: d.month, value: d.total_orders };
        })
      );
    }
    setLoading(false);
  };

  return (
    <div>
      <Box className='dashboard-main' style={{ minHeight: 'unset', padding: '0px' }}>
        {loading && <Loader />}
        <Grid container className='dashboard-content' style={{ marginBottom: '0px' }}>
          <Grid item md={12}>
            <div className='transaction-logs-wrapper'>
              <CardContent style={{ paddingBottom: '16px' }}>
                <Grid container columnGap={2} rowGap={2}>
                  {cardWidgetsData?.map((wid) => {
                    if (wid?.display) {
                      return <Grid item>{wid?.display && <CardWidget {...wid} />}</Grid>;
                    }
                  })}
                </Grid>
              </CardContent>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Grid container md={12} style={{ padding: '0px 16px' }}>
        <Grid item className='dashboard-container' md={7.9}>
          <Chart chartData={graphData} />
        </Grid>
        <Grid item md={0.1}></Grid>
        <Grid item className='dashboard-container d-flex align-items-center justify-center' md={4}>
          <ProfileWidget />
        </Grid>
      </Grid>
      <Grid container style={{ padding: '16px' }}>
        <Grid
          item
          className='dashboard-container'
          md={3.9}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div className='dashboard-heading'>Orders</div>
          <Grid container style={{ padding: '8px 16px' }} columnGap={1}>
            <Grid item md={5.6} columnGap={2}>
              {' '}
              <DashBoardListItem
                icon={<QuestionAnswer />}
                title={'Order List'}
                route={'/admin/orders'}
              />
            </Grid>
            {isSuperAdmin && (
              <Grid item md={5.6} columnGap={2}>
                {' '}
                <DashBoardListItem
                  icon={<Assessment fontSize='small' />}
                  title={'Reports'}
                  route={'/admin/reports'}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item md={0.1}></Grid>
        <Grid
          item
          className='dashboard-container'
          md={3.9}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div className='dashboard-heading'>User Management & Audit</div>
          <Grid container style={{ padding: '8px 16px' }} columnGap={2}>
            <Grid item md={5.6}>
              <DashBoardListItem
                title={'Client Offices'}
                icon={<ManageAccounts fontSize='small' />}
                route={
                  isSuperAdmin
                    ? '/admin/clients'
                    : `/admin/clients/${admin?.user_id}/${admin?.client_id}`
                }
              />
            </Grid>
            <Grid item md={5.6}>
              <DashBoardListItem
                title={'Users'}
                route={'/admin/users'}
                icon={<SupervisorAccount fontSize='small' />}
              />
            </Grid>
            {isSuperAdmin && (
              <Grid item md={5.6}>
                <DashBoardListItem
                  title={'System Admins'}
                  route={'/admin/list'}
                  icon={<AdminPanelSettings fontSize='small' />}
                />
              </Grid>
            )}
            <Grid item md={5.6}>
              <DashBoardListItem
                title={'Audit Trails'}
                route={'/admin/audit-trails'}
                icon={<RecentActors fontSize='small' />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={0.1}></Grid>
        <Grid
          item
          className='dashboard-container'
          md={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className='dashboard-heading'>Configurations</div>
          <Grid container style={{ padding: '8px 16px' }} columnGap={2}>
            {!isOfficeAdmin && (
              <Grid item md={5.6}>
                <DashBoardListItem
                  title={'Preferences'}
                  route={'/admin/configurations'}
                  icon={<Settings fontSize='small' />}
                />
              </Grid>
            )}
            <Grid item md={5.6}>
              <DashBoardListItem
                title={'Form Configuration'}
                route={'/admin/templates'}
                icon={<ListAlt fontSize='small' />}
              />
            </Grid>
            <Grid item md={5.6}>
              <DashBoardListItem
                title={'Document Configuration'}
                route={'/admin/document-configurations'}
                icon={<Article fontSize='small' />}
              />
            </Grid>
            {isSuperAdmin && (
              <Grid item md={5.6}>
                <DashBoardListItem
                  title={'Source Mapping'}
                  icon={<Source fontSize='small' />}
                  route={'/admin/sources'}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default DashBoard;
