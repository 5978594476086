import React, { useEffect, useState } from 'react';
import { FormHelperText, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import useRedux from '../../../../utils/hooks/useRedux';
import {
  areAllElementsSame,
  errorChecker,
  fetchSourceData,
  isValidDate,
} from '../../../../utils/helpers';
import { CalendarToday } from '@mui/icons-material';
import ValueSelectionModal from '../../../questions/home/ValueSelectionModal';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DATE_OF_BIRTH, DL_FIELD_DOB } from '../../../../utils/constants';
import moment from 'moment';

const DateField = ({ element, disabled }) => {
  const { code, participantNo } = useParams();
  const isAdditionalParticipant = participantNo && participantNo != 1 ? true : false;
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code];
  const languageForLabel = selectedLanguage && selectedLanguage !== 'en' ? selectedLanguage : '';
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  const { setFormValue, getFormValue, getErrorDetails, removeError, setError, setTouched } =
    useRedux();
  const value = getFormValue(element.element_id);
  const { error, error_message } = getErrorDetails(element.element_id) || {
    error: false,
    error_message: '',
  };
  const dlDetails = useSelector((state) => state.questionSlice.dlDetails)?.[code];
  const dataVal = value !== undefined ? value : element.user_value ? element.user_value : '';
  const [date, setDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [isDDOpen, setDDOpen] = useState(false);
  const [sourceList, setSourceList] = useState([]);
  const [disabledField, setDisabledField] = useState(false);

  // for populating DOB
  useEffect(() => {
    if (!isAdditionalParticipant && element.element_id === DATE_OF_BIRTH) {
      if (dlDetails?.transaction_response?.Approved && dlDetails?.transaction_response?.BirthDate) {
        if (moment(dlDetails?.transaction_response?.BirthDate, moment.ISO_8601, true).isValid()) {
          const convertedBDay = moment(dlDetails?.transaction_response?.BirthDate).format(
            'MM/DD/YYYY'
          );
          if (!dataVal) {
            setFormValue({
              id: element.element_id,
              value: convertedBDay,
            });
            setDate(convertedBDay);
            setTouched(element.element_id);
            removeError(element.element_id);
          }
        }
      }
    }

    // for populating DOB for additional participant
    if (
      isAdditionalParticipant &&
      participantNo === element?.participant_no &&
      element?.element_name?.startsWith(DL_FIELD_DOB)
    ) {
      if (dlDetails?.transaction_response?.Approved && dlDetails?.transaction_response?.BirthDate) {
        if (moment(dlDetails?.transaction_response?.BirthDate, moment.ISO_8601, true).isValid()) {
          const convertedBDay = moment(dlDetails?.transaction_response?.BirthDate).format(
            'MM/DD/YYYY'
          );
          if (!dataVal) {
            setFormValue({
              id: element.element_id,
              value: convertedBDay,
            });
            setDate(convertedBDay);
            setTouched(element.element_id);
            removeError(element.element_id);
          }
        }
      }
    }
  }, [element?.element_id, dlDetails, dataVal]);

  useEffect(() => {
    setSourceList(fetchSourceData(element));
  }, [element]);

  useEffect(() => {
    if (value !== undefined) setDate(new Date(value));
    else if (element.user_value) setDate(new Date(element.user_value));
  }, [value]);

  useEffect(() => {
    if (
      (sourceList?.length === 1 || areAllElementsSame(sourceList.map((source) => source?.value))) &&
      !element.user_value
    ) {
      const dateObj = isValidDate(sourceList[0]?.value);
      if (dateObj?.isValid) {
        setDate(new Date(dateObj?.date));
        setFormValue({ id: element.element_id, value: new Date(dateObj?.date) });
        // setDisabledField(true);
      }
    }
  }, [sourceList]);

  const handleChange = (newValue) => {
    setTouched(element.element_id);
    if (newValue) {
      var date = new Date(newValue);
      var finaldate =
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        date.getDate().toString().padStart(2, '0') +
        '/' +
        date.getFullYear();
      setDate(newValue);
      setFormValue({ id: element.element_id, value: finaldate });
      handleError(finaldate);
    } else {
      setDate('');
    }
    setDDOpen(false);
  };

  const handleError = (newVal) => {
    const { isError, message } = errorChecker(element, newVal);
    if (!isError) {
      removeError(element.element_id);
    } else {
      setError({ id: element.element_id, error: true, error_message: message });
    }
  };

  const onSelect = (val) => {
    setTouched(element.element_id);
    setFormValue({ id: element.element_id, value: val });
    handleError(val);
    setOpen(false);
    setDDOpen(false);
  };

  const MyContainer = ({ className, children }) => {
    return (
      <Grid container style={{ background: 'white' }}>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{ borderRight: '1px solid #e1dcdc' }}
          className='d-flex justify-center'
        >
          <CalendarContainer className={className}>
            <div style={{ position: 'relative' }}>{children}</div>
          </CalendarContainer>
        </Grid>
        {/* <Grid xs={12} item md={5.5} sm={5.5} style={{ minHeight: '250px' }}>
          <h4 className='d-flex justify-center pb-16' style={{ borderBottom: '1px solid #e1dcdc' }}>
            Suggested Options
          </h4>
          <div className={sourceList?.length ? 'd-flex justify-center' : 'd-flex justify-center'}>
            {sourceList?.length && false ? (
              sourceList.map((source, index) => {
                return (
                  <MenuItem
                    value={source?.value}
                    key={index}
                    style={menuItemStyle}
                    onClick={() => {
                      onSelect(source?.value);
                    }}
                    className={date == source?.value ? 'bg-selected' : ''}
                  >
                    {source?.value}
                  </MenuItem>
                );
              })
            ) : (
              <span className='not-available-text'>Not available</span>
            )}
          </div>
        </Grid> */}
      </Grid>
    );
  };

  const futureDateRestrictedElements = [
    'core_date_of_birth',
    'buyer_v2_field_30317',
    'buyer_v2_field_82848',
    'borrower_v2_field_23381',
    'borrower_v2_field_25400',
    'borrower_date_of_birth',
  ];
  const curDate = new Date();
  curDate.setFullYear(curDate.getFullYear() - 18);

  return (
    <>
      {element.label !== '' && (
        <Grid xs={12} md={6} spacing={1} className='grid-container plt-8'>
          <Grid xs={12} md={12} item={true}>
            <label
              dangerouslySetInnerHTML={{
                __html: languageForLabel
                  ? translations?.[languageForLabel]?.elements?.[element.element_id]
                    ? translations?.[languageForLabel]?.elements?.[element.element_id]
                    : element?.label
                  : element?.label,
              }}
            />
            <label className='required'>{element.required && '*'}</label>
          </Grid>

          <Grid xs={12} md={12} item={true} className='date-input mt-4'>
            <div className='d-flex align-items-center'>
              <DatePicker
                // showIcon
                selected={date}
                disabled={disabled || disabledField}
                onChange={(date) => handleChange(date)}
                maxDate={
                  futureDateRestrictedElements.includes(element.element_name) ? curDate : null
                }
                calendarContainer={MyContainer}
                onInputClick={() => setDDOpen(true)}
                onClickOutside={() => setDDOpen(false)}
                open={isDDOpen && !disabled}
                autoComplete='off'
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                customInput={
                  <TextField
                    disabled={disabled || disabledField}
                    className='full-width w-100p'
                    variant='outlined'
                    error={error}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CalendarToday fontSize='small' style={{ color: 'grey' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={date}
                  inputFormat='MM/dd/yyyy'
                  onChange={handleChange}
                  disabled={disabled}
                  renderInput={(params) => (
                    <TextField
                      className='full-width w-100p'
                      variant='outlined'
                      {...params}
                      autoComplete='off'
                      error={error}
                    />
                  )}
                />
              </LocalizationProvider>
              {sourceList?.length > 1 &&
              !areAllElementsSame(sourceList.map((source) => source?.value)) ? (
                <Tooltip title='Click to Select value from given list'>
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <Tune style={{ color: 'grey', marginLeft: '2px' }} fontSize='small' />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )} */}
            </div>
            {/* <FormHelperText error={true}>{error_message || ''}</FormHelperText> */}
          </Grid>
        </Grid>
      )}
      <ValueSelectionModal
        setOpen={setOpen}
        open={open}
        data={sourceList}
        onSelect={onSelect}
        value={value}
      />
    </>
  );
};
export default DateField;
