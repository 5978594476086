import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
const Error404Page = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Typography variant='h1' style={{ color: 'black' }}>
          404
        </Typography>
        <Typography variant='h6' style={{ color: 'grey' }}>
          The page you’re looking for doesn’t exist.
        </Typography>
      </Box>
    </>
  );
};

export default Error404Page;
