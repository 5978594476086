import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, CardContent, Box, Typography } from '@mui/material';
import { getClient } from '../../../services/admin/clients';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';

const ViewClient = () => {
  const { id } = useParams();
  const { admin } = useSelector((state) => state?.authSlice);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState({});

  useEffect(() => {
    if (id) fetchClient();
  }, [id]);

  const fetchClient = async () => {
    setLoading(true);
    const res = await getClient(id, admin?.token);
    if (res.success) {
      setClient(res.response);
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <Grid item md={12} className='d-flex justify-between align-center sub-header'>
              <Typography variant='h4' className='mb-0'>
                {'Client Information'}
              </Typography>
            </Grid>
            <Box sx={{ mt: 2 }} className='client-user-form'>
              <CardContent>
                <form className='view-client'>
                  <fieldset disabled={true} className='m-0 p-0 border-none'>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <label>Company Name</label>
                        <div className='f-13 mt-12 fw-500 color-g'>
                          {client?.company_name ?? '-'}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography variant='h4' className='mt-8'>
                          {'Office Information'}
                        </Typography>
                      </Grid>

                      {client?.offices?.map((ofc) => {
                        return (
                          <>
                            <Grid item xs={12} sm={4}>
                              <label>Office Name</label>
                              <div className='f-13 mt-12 fw-500 color-g'>
                                {ofc?.office_name ?? '-'}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <label>Contact Name</label>
                              <div className='f-13 mt-12 fw-500 color-g'>
                                {ofc?.contact_name ?? '-'}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <label>Contact Email</label>
                              <div className='f-13 mt-12 fw-500 color-g'>
                                {ofc?.contact_email ?? '-'}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <label>Phone No </label>
                              <div className='f-13 mt-12 fw-500 color-g'>
                                {ofc?.phone_no ?? '-'}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <label>Fax </label>
                              <div className='f-13 mt-12 fw-500 color-g'>{ofc?.fax_no ?? '-'}</div>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <label>Status</label>
                              <div>
                                <div className='f-13 mt-12 fw-500 color-g'>
                                  {ofc?.status ?? '-'}
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <label>Address</label>
                              <div className='f-13 mt-12 fw-500 color-g'>
                                {ofc?.address_line_1 ?? '-'}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <label>Address 2</label>
                              <div className='f-13 mt-12 fw-500 color-g'>
                                {ofc?.address_line_2 ?? '-'}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <label>City</label>
                              <div className='f-13 mt-12 fw-500 color-g'>{ofc?.city ?? '-'}</div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <label>State</label>
                              <div className='f-13 mt-12 fw-500 color-g'>{ofc?.state ?? '-'}</div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <label>Zip</label>
                              <div className='f-13 mt-12 fw-500 color-g'>
                                {ofc?.zip_code ?? '-'}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ borderBottom: '1px solid #e4e1e1' }}
                            ></Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </fieldset>
                  <Grid item xs={12}>
                    <div className='d-flex justify-end mt-16'>
                      <Button
                        variant={'outlined'}
                        color='primary'
                        onClick={() => {
                          navigate('/admin/clients');
                        }}
                        className='mr-8'
                      >
                        Back
                      </Button>
                    </div>
                  </Grid>
                </form>
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ViewClient;
