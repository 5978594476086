import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, ErrorOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ValidationErrorModal = ({
  open,
  setOpen,
  dlError,
  handleContinue,
  validationMessage,
  errorSections,
  dlSectionStep,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          style={{ fontSize: '16px' }}
          className='font-family-main'
        >
          {t('validation')}{' '}
          <ErrorOutline
            color='error'
            style={{ fontSize: '16px', marginBottom: '-2px' }}
            className='mr-8'
          />
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Typography
            gutterBottom
            className='d-flex align-center ml-2 font-family-main fw-550 f-13'
          >
            {' '}
            {validationMessage}
          </Typography>
          {dlError && (
            <Grid container className={'mt-16'}>
              <Grid
                item
                md={7}
                sm={7}
                xs={7}
                className='f-13 fw-500 p-8 d-flex align-items-center'
                border={'1px solid rgba(50, 50, 93, 0.25)'}
                borderRight={'0px'}
              >
                {`Statement of Identity (Upload Government Issued ID)`}
              </Grid>
              <Grid
                item
                md={5}
                sm={5}
                xs={5}
                className='d-flex justify-end f-13 p-8 fw-500'
                border={'1px solid rgba(50, 50, 93, 0.25)'}
              >
                <div
                  className='cursor-pointer text-underline'
                  style={{ color: '#005fd4' }}
                  onClick={() => handleContinue(dlSectionStep)}
                >
                  GO BACK TO SECTION
                </div>
              </Grid>
            </Grid>
          )}
          {errorSections?.length
            ? errorSections?.map((errSection, index) => {
                return (
                  <Grid container className={index !== 0 ? 'mt-8' : 'mt-16'}>
                    <Grid
                      item
                      md={7}
                      sm={7}
                      xs={7}
                      className='f-13 fw-500 p-8 d-flex align-items-center'
                      border={'1px solid rgba(50, 50, 93, 0.25)'}
                      borderRight={'0px'}
                    >
                      {errSection?.section_label}
                    </Grid>
                    <Grid
                      item
                      md={5}
                      sm={5}
                      xs={5}
                      className='d-flex justify-end f-13 p-8 fw-500'
                      border={'1px solid rgba(50, 50, 93, 0.25)'}
                    >
                      <div
                        className='cursor-pointer text-underline'
                        style={{ color: '#005fd4' }}
                        onClick={() => handleContinue(errSection?.stepIndex)}
                      >
                        GO BACK TO SECTION
                      </div>
                    </Grid>
                  </Grid>
                );
              })
            : ''}
          <Grid container></Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            className='p-2'
            color='secondary'
            style={{ color: 'grey' }}
          >
            {t('close')}
          </Button>
          {/* {dlError ? (
            <Button autoFocus onClick={handleContinue} className='p-2' variant='contained'>
              {t('upload')}
            </Button>
          ) : (
            <></>
            // <Button
            //   autoFocus
            //   onClick={handleContinue}
            //   style={{ padding: '2px 8px' }}
            //   variant='contained'
            // >
            //   {t('goToSection')}
            // </Button>
          )} */}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ValidationErrorModal;
