import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, ErrorOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const StepValidationModal = ({ open, setOpen, handleContinue, validationMessage, isBankStep }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          style={{ fontSize: '16px' }}
          className='font-family-main'
        >
          {t('validation')}{' '}
          <ErrorOutline
            color='error'
            style={{ fontSize: '16px', marginBottom: '-2px' }}
            className='mr-8'
          />
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Typography
            gutterBottom
            className='d-flex align-center ml-2 font-family-main fw-550 f-13'
          >
            {' '}
            {validationMessage}{' '}
            {isBankStep ? 'Please provide the necessary details to verify your bank account.' : ''}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            className='p-2'
            color='secondary'
            style={{ color: 'grey' }}
          >
            {t('stay')}
          </Button>

          <Button
            onClick={handleContinue}
            style={{ padding: '2px' }}
            className='p-2'
            variant='contained'
          >
            {t('next')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default StepValidationModal;
