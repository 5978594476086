import React, { useCallback, useRef } from 'react';
import { Box, Button, CircularProgress, Fade, Grid, Modal } from '@mui/material';
import Webcam from 'react-webcam';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const videoConstraints = {
  width: 600,
  height: 600,
  facingMode: 'user',
};

const FacecaptureModal = ({
  open,
  setIsLoginModal,
  skipToMFA,
  setIsFaceCaptureModal,
  onSubmit,
  loading,
}) => {
  const { code } = useParams();
  const webcamRef = useRef(null);
  const data = useSelector((state) => state?.authSlice);

  const capture = useCallback(() => {
    const imgSrc = webcamRef?.current.getScreenshot();
    onSubmit({
      uuid: data[code]?.user_id,
      email: data[code]?.email,
      photo: imgSrc,
      temp_photo_match: false,
    });
  }, [webcamRef]);

  const getCaptureError = (e) => {
    setIsFaceCaptureModal(false);
    setIsLoginModal(true);
    skipToMFA();
  };
  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className='modal-wrapper face-capture-modal'>
            <Box variant='div' component='div' className='title'>
              <h3>Face Capture Modal</h3>
              <p>Verify your identity by centering your face in the photo frame.</p>
            </Box>
            <Box variant='div' component='div' className='body'>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                  <Box variant='div' component='div' className='webcam'>
                    {/* <label>Open camera here</label> */}
                    <Webcam
                      ref={webcamRef}
                      screenshotFormat='image/jpeg'
                      width={1280}
                      videoConstraints={videoConstraints}
                      onUserMediaError={getCaptureError}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box variant='div' component='div' className='footer'>
              <Box variant='div' component='div' className='btn-group'>
                <Button
                  variant='contained'
                  size='large'
                  className='primary-btn btn'
                  disableRipple
                  onClick={() => capture()}
                  disabled={loading}
                  endIcon={loading && <CircularProgress size={20} />}
                >
                  {' '}
                  Capture
                </Button>
              </Box>
            </Box>
            <Button
              variant='text'
              size=''
              className=''
              disableRipple
              onClick={() => {
                setIsFaceCaptureModal(false);
                setIsLoginModal(true);
                skipToMFA();
              }}
            >
              {' '}
              Try Other Verification Method
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default FacecaptureModal;
