import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CardContent,
  Grid,
  Typography,
  Chip,
  Select,
  MenuItem,
  Tooltip,
  Popover,
  Checkbox,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getOrders, archiveOrder } from '../../../services/order/order';
import FilterPopper from '../../../components/common/FilterPopper';
import moment from 'moment';
import Loader from '../../../components/common/Loader';
import {
  AssignmentInd,
  Delete,
  ErrorOutline,
  Settings,
  VerifiedUser,
  Visibility,
} from '@mui/icons-material';
import ActionMenu from '../../../components/common/ActionMenu';
import {
  ADMIN_ROLE,
  CLIENT_ADMIN_ROLE,
  CLIENT_USER_ROLE,
  OFFICE_USER_ROLE,
  SUPER_ADMIN_ROLE,
} from '../../../utils/constants';
import { getOfficeList } from '../../../services/admin/users';
import AssignModal from '../../../components/order/AssignModal';
import { toast } from 'react-toastify';
import CustomStepper from '../../../components/common/StepperStatus';
import RearrangableTable from '../../../components/common/RearrangableTable';
import { cloneDeep } from 'lodash';
import { getConfigurations, saveConfig } from '../../../services/admin/configurations';
import { login } from '../../../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import ConfigConfirmationModal from '../../../components/order/ConfigConfirmationModal';
import CommonModal from '../../../components/questions/home/CommonModal';

const OrderList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state?.authSlice);
  const bankAccountClient = order?.is_bank_account_client;
  const isSuperAdmin = order?.user_role_name === SUPER_ADMIN_ROLE;
  const isClientAdmin = order?.user_role_name === CLIENT_ADMIN_ROLE;
  const isClientUser =
    order?.user_role_name === OFFICE_USER_ROLE || order?.user_role_name === CLIENT_USER_ROLE
      ? true
      : false;
  const clientLevelRole =
    order?.user_role_name === CLIENT_ADMIN_ROLE || order?.user_role_name === CLIENT_USER_ROLE
      ? true
      : false;
  const isAdmin =
    order?.user_role_name === ADMIN_ROLE || order?.user_role_name === SUPER_ADMIN_ROLE
      ? true
      : false;

  const defaultColumns = useMemo(() => {
    let cols = [
      { id: 'actions', show: true, label: 'Actions' },
      { id: 'escrow_num', show: true, label: 'Escrow No.' },
      { id: 'bank_account', show: bankAccountClient, label: 'Bank Account' },
      { id: 'order_status', show: true, label: 'Status' },
      { id: 'company_name', show: true, label: 'Company Name' },
      { id: 'office_name', show: true, label: 'Office Name' },
      { id: 'created_by', show: true, label: 'Created By' },
      { id: 'assigned_to', show: true, label: 'Assigned To' },
      { id: 'order_type_name', show: true, label: 'Order Type' },
      { id: 'order_create_type_name', show: true, label: 'Order Create Type' },
      { id: 'property_address', show: true, label: 'Property Address' },
      { id: 'created_date', show: true, label: 'Order Date' },
      { id: 'order_completed_date', show: true, label: 'Completed On' },
    ];

    if (isSuperAdmin) {
      cols = cols?.filter((col) => col.id !== 'order_create_type_name');
    } else {
      cols = cols?.filter((col) => col.id !== 'company_name');
    }

    return cols;
  }, [bankAccountClient]);

  const [loading, setLoading] = useState(false);
  const [officeError, setOfficeError] = useState(false);
  const [officeListLoading, setOfficeListLoading] = useState(false);
  const [OrderList, setOrderList] = useState([]);
  const [total, setTotal] = useState(0);
  const [CurrPage, setCurrPage] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(50);
  const [anchor, setAnchor] = useState(null);
  const [anchor2, setAnchor2] = useState(null);
  const [anchor3, setAnchor3] = useState(null);
  const [anchor4, setAnchor4] = useState(null);
  const [anchor5, setAnchor5] = useState(null);
  const [anchor6, setAnchor6] = useState(null);
  const [anchor7, setAnchor7] = useState(null);
  const [anchor8, setAnchor8] = useState(null);
  const [anchor9, setAnchor9] = useState(null);
  const [anchor10, setAnchor10] = useState(null);
  const [filters, setFilters] = useState({});
  const [dateRange, setDateRange] = useState([{ startDate: '', endDate: '', key: 'selection' }]);
  const [isDateAdded, setIsDateAdded] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [offices, setOffices] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [open, setOpen] = useState(false);
  const [configConfirmationOpen, setConfigConfirmationOpen] = useState(false);
  const [archiveOrderModalOpen, setArchiveOrderModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showHideColumns, setShowHideColumns] = useState(cloneDeep(defaultColumns));
  const [configurations, setConfigurations] = useState({});
  const [curRecord, setCurRecord] = useState({});

  const filterMapper = {
    escrow_num: 'escrow_num',
    order_status: 'order_status',
    company_name: 'client_name',
    office_name: 'office_name',
    created_by: 'created_by_user',
    assigned_to: 'assignee_user',
    order_type_name: 'order_type',
    order_create_type_name: 'order_create_type',
    property_address: 'address',
    created_date: 'created_at',
  };

  const filteredColumns = useMemo(() => {
    let columns = [
      { id: 'actions', label: 'Actions', type: 'jsx' },
      {
        id: 'escrow_num',
        label: 'Escrow No.',
        width: '150px',
        render: (props) => {
          return (
            <div className='d-flex justify-start align-items-center'>
              {/* <div>Escrow No.</div> */}
              <FilterPopper
                anchor={anchor}
                setAnchor={setAnchor}
                field={props?.id}
                label={props?.label}
                filters={filters}
                setFilters={setFilters}
                type='text'
              />
            </div>
          );
        },
      },
      {
        id: 'bank_account',
        label: 'Bank Account',
        type: 'jsx',
        width: '200px',
      },
      {
        id: 'order_status',
        label: 'Status',
        type: 'jsx',
        width: '255px',
        render: (props) => {
          return (
            <div className='d-flex justify-start align-items-center'>
              {/* <div>Status</div> */}
              <FilterPopper
                anchor={anchor4}
                setAnchor={setAnchor4}
                field={props?.id}
                label={props?.label}
                filters={filters}
                setFilters={setFilters}
                type='select'
                options={[
                  { label: 'Collecting', value: 'Collecting' },
                  { label: 'Reviewing', value: 'Reviewing' },
                  { label: 'Completed', value: 'Completed' },
                ]}
              />
            </div>
          );
        },
      },
      {
        id: 'company_name',
        label: 'Company Name',
        width: '140px',
        render: (props) => {
          return (
            <div className='d-flex justify-start align-items-center'>
              {/* <div>Company Name</div> */}
              <FilterPopper
                anchor={anchor5}
                setAnchor={setAnchor5}
                field={'client_name'}
                label={props?.label}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          );
        },
      },
      {
        id: 'office_name',
        label: 'Office Name',
        width: '140px',
        render: (props) => {
          return (
            <div className='d-flex justify-start align-items-center'>
              {/* <div>Office Name</div> */}
              <FilterPopper
                anchor={anchor6}
                setAnchor={setAnchor6}
                field={props?.id}
                label={props?.label}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          );
        },
        type: 'jsx',
      },
      {
        id: 'created_by',
        label: 'Created By',
        type: 'jsx',
        width: '140px',
        render: (props) => {
          return (
            <div className='d-flex justify-start align-items-center'>
              {/* <div>Created By</div> */}
              <FilterPopper
                anchor={anchor8}
                setAnchor={setAnchor8}
                field={'created_by_user'}
                label={props?.label}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          );
        },
      },
      {
        id: 'assigned_to',
        label: 'Assigned To',
        width: '140px',
        render: (props) => {
          return (
            <div className='d-flex justify-start align-items-center'>
              {/* <div>Assigned To</div> */}
              <FilterPopper
                anchor={anchor9}
                setAnchor={setAnchor9}
                field={'assignee_user'}
                label={props?.label}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          );
        },
      },
      {
        id: 'order_type_name',
        label: 'Order Type',
        width: '130px',
        render: (props) => {
          return (
            <div className='d-flex justify-start align-items-center'>
              {/* <div>Order Type</div> */}
              <FilterPopper
                anchor={anchor7}
                setAnchor={setAnchor7}
                field={'order_type'}
                label={props?.label}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          );
        },
      },
      {
        id: 'order_create_type_name',
        label: 'Order Create Type',
        type: 'jsx',
        width: '160px',
        render: (props) => {
          return (
            <div className='d-flex justify-start align-items-center'>
              {/* <div>Order Create Type</div> */}
              <FilterPopper
                anchor={anchor10}
                setAnchor={setAnchor10}
                field={'order_create_type'}
                label={props?.label}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          );
        },
      },
      {
        id: 'property_address',
        label: 'Property Address',
        type: 'jsx',
        width: '180px',
        render: (props) => {
          return (
            <div className='d-flex justify-start align-items-center'>
              {/* <div>Address</div> */}
              <FilterPopper
                anchor={anchor2}
                setAnchor={setAnchor2}
                field={'address'}
                label={props?.label}
                filters={filters}
                setFilters={setFilters}
                type='text'
              />
            </div>
          );
        },
      },
      {
        id: 'created_date',
        label: 'Order Date',
        width: '130px',
        render: (props) => {
          return (
            <div className='d-flex justify-start align-items-center'>
              {/* <div>Order Date</div> */}
              <FilterPopper
                anchor={anchor3}
                setAnchor={setAnchor3}
                field={'created_at'}
                label={props?.label}
                filters={filters}
                setFilters={setFilters}
                dateRange={dateRange}
                setDateRange={setDateRange}
                fetchFormData={fetchOrders}
                type='date'
                isDateAdded={isDateAdded}
              />
            </div>
          );
        },
      },
      {
        id: 'order_completed_date',
        label: 'Completed On',
        width: '120px',
        // render: (props) => {
        //   return (
        //     <div className='d-flex justify-start align-items-center' style={{ width: '100px' }}>
        //       <div>Completed On</div>
        //     </div>
        //   );
        // },
      },
    ];

    if (isAdmin) {
      columns = columns.filter((column) => column.id !== 'order_create_type_name');
    } else {
      columns = columns.filter((column) => column.id !== 'company_name');
    }
    if (order?.orderColumns?.length) {
      columns.sort((a, b) => {
        return order?.orderColumns.indexOf(a.id) - order?.orderColumns.indexOf(b.id);
      });
    }
    // if (!isSuperAdmin) {
    const showHideCols = {};
    showHideColumns?.forEach((col) => {
      showHideCols[col?.id] = col?.show;
    });
    columns = columns.filter((col) => showHideCols[col?.id]);
    // }
    return columns;
  }, [
    anchor,
    anchor2,
    anchor3,
    anchor4,
    anchor5,
    anchor6,
    anchor7,
    anchor8,
    anchor9,
    anchor10,
    filters,
    perPageRecords,
    CurrPage,
    showHideColumns,
  ]);

  useEffect(() => {
    fetchOfficeList();
    if (order?.client_id && clientLevelRole) fetchClient();
  }, []);

  useEffect(() => {
    if (offices?.length) {
      if (offices?.length > 1) {
        let primaryOffice = offices.find((office) => office.is_primary);
        if (primaryOffice && !isAdmin) setSelectedOffice(primaryOffice?.office_id);
      } else {
        if (!isAdmin) {
          setSelectedOffice(offices?.[0]?.office_id);
        }
      }
    }
  }, [offices]);

  useEffect(() => {
    if (selectedOffice && clientLevelRole && offices?.length > 1)
      setFilters((prev) => {
        return { ...prev, client_office_id: String(selectedOffice) };
      });
  }, [selectedOffice]);

  useEffect(() => {
    fetchOrders();
    fetchConfig();
  }, [CurrPage, perPageRecords]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchOrders();
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [filters]);

  useEffect(() => {
    setTimeout(() => {
      if (
        dateRange[0]?.startDate &&
        dateRange[0]?.startDate !== 'Invalid date' &&
        dateRange[0]?.endDate &&
        dateRange[0]?.endDate !== 'Invalid date'
      ) {
        setIsDateAdded(true);
        fetchOrders();
      } else {
        setIsDateAdded(false);
      }
    }, [1000]);
  }, [dateRange]);

  const fetchOfficeList = async () => {
    setOfficeListLoading(true);
    const res = await getOfficeList(order?.token, order?.client_id, order?.id);
    if (res?.response?.length) {
      const firstOfc = res?.response?.find((ofc) => ofc.status !== 'disabled');
      if (!firstOfc) {
        setOfficeError(true);
      } else {
        setOfficeError(false);
      }
    }
    setOfficeListLoading(false);
  };

  const fetchClient = async () => {
    setLoading(true);
    const res = await getOfficeList(order?.token, order?.client_id);
    if (res.success) {
      setOffices(res.response || []);
    }
    setLoading(false);
  };

  const fetchOrders = async (isClear = false) => {
    setLoading(true);
    let str = `page=${CurrPage + 1}&per_page=${perPageRecords}&is_listing=yes`;
    Object.keys(filters).forEach((key) => {
      if (filters?.[key]?.length) {
        str = str + `&${key === 'id' ? 'order_id' : key}=${filters?.[key]}`;
      }
    });
    if (dateRange[0]?.startDate && dateRange[0]?.endDate && !isClear) {
      str =
        str +
        `&order_date_from=${moment(
          dateRange?.[0]?.startDate,
          'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
        ).format('YYYY-MM-DD')}&order_date_to=${moment(
          dateRange?.[0]?.endDate,
          'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
        ).format('YYYY-MM-DD')}`;
    }
    if (isSuperAdmin) {
      str = str + `&order_create_type=manual`;
    }
    const res = await getOrders(order?.token, str);
    if (res?.success) {
      setOrderList(res?.response?.data || []);
      setTotal(res?.response?.meta?.total);
    } else {
      setOrderList([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const fetchConfig = async () => {
    setLoading(true);
    const res = await getConfigurations(order?.client_id, order?.token);
    if (res?.success) {
      setConfigurations(res?.response);
      order?.showHideColumns
        ? setShowHideColumns(cloneDeep(order?.showHideColumns))
        : setShowHideColumns(
            res?.response?.listing_column_config && res?.response?.listing_column_config?.length
              ? res?.response?.listing_column_config
              : cloneDeep(defaultColumns)
          );
    } else {
      setShowHideColumns(
        order?.showHideColumns ? order?.showHideColumns : cloneDeep(defaultColumns)
      );
    }
    setLoading(false);
  };

  const statusMapping = { Collecting: 1, Reviewing: 2, Completed: 3 };
  const msgMapping = {
    1: 'Pending participant form filling.',
    2: 'Participant form submitted, pending review.',
    3: 'Participant form data collected and review completed.',
  };

  const tableData = useMemo(() => {
    if (OrderList?.length) {
      return OrderList?.map((orders, idx) => {
        return {
          ...orders,
          created_by: (props) => (
            <div>
              {props?.created_by_user?.length ? (
                <div>{props?.created_by_user} </div>
              ) : (
                <div className='color-p'>Deleted User</div>
              )}
            </div>
          ),
          office_name: (props) => <div>{props?.client_office?.office_name ?? ''}</div>,
          order_create_type_name: (props) => (
            <div className='text-capitalize'>{props?.order_create_type ?? ''}</div>
          ),
          order_status: (props) =>
            props?.is_bank_account_client ? (
              props?.bank_account_number && props?.bank_routing_number ? (
                <div
                  className={`${props?.bank_account_status === 'verified' ? 'color-s' : 'error'} `}
                >
                  Bank account verification completed
                </div>
              ) : (
                <div className='color-p'>Pending</div>
              )
            ) : (
              <CustomStepper
                current={statusMapping[props?.order_status_name] ?? 0}
                message={msgMapping ?? ''}
              />
            ),
          // order_status: (props) => (
          //   <Chip
          //     label={props?.order_status_name}
          //     style={{
          //       height: '20px',
          //       padding: '2px',
          //       borderRadius: '5px',
          //     }}
          //     className={`${props?.order_status_name !== 'Completed' ? 'bg-p' : 'completed-bg'}`}
          //   />
          // ),
          bank_account: (props) => {
            return (
              <div className='d-flex' style={{ flexDirection: 'row' }}>
                <div>
                  <div>Account# : {props?.bank_account_number ?? '-'}</div>
                  <div>ABA : {props?.bank_routing_number ?? '-'}</div>
                </div>
                <div className='ml-8 d-flex align-items-center'>
                  {props?.bank_account_status === 'verified' ? (
                    <Tooltip title={`Participant's bank account is verified successfully.`}>
                      <VerifiedUser className='f-18 color-s' />
                    </Tooltip>
                  ) : (
                    <Tooltip title={`Participant's bank account is not verified.`}>
                      <ErrorOutline className='f-18 error' />
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          },
          property_address: (props) => (
            <Tooltip title={props?.address && props?.address?.length > 40 ? props?.address : ''}>
              <div>
                {' '}
                {props?.address && props?.address?.length > 40
                  ? props?.address?.substring(0, 40) + '...'
                  : props?.address}
              </div>
            </Tooltip>
          ),
          actions: (props) => (
            <div>
              <ActionMenu
                actions={
                  isClientUser
                    ? [
                        {
                          label: 'Order Details',
                          logo: <Visibility className='action-menu-item' />,
                          onClick: () =>
                            navigate(`/${isClientUser ? 'orders' : 'admin/orders'}/${props.id}`, {
                              state: {},
                            }),
                        },
                        {
                          label: 'Archive Order',
                          logo: <Delete className='action-menu-item' />,
                          onClick: () => {
                            setCurRecord(props);
                            setArchiveOrderModalOpen(true);
                          },
                          color: '#ff1241',
                        },
                      ]
                    : [
                        {
                          label: 'Order Details',
                          logo: <Visibility className='action-menu-item' />,
                          onClick: () =>
                            navigate(`/${isClientUser ? 'orders' : 'admin/orders'}/${props.id}`, {
                              state: {},
                            }),
                        },
                        {
                          label: 'Assign Order',
                          logo: <AssignmentInd className='action-menu-item' />,
                          onClick: () => {
                            setSelectedRecord(orders);
                            setOpen(true);
                          },
                        },
                        {
                          label: 'Archive Order',
                          logo: <Delete className='action-menu-item' />,
                          onClick: () => {
                            setCurRecord(props);
                            setArchiveOrderModalOpen(true);
                          },
                          color: '#ff1241',
                        },
                      ]
                }
              />
            </div>
          ),
        };
      });
    }
    return [];
  }, [OrderList]);

  const rowsPerPageOptions = [10];

  const onRowClick = (row) => {
    navigate(`/${isClientUser ? 'orders' : 'admin/orders'}/${row.id}`, {
      state: {},
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    order?.showHideColumns
      ? setShowHideColumns(cloneDeep(order?.showHideColumns))
      : setShowHideColumns(
          configurations?.listing_column_config && configurations?.listing_column_config?.length
            ? configurations?.listing_column_config
            : cloneDeep(defaultColumns)
        );
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = configConfirmationOpen ? 'simple-popover' : undefined;

  const saveUserSettings = async () => {
    // update filters
    let tempFilters = { ...filters };
    for (const col of showHideColumns) {
      if (!col?.show) delete tempFilters?.[filterMapper?.[col?.id]];
    }
    setFilters(tempFilters);

    setAnchorEl(null);
    dispatch(
      login({
        data: { ...order, showHideColumns: cloneDeep(showHideColumns) },
        code: 'order',
      })
    );
    if (isClientAdmin) {
      setConfigConfirmationOpen(true);
    }
  };

  const saveGlobalConfig = async () => {
    setLoading(true);
    const res = await saveConfig(
      {
        client_id: order?.client_id,
        export_column_array: configurations?.export_column_config || [],
        listing_column_array: showHideColumns || [],
      },
      order?.token
    );
    if (res?.success) {
      toast?.success(res?.message);
      setConfigConfirmationOpen(false);
    } else {
      toast?.error(res?.message);
    }
    setLoading(false);
  };

  const handleArchiveOrder = async () => {
    setLoading(true);
    const res = await archiveOrder(
      {
        record_status: 'archived',
        order_id: curRecord?.id,
      },
      order?.token
    );
    if (res?.success) {
      toast?.success(res?.message);
      setArchiveOrderModalOpen(false);
      setCurRecord({});
      await fetchOrders();
    } else {
      toast?.error(res?.message);
    }
    setLoading(false);
  };

  return (
    <Box className='dashboard-main'>
      <ConfigConfirmationModal
        open={configConfirmationOpen}
        setOpen={setConfigConfirmationOpen}
        handleSave={saveGlobalConfig}
      />
      <CommonModal
        open={archiveOrderModalOpen}
        setOpen={setArchiveOrderModalOpen}
        handleSave={handleArchiveOrder}
        title={<div>Archive Order</div>}
        description={`<div style={{ color: 'red' }}>
            Are you sure you want to archive this order? It will no longer be visible in the list.
          </div>`}
      />
      <AssignModal
        open={open}
        setOpen={setOpen}
        record={selectedRecord}
        token={order?.token}
        fetchOrders={fetchOrders}
      />
      {(loading || officeListLoading) && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12} sm={12} xs={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center title-bar'>
                <Typography variant='h4' className='mb-0'>
                  Orders
                </Typography>
                <div className='flex-row'>
                  {offices?.length > 1 ? (
                    <div className='mr-8 table-select-filter'>
                      <label id='demo-simple-select-label'>Office : </label>
                      <Select
                        sx={{
                          // Reset common styles
                          marginLeft: '4px',
                          width: '200px',
                          // Add any additional styles you need
                        }}
                        defaultValue={selectedOffice}
                        displayEmpty={true}
                        value={selectedOffice}
                        onChange={(e) => {
                          setSelectedOffice(e.target.value);
                        }}
                      >
                        {offices?.map((office) => (
                          <MenuItem value={office.office_id} key={office.office_id}>
                            {office.office_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    ''
                  )}
                  {!isAdmin ? (
                    <Button
                      autoFocus
                      onClick={() => {
                        if (officeError)
                          return toast.error(
                            'There are no active offices available for your client. Please enable an office in the Client Offices section before proceeding with the order creation process.'
                          );
                        navigate(`/${isClientUser ? 'orders' : 'admin/orders'}/bulk-upload`);
                      }}
                      variant='contained'
                      color='primary'
                      style={{ padding: '4px 12px' }}
                      className='mr-8'
                    >
                      Upload Bulk Order
                    </Button>
                  ) : (
                    ''
                  )}
                  {!isAdmin ? (
                    <Button
                      autoFocus
                      onClick={() => {
                        if (officeError)
                          return toast.error(
                            'There are no active offices available for your client. Please enable an office in the Client Offices section before proceeding with the order creation process.'
                          );
                        navigate(`/${isClientUser ? 'orders' : 'admin/orders'}/add`);
                      }}
                      variant='contained'
                      color='primary'
                      style={{ padding: '4px 12px' }}
                    >
                      + Create Order
                    </Button>
                  ) : (
                    ''
                  )}
                  <>
                    <Button
                      aria-describedby={id}
                      autoFocus
                      onClick={handleClick}
                      variant='contained'
                      color='primary'
                      style={{ padding: '4px 12px' }}
                      className='ml-8'
                    >
                      <Settings fontSize='small' className='mr-4' />
                      Manage Columns
                    </Button>
                    <Popover
                      id={id}
                      open={popoverOpen}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <div className='flex-column p-8'>
                        <div className='flex-row p-0 show-checkbox'>
                          <Checkbox
                            checked={!showHideColumns?.find((col) => !col?.show)}
                            onChange={(e) => {
                              setShowHideColumns((prevCols) => {
                                let existingCols = [...prevCols];
                                existingCols = existingCols?.map((col) => {
                                  return { ...col, show: e.target.checked };
                                });

                                return existingCols;
                              });
                            }}
                            color='primary'
                            size='small'
                          />
                          <label style={{ minWidth: 'unset' }} className='fw-550 f-13 mt-4'>
                            {'Select All'}
                          </label>
                        </div>
                        {showHideColumns?.map((col, index) => {
                          return (
                            <div className='flex-row p-0 show-checkbox'>
                              <Checkbox
                                checked={col?.show}
                                onChange={(e) => {
                                  setShowHideColumns((prevCols) => {
                                    let existingCols = [...prevCols];
                                    existingCols[index].show = e.target.checked;
                                    return existingCols;
                                  });
                                }}
                                color='primary'
                                size='small'
                              />
                              <label style={{ minWidth: 'unset' }} className='fw-400 f-12 mt-4'>
                                {col?.label}
                              </label>
                            </div>
                          );
                        })}
                        <div className='flex-row mt-8 justify-end'>
                          <Button className='popover-btn' onClick={handleClose}>
                            Cancel
                          </Button>
                          <Button
                            className='popover-btn'
                            variant='contained'
                            onClick={saveUserSettings}
                            disabled={!showHideColumns?.filter((col) => col?.show)?.length}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </Popover>
                  </>
                </div>
              </Grid>
            </CardContent>
            <Box>
              <CardContent>
                {/* <Table
                  loader={loading}
                  columns={filteredColumns}
                  data={tableData}
                  rowsPerPageOptions={rowsPerPageOptions}
                  // linkDialog={true}
                  // openDialog={openDetailModal}
                  count={total}
                  current_page={CurrPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                  isClickable={true}
                  onRowClick={onRowClick}
                /> */}
                <RearrangableTable
                  staticColumns={filteredColumns}
                  data={tableData}
                  rowsPerPageOptions={rowsPerPageOptions}
                  count={total}
                  current_page={CurrPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                  isClickable={true}
                  onRowClick={onRowClick}
                />
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default OrderList;
