import { Tooltip } from '@mui/material';
import '../../styles/StepperParticipantStatus.scss';

const CustomStepper = ({ statusList }) => {
  return (
    <div className='container'>
      <div className='wrapper'>
        <div className='arrow-steps clearfix'>
          {statusList?.map((status, idx) => {
            return (
              <Tooltip title={status?.message}>
                <div
                  className={`step-part ${status?.isCurrent ? 'current' : ''} ${
                    status?.isDone ? 'done' : ''
                  }`}
                  key={idx}
                >
                  <span>{status?.name}</span>{' '}
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CustomStepper;
