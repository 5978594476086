import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '16px 16px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

const SourceDetailsModal = ({ open, setOpen, curData, onVerify }) => {
  const { pathname } = useLocation();
  const { admin } = useSelector((state) => state?.authSlice);
  const { order } = useSelector((state) => state?.authSlice);
  const user =
    pathname.split('/')[1] === 'admin'
      ? admin?.first_name + ' ' + admin?.last_name
      : order?.client_name && !order?.parent_id
      ? order?.client_name
      : order?.first_name + ' ' + order?.last_name;
  const [elementData, setElementData] = useState(cloneDeep(curData));
  useEffect(() => {
    setElementData(cloneDeep(curData));
  }, [curData]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNoteChange = (e) => {
    setElementData((data) => {
      return { ...data, additional_notes: e.target.value };
    });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          style={{ fontSize: '16px' }}
          className='p-12'
        >
          <div className='d-flex align-items-center'>
            <div>Confirmation</div>
          </div>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent className='p-16 source-details-modal' dividers>
          <Grid item md={12} className='f-13 fw-400 mb-8'>
            Would you like to change the value for the field <b>{elementData?.label}</b> to{' '}
            <b>{elementData?.updated_user_value}</b> and verify this field? You can also add a note
            for future reference.
          </Grid>
          <Grid item md={12} className='text-field-container'>
            <label>Note</label>
            <textarea
              id='outlined-basic'
              variant='outlined'
              style={{ width: '100%', border: '1px solid #b8b7b7', borderRadius: '3px' }}
              onChange={handleNoteChange}
              value={elementData?.additional_notes}
            />
          </Grid>
        </DialogContent>
        <DialogActions className='p-10'>
          <Button
            autoFocus
            onClick={handleClose}
            className='p-2'
            color='secondary'
            style={{ color: 'grey', marginRight: '4px' }}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color='primary'
            variant='contained'
            style={{ padding: '4px 8px' }}
            onClick={() => {
              let tempElemData = cloneDeep(elementData);
              tempElemData.note = `Admin user(${user}) has updated this field's value from <b>${tempElemData?.user_value}</b> to <b>${tempElemData?.updated_user_value}</b> and manually verified the field.`;
              tempElemData.user_value = tempElemData?.updated_user_value;
              delete tempElemData?.updated_user_value;
              tempElemData.history = tempElemData?.history?.length
                ? [
                    ...tempElemData.history,
                    {
                      note: tempElemData?.additional_notes,
                      created_by: user,
                      created_by_user_id: admin?.user_id || order?.user_id || '',
                      approval: 1,
                      created_at: moment(new Date()).format('YYYY-MM-DD'),
                    },
                  ]
                : [
                    {
                      note: tempElemData?.additional_notes,
                      created_by: user,
                      created_by_user_id: admin?.user_id || order?.user_id || '',
                      approval: 1,
                      created_at: moment(new Date()).format('YYYY-MM-DD'),
                    },
                  ];
              onVerify([tempElemData]);
            }}
          >
            Save & Confirm
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default SourceDetailsModal;
