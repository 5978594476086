import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, CardContent, Grid, TextField, Button, Tooltip, Checkbox } from '@mui/material';
import Loader from '../../../components/common/Loader';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Help } from '@mui/icons-material';
import { getConfigurations, setConfigurations } from '../../../services/admin/configurations';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../../components/admin/configurations/ConfirmationModal';
import { SUPER_ADMIN_ROLE } from '../../../utils/constants';

const Configurations = () => {
  const { admin } = useSelector((state) => state?.authSlice);
  const { client_id } = useParams();
  const isSuperAdmin = admin?.user_role_name === SUPER_ADMIN_ROLE ? true : false;
  const [loading, setLoading] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [escrowEmailChecked, setEscrowEmailChecked] = useState(false);
  const [emailPartChecked, setEmailPartChecked] = useState(false);
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  useEffect(() => {
    fetchDetails();
  }, [client_id]);

  const onHandleSubmit = async (confirm = false) => {
    let confirmationFlag = isSuperAdmin || confirm == 'yes' ? true : false;
    const data = getValues();
    setLoading(true);
    const payload = {
      ...data,
      client_id: client_id ? client_id : admin?.client_id,
      is_send_reminder_email: emailChecked,
      is_confirmed: confirmationFlag,
      send_form_completed_mail_to_closer: escrowEmailChecked,
      is_sent_invite_link_email: emailPartChecked,
    };
    const res = await setConfigurations(payload, admin?.token);
    if (res.success) {
      if (res?.response?.length) {
        setOrders(res?.response);
        setOpen(true);
      } else {
        await fetchDetails();
        setOpen(false);
        toast.success(res?.message);
      }
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const fetchDetails = async () => {
    setLoading(true);
    const res = await getConfigurations(client_id ? client_id : admin?.client_id, admin?.token);
    if (res?.success) {
      setEmailChecked(res?.response?.is_send_reminder_email === 'yes' ? true : false);
      setEscrowEmailChecked(
        res?.response?.send_form_completed_mail_to_closer === 'yes' ? true : false
      );
      setEmailPartChecked(res?.response?.is_sent_invite_link_email === 'yes' ? true : false);
      reset({
        no_of_days: res?.response?.no_of_days,
        no_of_reminder: res?.response?.no_of_reminder,
      });
    }
    setLoading(false);
  };

  const handleModalClose = () => {
    setOpen(false);
    fetchDetails();
  };

  return (
    <Box className='dashboard-main'>
      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        handleSave={onHandleSubmit}
        handleModalClose={handleModalClose}
        orders={orders}
      />{' '}
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <Box className='client-user-form order-form'>
              <CardContent className='pt-0'>
                <FormProvider>
                  <form
                    onSubmit={handleSubmit(onHandleSubmit)}
                    style={{ marginTop: '25px' }}
                    className='order-form disabled-form-setup'
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} className='mb-24'>
                        <div className='f-16 fw-550 color-p d-flex align-items-center'>
                          Email Configurations
                          <Tooltip
                            title={
                              'You can configure email settings for participants. The invite link should be sent a specific number of times, at specified intervals, etc.'
                            }
                          >
                            <Help className='color-p f-16 ml-4' />
                          </Tooltip>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <label>
                          Do you want to send an email to the escrow officer when the Participant's
                          Question forms are completed?
                        </label>
                        <Checkbox
                          checked={escrowEmailChecked}
                          onChange={(e) => {
                            setEscrowEmailChecked(e.target.checked);
                          }}
                          color='primary'
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <label>Send form invite link to participant via email</label>
                        <Checkbox
                          checked={emailPartChecked}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              setEmailChecked(false);
                              reset({
                                no_of_days: 0,
                                no_of_reminder: 0,
                              });
                            }
                            setEmailPartChecked(e.target.checked);
                          }}
                          color='primary'
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <label>
                          Do you intend to send repetitive emails to participants for invite link?
                        </label>
                        <Checkbox
                          checked={emailChecked}
                          disabled={!emailPartChecked}
                          onChange={(e) => {
                            setEmailChecked(e.target.checked);
                            if (!e.target.checked) {
                              reset({
                                no_of_days: 0,
                                no_of_reminder: 0,
                              });
                            }
                          }}
                          color='primary'
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <label>No. of email reminders.</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name={`no_of_reminder`}
                          control={control}
                          defaultValue=''
                          rules={{ required: !emailChecked ? false : 'This field is required' }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              variant='outlined'
                              fullWidth
                              InputProps={{ inputProps: { min: 1 } }}
                              type='number'
                              disabled={!emailChecked}
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : ''}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <label>Time duration intervals(In days)</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name={`no_of_days`}
                          control={control}
                          defaultValue=''
                          rules={{ required: !emailChecked ? false : 'This field is required' }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              variant='outlined'
                              fullWidth
                              type='number'
                              InputProps={{ inputProps: { min: 1 } }}
                              disabled={!emailChecked}
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : ''}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <div className='d-flex justify-end mt-16'>
                      <Button variant='contained' type='submit'>
                        Save
                      </Button>
                    </div>
                  </form>
                </FormProvider>
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Configurations;
