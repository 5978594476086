import { Alert, Button, Grid, Tab, Tabs, Chip, Tooltip } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
const GroupTabs = ({ groups, current, setCurrent, setManageGroupDrawer, onTabChange, toShow }) => {
  return (
    <div>
      {toShow ? (
        <div className='flex-row'>
          {groups?.length ? (
            <Grid container className='d-flex justify-between'>
              <Grid item className='tab-title pt-20' md={0.4}>
                <Tooltip title='Groups for the section'>
                  {' '}
                  <GroupsIcon style={{ marginRight: '2px', color: 'grey' }} />
                </Tooltip>

                {/* <Chip
                  color='primary'
                  // icon={
                  //   <GroupsIcon fontSize='small' style={{ marginRight: '2px', color: 'grey' }} />
                  // }
                  label='Groups'
                  variant='outlined'
                /> */}
              </Grid>
              <Grid item md={10.6} className='group-tabs-list'>
                <Tabs
                  value={current}
                  onChange={(event, newValue) => {
                    const isValidated = onTabChange();
                    if (!isValidated) return;
                    setCurrent(newValue);
                  }}
                  sx={{ borderBottom: 1, borderColor: 'divider' }}
                  className='mt-16 group-tab'
                  variant='scrollable'
                  scrollButtons={true}
                  aria-label='scrollable auto tabs example'
                >
                  {groups.map((group) => (
                    <Tab label={group} />
                  ))}
                </Tabs>
              </Grid>
              <Grid item md={1} className='flex-center flex-end-imp'>
                <div className='flex-center mt-20 btn-edit'>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => {
                      setManageGroupDrawer(true);
                    }}
                  >
                    Update
                  </Button>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Alert severity='warning' style={{ width: '100%' }} className='mt-20'>
              No groups are there. Click{' '}
              <a style={{ color: 'blue' }} onClick={() => setManageGroupDrawer(true)}>
                here
              </a>{' '}
              here to create groups for section!
            </Alert>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default GroupTabs;
