import { authorizedApi } from '../../utils/api';

export const getSources = (token, currPage = 1, per_page = 10) => {
  return authorizedApi(token).get(
    `/admin/participant-que-condition?page=${currPage}&per_page=${per_page}`
  );
};

export const getSource = (id, token) => {
  return authorizedApi(token).get(`/admin/participant-que-condition/${id}`);
};

export const addSource = (data, token) => {
  return authorizedApi(token).post(`/admin/participant-que-condition`, data);
};

export const updateSource = (id, data, token) => {
  return authorizedApi(token).put(`/admin/participant-que-condition/${id}`, data);
};

export const deleteSource = (id, token) => {
  return authorizedApi(token).delete(`/admin/participant-que-condition/${id}`);
};

export const getSourceList = (token) => {
  return authorizedApi(token).get(`/sources/list`);
};

export const getFieldList = (token) => {
  return authorizedApi(token).get(`/admin/template-element-list`);
};
