import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, ErrorOutline, VerifiedUser } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BankAccountVerificationModal = ({ open, setOpen, handleContinue, error }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          style={{ fontSize: '16px' }}
          className='font-family-main'
        >
          {'Bank Account Verification'}
          {error?.length ? (
            <ErrorOutline
              color='error'
              style={{ fontSize: '16px', marginBottom: '-2px' }}
              className='ml-8'
            />
          ) : (
            <VerifiedUser
              style={{ fontSize: '16px', marginBottom: '-2px', color: 'green' }}
              className='ml-8'
            />
          )}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          {error?.length ? <div className='error f-13 fw-600 mb-8'>{error}</div> : ''}
          <Typography
            gutterBottom
            className='d-flex align-center ml-2 font-family-main fw-500 f-13'
          >
            {!error?.length
              ? `Your bank account has been successfully verified. Please click the 'CONTINUE' button to proceed with filling out the rest of the form.`
              : `Your bank account is not verified. You can temporarily skip this verification by clicking the 'SKIP' button. If you prefer to update your bank details, click the 'STAY & UPDATE' button.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          {error?.length ? (
            <Button
              autoFocus
              onClick={handleClose}
              style={{ padding: '2px 8px', color: 'grey' }}
              color='secondary'
            >
              Stay & Update
            </Button>
          ) : (
            ''
          )}

          <Button onClick={handleContinue} style={{ padding: '2px 8px' }} variant='contained'>
            {error?.length ? 'Skip' : 'Continue'}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default BankAccountVerificationModal;
