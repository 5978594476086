import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '8px 16px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

const ValueSelectionModal = ({ open, setOpen, data, onSelect, value }) => {
  const [radioValue, setRadioValue] = useState('');

  useEffect(() => {
    setRadioValue(value);
  }, [value]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitHandle = () => {
    if (!radioValue?.length) return;
    onSelect(radioValue);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          style={{ fontSize: '16px' }}
          className='p-12'
        >
          <div className='d-flex align-items-center'>
            <div>Select Value </div>
          </div>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers className='p-16'>
          <Grid container className='pl-12' columnSpacing={1} rowSpacing={1}>
            {data?.map((element) => {
              return (
                <Grid md={4}>
                  <FormControlLabel
                    style={{ minWidth: 'unset' }}
                    checked={radioValue === element.value}
                    onChange={(e) => {
                      setRadioValue(e.target.value);
                    }}
                    value={element.value}
                    control={<Radio />}
                    label={element.value}
                  >
                    {element.value}
                  </FormControlLabel>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions className='p-10'>
          <Button
            autoFocus
            onClick={handleClose}
            className='p-2'
            color='secondary'
            style={{ color: 'grey', marginRight: '4px' }}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color='primary'
            variant='contained'
            className='p-4'
            disabled={!radioValue?.length}
            onClick={() => {
              onSubmitHandle();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default ValueSelectionModal;
