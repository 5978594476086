// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    translation: {
      language: 'Language',
      languageMessage: 'You can view the participant form in various available languages.',
      propertyAddress: 'Property Address',
      orderDetails: 'Order Details',
      fileNumber: 'File Number',
      title: 'Title / Escrow Company',
      contact: 'Contact Information',
      note: 'Note : Your progress in previous sections is automatically saved. However, the details of the current section will not be saved automatically unless you click on the NEXT STEP button at the end of the section.',
      notePOA:
        'Note: A power of attorney, also known as an “attorney-in-fact” is used in many real estate transactions to allow someone else to be able to complete closings or giving someone else the power to sell your properties.',
      noteAdditionalService:
        'Note : You can add an additional service and its relevant details if it is not listed here.',
      addParticipant: 'Add Participant',
      addParticipantConfirmation:
        ' <b>Would you like to add an additional participant to this transaction?</b> You will be prompted to provide basic information <b>(Name, Email, Phone Number, SSN)</b> for any additional participants.',
      serviceLabel: 'Label For Service',
      serviceLabelRequired: 'Service Label is required',
      addHOA: 'Add Another HOA',
      addLoan: 'Add Another Loan',
      addBuyer: 'Add Another Buyer',
      addBorrower: 'Add Another Borrower',
      addSeller: 'Add Another Seller',
      addCard: 'Add Credit Card',
      addLien: 'Add Lien Details',
      addUtility: 'Add Utility Service',
      noteHOA:
        '<b>Would you like to add an additional HOA for this transaction?</b> You will be prompted to provide basic information <b>(Association Name, Management Company, Association Dues)</b> for any additional HOA.',
      noteLoan:
        '<b>Would you like to add an additional Loan for this transaction?</b> You will be prompted to provide basic information <b>(Lender Name, Loan Number, Approximate Balance)</b> for any additional Loans.',
      noteBuyer:
        '<b>Would you like to add an additional buyer for this transaction?</b> You will be prompted to provide basic information <b>(Buyer Name, Email, Phone, DOB, etc.)</b> for any additional buyer.',
      noteBorrower:
        '<b>Would you like to add an additional borrower for this transaction?</b> You will be prompted to provide basic information <b>(Name, Email, Phone Number, SSN)</b> for any additional borrower.',
      lastSection: 'Signature & Documents',
      participantSignature: 'Participant Signature',
      signRequired: 'Please add signature to proceed further.',
      additionalSignature: "Additional Participant's Signature",
      lastStepMessage1: 'These document(s) will be automatically signed electronically at the end.',
      lastStepMessage2: `The form will NOT be submitted until ALL documents are reviewed, signature is
                  added and the submit button is clicked.`,
      congratulations: 'Congratulations',
      download: 'DOWNLOAD FINAL FORM',
      submissionMessage1: 'Completed. Your documents have been successfully completed.',
      submissionMessage2:
        'You are all set. We have sent an email to your spouse and/or any additional parties for their signatures. Once the signatures are complete, the documents will be forwarded to',
      submissionMessage3: ' Secured and authenticated by VoxturVerify California Inc ™',
      somethingWrong: 'Something went wrong!',
      creditCardLabel: 'Credit Card Information',
      creditCardMessage:
        'By providing your card information, you allow localhost:3000 to charge your card for   future payments in accordance with their terms.',
      creditCardSuccess: 'Credit card has been added successfully.',
      pendingReview: 'Pending Review',
      docReviewed: 'You have reviewed this document.',
      additionalDocs: 'Additional Documents',
      signRequired2: 'Please add signature first to proceed further for documents.',
      qrNote: `Please click the provided link below or use your mobile phone to scan the QR code
                and follow the instructions to capture images to verify your government issued ID.
                You will only be allowed to proceed further upon successful verification.`,
      fnameError: `The First Name or Last Name does not appear to be the same as the name on your uploaded
              Government-issued ID`,
      mailingAddressError: `Your mailing address does not match the address on your uploaded government issued ID.
              Click on the "Fetch Address" button to fetch the address from the government issued ID
              into the Mailing Address fields.`,
      mobyError: `We're having trouble verifying your Government ID electronically. We'll proceed with manual verification using your last uploaded images. If you'd like to upload new images, please click the 'Upload for Manual Verification' button, or click 'Use Last Images' to continue with the existing ones.`,
      signDoc: 'You can only add your signature after reviewing all the documents.',
      signPlaceholder: 'Sign here and click save when completed.',
      mobyUpload: 'Click here to upload from your computer',
      govIdHeader: 'Uploaded Government Issued ID',
      verified: 'Verified',
      pending: 'Pending',
      nameErrorMsg: `The value in this field does not appear to be the same as the name on your uploaded
              Government-issued ID. Do you want to update this field to match ?`,
      yes: 'Yes',
      no: 'No',
      fetchAdr: 'Fetch Address',
      uploadIdModalHeader: 'Upload Government Issued ID',
      dReview: 'Document Review',
      dReviewMessage1:
        'All documents need to be reviewed, and a signature must be added before proceeding further.',
      dReviewMessage2: `The form will be finalized and submitted, you won't be able to change after submission`,
      prevStep: 'Prev Step',
      nextStep: 'Next Step',
      stay: 'Stay',
      next: 'Next',
      validation: 'Missing Information',
      close: 'Close',
      upload: 'Upload',
      invitationError: `We're generating a QR code or invitation link for your government ID verification. Please wait a moment. If you still don't see the QR code or invitation link, please reload the page`,
      regenerate: 'Regenerate',
      uploadNewImages: 'Upload for Manual Verification',
      useLastImages: 'Use Last Images',
      dlError: `A copy of your government issued ID has not been uploaded. Please click the 'GO BACK TO SECTION' button to navigate to the specific section and upload the government issued ID. Once uploaded, you can submit the form.`,
      validationErrorLastStep: `Below mentioned sections of the form contain incomplete(required) or invalid field(s). Please click the 'GO BACK TO SECTION' button to navigate to the specific section and complete the missing information. Once completed, you can submit the form.`,
      combinedErrorLastStep: `A copy of your government-issued ID has not been uploaded, and below mentioned sections of the form contain incomplete(required) or invalid field(s). Please click the 'GO BACK TO SECTION' button to navigate to the specific section and complete the missing information. Once completed, you can submit the form.`,
      goToSection: 'Go Back to Incomplete Section',
      stepValidationMessage: `The required fields can't be left blank. You can temporarily skip to the next section but remember to go back and complete the data input.`,
      stepDlValidationMessage: `You haven't uploaded your government-issued ID yet. You can temporarily skip to the next section, but please remember to return and complete the ID verification process.`,
      bothValidationFailedMessage: `The required fields can't be left blank. You can temporarily skip to the next section, but please remember to return and complete all data input, including uploading your government-issued ID for verification.`,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // default language
  fallbackLng: 'en', // fallback language
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export const addTranslations = (lng, translations) => {
  if (!i18n.hasResourceBundle(lng, 'translation')) {
    i18n.addResourceBundle(lng, 'translation', translations);
  } else {
    i18n.addResources(lng, 'translation', translations);
  }
  i18n.changeLanguage(lng); // Switch to the selected language
};

export default i18n;
