import { authorizedApi } from '../../utils/api';

export const getTemplates = (token, currPage = 1, per_page = 10, clientId, officeId) => {
  let url = `/admin/templates?page=${currPage}&per_page=${per_page}`;
  if (clientId) url += `&client_id=${clientId}`;
  if (officeId) url += `&client_office_id=${officeId}`;
  return authorizedApi(token).get(url);
};

export const getClientTemplates = (token, client_id, currPage = 1, per_page = 10, officeId) => {
  return authorizedApi(token).get(
    `/admin/client/templates/${client_id}?page=${currPage}&per_page=${per_page}&client_office_id=${officeId}`
  );
};

export const getTemplate = (id, token) => {
  return authorizedApi(token).get(`/admin/templates/${id}`);
};

export const addTemplate = (data, token) => {
  return authorizedApi(token).post(`/admin/templates`, data);
};

export const updateTemplate = (id, data, token) => {
  return authorizedApi(token).put(`/admin/templates/${id}`, data);
};

export const deleteTemplate = (id, token) => {
  return authorizedApi(token).delete(`/admin/templates/${id}`);
};

export const getDocument = (template_id, element_name, token) => {
  return authorizedApi(token).get(`/templates/doc/${template_id}/${element_name}`);
};

export const uploadDocument = (data, config, token) => {
  return authorizedApi(token).post(`/admin/templates/doc`, data, config);
};

export const deleteDocument = (id, element_name, token) => {
  return authorizedApi(token).delete(
    `/admin/templates/doc/${id}?form_element_name=${element_name}`
  );
};
