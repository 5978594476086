import { Alert, Button, Grid, Tab, Tabs, Chip, Tooltip } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
const SectionTabs = ({
  data,
  current,
  setCurrent,
  setCurrentGroup,
  setManageSectionDrawer,
  onTabChange,
}) => {
  const sections = data?.sections?.map((section) => section.section_label) || [];
  return (
    <div className='flex-row'>
      {sections?.length ? (
        <Grid container>
          <Grid item className='tab-title' md={0.4}>
            {/* <Chip
              color='primary'
              // icon={<GridViewIcon fontSize='small' style={{ marginRight: '2px', color: 'grey' }} />}
              label='Sections'
              variant='outlined'
            /> */}
            <Tooltip title='Sections'>
              {' '}
              <GridViewIcon style={{ marginRight: '2px', color: 'grey' }} />
            </Tooltip>
          </Grid>
          <Grid item md={10.6} className='section-tabs-list'>
            <Tabs
              value={current}
              onChange={(event, newValue) => {
                const isValidated = onTabChange();
                if (!isValidated) return;
                setCurrent(newValue);
                setCurrentGroup(0);
              }}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              variant='scrollable'
              scrollButtons={true}
              aria-label='scrollable auto tabs example'
              className='section-tab'
            >
              {sections.map((section) => (
                <Tab label={section} id={section} sx={{ fontWeight: '700' }} />
              ))}
            </Tabs>
          </Grid>
          <Grid item md={1} className='flex-center flex-end-imp'>
            <div className='flex-center btn-edit'>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => {
                  setManageSectionDrawer(true);
                }}
              >
                Update
              </Button>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Alert severity='warning' style={{ width: '100%' }}>
          No sections are there. Click{' '}
          <a style={{ color: 'blue' }} onClick={() => setManageSectionDrawer(true)}>
            here
          </a>{' '}
          here to create sections!
        </Alert>
      )}
    </div>
  );
};
export default SectionTabs;
