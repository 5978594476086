import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ConfirmationModal = ({ open, setOpen, handleSave }) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          className='customized-dialog-title'
          style={{ fontSize: '16px' }}
        >
          {t('addParticipant')}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>

        <DialogContent dividers>
          <div
            className='f-15 mt-8 mb-8 fw-500'
            style={{ lineHeight: '1.5' }}
            dangerouslySetInnerHTML={{ __html: t('addParticipantConfirmation') }}
          >
            {/* {} */}
          </div>
        </DialogContent>
        <DialogActions className='mt-8'>
          <Button
            autoFocus
            onClick={handleClose}
            className='p-2'
            color='secondary'
            style={{ color: 'grey' }}
          >
            Close
          </Button>
          <Button
            autoFocus
            onClick={handleSave}
            variant='contained'
            color='primary'
            className='p-2 btn-participentType'
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default ConfirmationModal;
