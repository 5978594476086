import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { PERSIST, PURGE } from 'redux-persist/es/constants';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import storage from 'redux-persist/lib/storage';
import slices from './slices/index';

const rootReducer = combineReducers(slices);

const persistConfig = {
  key: 'VoxturVerify',
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const syncConfig = {
  blacklist: [PERSIST, PURGE],
};
const middleware = [createStateSyncMiddleware(syncConfig)];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middleware),
});

initMessageListener(store);

export const persistor = persistStore(store);
