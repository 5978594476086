import api, { authorizedApi } from '../../utils/api';

export const getCompanyData = (id, token) => {
  return api().get(`/company/${id}`);
};
export const getOrderData = (code, token) => {
  return authorizedApi(token).get(`/participant/order/${code}`);
};

export const getSignature = (token, type, order_id, participant_no = 1, id) => {
  return authorizedApi(token).get(
    `/participant/signature?sign_user_type=${type}&order_id=${order_id}&participant_no=${participant_no}&order_part_participant_id=${id}`
  );
};

export const getQuestionDetails = (id, token) => {
  return authorizedApi(token).get(`/question/${id}`);
};

export const uploadSignature = (data, config, token) => {
  return authorizedApi(token).post(`/participant/signature`, data, config);
};

export const uploadDocument = (data, config, token) => {
  return authorizedApi(token).post(`/participant/document`, data, config);
};

export const getDocumentDetails = (params, token) => {
  return authorizedApi(token).get(`/participant/document`, { params });
};

export const deleteDocument = (id, params, token) => {
  return authorizedApi(token).delete(`/participant/document/${id}`, { params });
};

export const saveAnswer = (data, token) => {
  return authorizedApi(token).post(`/participant-question-answer`, data);
};

export const downloadDocument = (id, token) => {
  return authorizedApi(token).get(`/participant/pdf/${id}`);
};

export const downloadDocumentAdmin = (id, user_id, token) => {
  return authorizedApi(token).get(`/participant/pdf/${id}?order_participant_id=${user_id}`);
};

export const downloadOtherDocuments = (id, token, docType, participant_no = 1) => {
  return authorizedApi(token).get(
    `/participant/additional/pdf/${id}?doc_type=${docType}&participant_no=${participant_no}`
  );
};

export const downloadFromMediaLink = (element_name, token) => {
  // return authorizedApi(token).get(`/participant/additional/pdf/?doc_type=${element_name}`);
};

export const addCard = (data, token) => {
  return authorizedApi(token).post(`/participant/save/card`, data);
};

export const getCard = (id, token) => {
  return authorizedApi(token).get(`/participant/card?order_part_participant_id=${id}`);
};
export const getFullSNN = (data, token) => {
  return authorizedApi(token).get(`/participant/ssn`, data);
};
export const translateContent = (data, token) => {
  return authorizedApi(token).post(`/translate/content`, data);
};

export const verifyBankDetails = (data, token) => {
  return authorizedApi(token).post(`/participant/verify/bank/detail`, data);
};

export const getDocList = (
  order_part_participant_id,
  order_participant_id,
  token,
  is_from_admin = false
) => {
  return authorizedApi(token).get(
    `/participant/additional/docs/list/${order_part_participant_id}/${order_participant_id}?is_from_admin=${is_from_admin}`
  );
};