import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Grid,
  CardContent,
  Box,
  Typography,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { addUser, updateUser, getUser, getTimeZones } from '../../../services/admin/users';
import { getClients, getOfficeList } from '../../../services/admin/users';
import {
  CLIENT_ADMIN_ROLE,
  CLIENT_USER_ROLE,
  OFFICE_ADMIN_ROLE,
  OFFICE_USER_ROLE,
  ROLES_FOR_CLIENT_ADMIN,
  ROLES_FOR_SUPER_ADMIN,
  SUPER_ADMIN_ROLE,
} from '../../../utils/constants';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/slices/breadcrumbSlice';
import AssignOrderModal from '../../../components/admin/users/AssignOrderModal';
import { reassignOrder } from '../../../services/order/order';

const AddEditUser = () => {
  const { state, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('client_id');
  const { id } = useParams();
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state?.authSlice);
  const isSuperAdmin = admin?.user_role_name === SUPER_ADMIN_ROLE ? true : false;
  const isClientAdmin = admin?.user_role_name === CLIENT_ADMIN_ROLE ? true : false;
  const isOfficeAdmin = admin?.user_role_name === OFFICE_ADMIN_ROLE ? true : false;
  const client_id = admin?.client_id;
  const parent_id = admin?.id;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [clientList, setClientList] = useState([]);
  // const [clientAdminList, setAdminClientList] = useState([]);
  const [officeList, setOfficeList] = useState([]);
  const [client, setClient] = useState('');
  const [office, setOffice] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [show, setShow] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const [timeZones, setTimeZones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  // const [parentId, setParentId] = useState('');
  const [role, setRole] = useState('');
  const roles =
    admin?.user_role_name === OFFICE_ADMIN_ROLE ? ROLES_FOR_CLIENT_ADMIN : ROLES_FOR_SUPER_ADMIN;
  const canAddOffice =
    (admin?.user_role_name === CLIENT_ADMIN_ROLE || admin?.user_role_name === SUPER_ADMIN_ROLE) &&
    role !== CLIENT_ADMIN_ROLE &&
    role !== CLIENT_USER_ROLE;

  useEffect(() => {
    if (clientId) {
      setClient(clientId);
    }
  });
  useEffect(() => {
    if (!id) {
      setRole(roles?.[0]?.name);
    }
    if (!isSuperAdmin) {
      setClient(client_id);
    }
  }, []);

  useEffect(() => {
    if (id) fetchUser();
  }, [id]);

  useEffect(() => {
    fetchTimeZones();
    fetchClientList();
  }, []);

  useEffect(() => {
    if (client) fetchOfficeList();
  }, [client]);

  // useEffect(() => {
  //   // if (client) fetchClientAdminList();
  // }, [client]);

  useEffect(() => {
    if (userDetails?.first_name) {
      dispatch(
        setBreadcrumb({
          data: [
            {
              title: 'Users',
              url: '/admin/users',
            },
            {
              title: userDetails?.first_name + ' ' + userDetails.last_name,
              url: '/admin/users/:id',
            },
          ],
          key: pathname,
        })
      );
    }
  }, [userDetails?.first_name]);

  const schema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    password: id
      ? ''
      : yup
          .string()
          .required('Password is required')
          .min(6, 'Password must be at least 6 characters'),

    status: yup.string().required('Status is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchUser = async () => {
    setLoading(true);
    reset();
    const res = await getUser(id, admin?.token);
    if (res.success) {
      reset({ ...res.response });
      setUserDetails({ ...res.response });
      setRole(res?.response?.user_role_name);
      setSelectedTimeZone(res?.response?.user_timezone);
      // if (res?.response?.parent_id) setParentId(res?.response?.parent_id);
      setClient(res?.response?.client_id);
      if (
        res?.response?.offices?.length === 1 &&
        res?.response?.user_role_name !== CLIENT_ADMIN_ROLE
      ) {
        setOffice(res?.response?.offices?.[0]?.client_office_id);
      }
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const fetchClientList = async () => {
    setLoading(true);
    const res = await getClients(admin?.token);
    if (res?.success) {
      setClientList(res?.response || []);
      if (!id && isSuperAdmin && !clientId) {
        setClient(res?.response?.[0]?.id);
      }
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const fetchOfficeList = async () => {
    setLoading(true);
    const res = await getOfficeList(
      admin?.token,
      client,
      admin.user_role_name === OFFICE_ADMIN_ROLE ? parent_id : ''
    );
    if (res?.success) {
      // let activeOffices = res?.response?.filter((ofc) => ofc.status !== 'disabled');
      setOfficeList(res?.response || []);
      const firstOfc = res?.response?.find((ofc) => ofc.status !== 'disabled');
      if (!firstOfc && !id) {
        toast.error('No active office is available to create a user.');
        setTimeout(() => {
          setLoading(false);
          navigate(-1);
        }, 3000);
      }
      if (res?.response?.length === 1 && !id) {
        setOffice(res?.response?.[0]?.office_id);
      }
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const fetchTimeZones = async () => {
    setLoading(true);
    const res = await getTimeZones(admin?.token);
    if (Object.keys(res || {})?.length) {
      setTimeZones(Object.keys(res || {})?.map((tz) => res[tz]) || []);
      if (!id) setSelectedTimeZone(res?.[0]);
    }
    setLoading(false);
  };

  const onSubmit = async (data, _, skipCheck = false) => {
    if (id && data.status === 'inactive' && !skipCheck) {
      setShow(true);
      setUpdatedData(data);
      return;
    }
    if (!selectedTimeZone) return;
    if (!client) return;
    if (!role) return;
    if ((role === OFFICE_USER_ROLE || role === OFFICE_ADMIN_ROLE) && !office) return;

    setLoading(true);
    let bodyData = data;
    bodyData.client_id = client;
    if (role !== CLIENT_ADMIN_ROLE && role !== CLIENT_USER_ROLE) {
      bodyData.client_office_id = office ?? '';
      if (!office && officeList?.length === 1) {
        bodyData.client_office_id = officeList?.[0]?.office_id;
      }
    }
    bodyData.role_type = role;
    bodyData.timezone = selectedTimeZone ? selectedTimeZone : '';
    const res = id
      ? await updateUser(id, bodyData, admin?.token)
      : await addUser(bodyData, admin?.token);
    if (res?.success) {
      toast.success(res?.message);
      setUpdatedData({});
      setShow(false);
      if (id) {
        fetchUser();
      } else {
        navigate(`/admin/users`);
      }
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const onReassign = async (payload) => {
    setLoading(true);
    const res = await reassignOrder(payload, admin?.token);
    if (res.success) {
      await onSubmit(updatedData, '', true);
    } else {
      setLoading(false);
      toast.error(res.message || 'Something went wrong!');
    }
  };
  return (
    <Box className='dashboard-main'>
      <AssignOrderModal
        open={show}
        record={userDetails}
        setOpen={setShow}
        onSave={onReassign}
        onOtherOperations={() => {
          onSubmit(updatedData, '', true);
        }}
        token={admin?.token}
        btnName='Update'
        note={`<b>Note:</b> You can assign below listed orders(Escrow No.) created by
            ${
              userDetails.first_name + ' ' + userDetails.last_name
            } to other available users after inactivating
            this user.`}
      />
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <Box sx={{ mt: 2 }} className='client-user-form'>
              <CardContent>
                <form onSubmit={handleSubmit(onSubmit)} className='user-form'>
                  <fieldset
                    disabled={state?.disabled ? true : false}
                    className='m-0 p-0 border-none'
                  >
                    <Grid container spacing={2}>
                      <Grid item md={12} className='d-flex justify-between align-center sub-header'>
                        <Typography
                          variant='h4'
                          className='mb-0'
                          display={
                            isSuperAdmin
                              ? ''
                              : role === CLIENT_ADMIN_ROLE || role === CLIENT_USER_ROLE
                              ? 'none'
                              : ''
                          }
                        >
                          {'Client Information'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} display={!isSuperAdmin ? 'none' : ''}>
                        <label>Client</label>
                        <label className='required'>{'*'}</label>
                        <FormControl fullWidth variant='outlined' error={!!errors.client_id}>
                          <Select
                            disabled={
                              state?.disabled || isClientAdmin || !!id || clientId ? true : false
                            }
                            onChange={(e) => {
                              setOffice(null);
                              setClient(e.target.value);
                              // setParentId(null);
                            }}
                            value={client}
                          >
                            {clientList?.map((client) => (
                              <MenuItem value={client.id} key={client.id}>
                                {client.full_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {!loading && !client ? (
                          <span className='error mt-4 f-12' style={{ display: 'inherit' }}>
                            Client field is required.
                          </span>
                        ) : (
                          ''
                        )}
                      </Grid>

                      {role !== CLIENT_ADMIN_ROLE && role !== CLIENT_USER_ROLE ? (
                        <Grid item xs={client && canAddOffice ? 5 : 6}>
                          <label>Office</label>
                          <label className='required'>{'*'}</label>

                          <FormControl fullWidth variant='outlined' error={!!errors.status}>
                            <Select
                              disabled={state?.disabled ? true : false}
                              onChange={(e) => {
                                setOffice(e.target.value);
                              }}
                              value={office}
                            >
                              {officeList?.map((ofc) => (
                                <MenuItem
                                  value={ofc.office_id}
                                  key={ofc.office_id}
                                  disabled={ofc.status === 'disabled'}
                                >
                                  {ofc.office_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          {!loading &&
                          !office &&
                          (role === OFFICE_ADMIN_ROLE || role === OFFICE_USER_ROLE) &&
                          officeList?.length ? (
                            <span className='error f-12 mt-4' style={{ display: 'inherit' }}>
                              Office field is required.
                            </span>
                          ) : (
                            ''
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}
                      {client && canAddOffice ? (
                        <Grid xs={1}>
                          <div
                            className='d-flex justify-center align-items-center'
                            style={{ height: '130%' }}
                          >
                            <Tooltip title={'Add new office for selected client.'}>
                              <Button
                                onClick={() => {
                                  navigate(
                                    isSuperAdmin
                                      ? `/admin/clients/${client}`
                                      : `/admin/clients/${admin.user_id}/${admin.client_id}`
                                  );
                                }}
                              >
                                Add Office
                              </Button>
                            </Tooltip>
                          </div>
                        </Grid>
                      ) : (
                        ''
                      )}
                      <Grid item md={12} className='d-flex justify-between align-center sub-header'>
                        <Typography variant='h4' className='mb-0'>
                          {'User Information'}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Role</label>
                        <label className='required'>{'*'}</label>

                        <FormControl fullWidth variant='outlined'>
                          <Select
                            disabled={state?.disabled ? true : false}
                            value={role}
                            onChange={(e) => {
                              setRole(e.target.value);
                              if (e.target.value === CLIENT_ADMIN_ROLE) {
                                setOffice(null);
                              }
                            }}
                          >
                            {roles.map((role) => {
                              return <MenuItem value={role?.name}>{role?.label}</MenuItem>;
                            })}
                          </Select>
                        </FormControl>
                        {!loading && !role && (
                          <span className='error f-12 mt-4' style={{ display: 'inherit' }}>
                            Role field is required.
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <label>First Name</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='first_name'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter first name'
                              variant='outlined'
                              fullWidth
                              error={!!errors.first_name}
                              helperText={errors.first_name?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>Last Name</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='last_name'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter last name'
                              variant='outlined'
                              fullWidth
                              error={!!errors.last_name}
                              helperText={errors.last_name?.message}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <label>Email</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='email'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter email'
                              variant='outlined'
                              fullWidth
                              error={!!errors.email}
                              helperText={errors.email?.message}
                              autoComplete='off'
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} display={id ? 'none' : ''}>
                        <label>Password</label>
                        {!id && <label className='required'>{'*'}</label>}
                        <Controller
                          name='password'
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={id ? true : false}
                              placeholder='Enter password'
                              variant='outlined'
                              fullWidth
                              type={togglePassword ? 'text' : 'password'}
                              error={!!errors.password}
                              autoComplete='new-password'
                              helperText={errors.password?.message}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    {togglePassword ? (
                                      <Visibility
                                        fontSize={'small'}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setTogglePassword((toggle) => !toggle)}
                                      />
                                    ) : (
                                      <VisibilityOff
                                        fontSize={'small'}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setTogglePassword((toggle) => !toggle)}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>

                      {/* {role === 'client user' && (
                        <Grid item xs={6} display={isClientAdmin ? 'none' : ''}>
                          <label>Parent Client Admin</label>
                          <label className='required'>{'*'}</label>
                          <FormControl fullWidth variant='outlined' error={!!errors.client_id}>
                            <Select
                              disabled={state?.disabled || isClientAdmin ? true : false}
                              onChange={(e) => {
                                setOffice(null);
                                setParentId(e.target.value);
                              }}
                              value={parentId}
                            >
                              {clientAdminList?.map((client) => (
                                <MenuItem value={client.id} key={client.id}>
                                  {client.full_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {!loading && !parentId && (
                            <span className='error mb-8 f-12 mt-4' style={{ display: 'inherit' }}>
                              Parent client admin field is required.
                            </span>
                          )}
                        </Grid>
                      )} */}
                      <Grid item xs={6}>
                        <label>Timezone</label>
                        <label className='required'>{'*'}</label>

                        <FormControl fullWidth variant='outlined' error={!!errors.status}>
                          <Select
                            disabled={state?.disabled ? true : false}
                            onChange={(e) => {
                              setSelectedTimeZone(e.target.value);
                            }}
                            value={selectedTimeZone}
                          >
                            {timeZones?.map((tz) => (
                              <MenuItem value={tz} key={tz}>
                                {tz}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {!loading && !selectedTimeZone ? (
                          <span className='error f-12 mt-4' style={{ display: 'inherit' }}>
                            Timezone field is required.
                          </span>
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <label>Status</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='status'
                          control={control}
                          defaultValue='active'
                          render={({ field }) => (
                            <FormControl fullWidth variant='outlined' error={!!errors.status}>
                              <Select {...field} disabled={state?.disabled ? true : false}>
                                <MenuItem value='active'>Active</MenuItem>
                                <MenuItem value='inactive'>Inactive</MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </fieldset>
                  <Grid item xs={12}>
                    <div className='d-flex justify-end mt-16'>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                          reset();
                          navigate('/admin/users');
                        }}
                        className='mr-8'
                      >
                        Cancel
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={state?.disabled ? true : false}
                      >
                        {'Save'}
                      </Button>
                    </div>
                  </Grid>
                </form>
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddEditUser;
