import React, { useState } from 'react';
// import { DateRangePicker } from 'react-dates';
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

const DateRangeSelector = ({
  startDate,
  endDate,
  setDate,
  startDateName,
  endDateName,
  title,
}) => {

  return (
    <div title={title}>
      {/* <DateRangePicker
        startDate={startDate}
        startDateId='start_date'
        endDate={endDate}
        endDateId='end_date'
        onDatesChange={({ startDate, endDate }) => {
          setDate(startDateName, startDate);
          setDate(endDateName, endDate);
        }}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        displayFormat='MM/DD/YYYY'
        isOutsideRange={() => false}
        startDatePlaceholderText={'From Date'}
        endDatePlaceholderText={'To Date'}
        minimumNights={0}
        showClearDates={true}
        readOnly
      /> */}
      <DateRangePicker
        onChange={(val) => {
          setDate(startDateName, val?.[0]);
          setDate(endDateName, val?.[1]);
        }}
        value={[startDate || '', endDate || '']}
        rangeDivider='->'
        showLeadingZeros={true}
      />
    </div>
  );
};

export default DateRangeSelector;
