import { InputAdornment, TextField, IconButton, Select, MenuItem, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { DateRange } from 'react-date-range';

const FilterComponent = ({
  values,
  setValues,
  field,
  dateRange,
  setDateRange,
  type,
  options,
  setAnchor,
}) => {
  return (
    <div className={type === 'select' ? 'p-2' : 'p-4'}>
      {type === 'text' ? (
        <TextField
          // placeholder={`Enter ${label ? label : 'value'} to search`}
          variant='outlined'
          focused
          autoFocus
          value={values[field]}
          className='filter-text'
          onChange={(e) => setValues({ ...values, [field]: e.target.value })}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Tooltip title='Clear Input'>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setValues({ ...values, [field]: '' });
                    }}
                  >
                    <CloseIcon
                      // fontSize='small'
                      styles={{ color: 'grey', cursor: 'pointer', fontSize: 'small' }}
                    />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        ''
      )}
      {type === 'date' ? (
        <DateRange
          editableDateInputs={true}
          onChange={(item) => {
            setDateRange([item.selection]);
          }}
          moveRangeOnFirstSelection={false}
          retainEndDateOnFirstSelection={true}
          ranges={dateRange}
          maxDate={new Date()}
        />
      ) : (
        ''
      )}
      {type === 'select' ? (
        <>
          <Select
            onChange={(e) => {
              setValues({ ...values, [field]: e.target.value });
              setAnchor(null);
            }}
            value={values[field]}
            style={{ width: '250px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title='Clear Input'>
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        setValues({ ...values, [field]: '' });
                        setAnchor(null);
                      }}
                    >
                      <CloseIcon fontSize='small' styles={{ color: 'grey', cursor: 'pointer' }} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          >
            {/* <div style={{ maxHeight: '250px', overflow: 'auto' }}> */}
            {options?.map((client) => (
              <MenuItem value={client.value} key={client.value}>
                {client.label}
              </MenuItem>
            ))}
            {/* </div> */}
          </Select>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              setValues({ ...values, [field]: '' });
              setAnchor(null);
            }}
          >
            <CloseIcon fontSize='small' styles={{ color: 'grey', cursor: 'pointer' }} />
          </IconButton>
        </>
      ) : (
        ''
      )}
    </div>
  );
};
export default FilterComponent;
