import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Tooltip,
} from '@mui/material';
import TableLoader from './TableLoader';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const CommonTable = ({
  columns,
  data,
  rowsPerPageOptions,
  loader,
  toSort,
  handleSort,
  page,
  setPage,
  isClickable = false,
  rowsPerPage = rowsPerPageOptions[0] ? rowsPerPageOptions[0] : 10,
  setRowsPerPage = () => {},
  onRowClick = () => {},
}) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    if (!slicedData?.length && page > 0) setPage(page - 1);
  }, [slicedData, page]);

  return (
    <div>
      <div style={{ width: '100%', overflow: 'scroll' }}>
        <Table style={{ width: '100%', overflow: 'scroll' }}>
          <TableHead>
            <TableRow>
              {toSort && <TableCell key={1111}>{'Sort'}</TableCell>}
              {columns.map((column) => {
                if (column?.render) {
                  return (
                    <TableCell key={column.id} style={{ padding: '12px' }}>
                      {column['render'](column)}
                    </TableCell>
                  );
                } else {
                  return <TableCell key={column.id}>{column.label}</TableCell>;
                }
              })}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleSort}>
            <Droppable droppableId='test'>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {loader ? (
                    <TableRow>
                      <TableCell colSpan={columns?.length}>
                        {' '}
                        <TableLoader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    slicedData.map((row, index) => (
                      <Draggable draggableId={row.element_id} index={index}>
                        {(provided, snapshot) => (
                          <TableRow
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={isClickable ? 'cursor-pointer open-link' : ''}
                            onDoubleClick={() => {
                              onRowClick(row);
                            }}
                          >
                            {toSort && (
                              <TableCell key={1111}>
                                <div
                                  {...provided.dragHandleProps}
                                  className='drag-icon'
                                  style={{ cursor: 'grab' }}
                                  // key={index}
                                >
                                  <DragIndicatorIcon fontSize='small' />
                                </div>
                              </TableCell>
                            )}
                            {columns.map((column) => {
                              if (column.type === 'jsx') {
                                // If the column is jsx type, execute the  function and render its JSX
                                return <TableCell key={column.id}>{row[column.id](row)}</TableCell>;
                              }
                              return (
                                <TableCell key={column.id}>
                                  {row[column.id] ? row[column.id] : '-'}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        )}
                      </Draggable>
                    ))
                  )}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </div>
      <div>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className='pagination-container'
        />
      </div>
    </div>
  );
};

export default CommonTable;
