import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, { useState } from 'react';
import ReactCodeInput from 'react-code-input';
import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { maskEmail } from '../../../utils/helpers';

const LogincodeModal = ({
  open,
  onSubmit,
  loading,
  setOtpCountdown,
  bothAvail,
  otpCountDown,
  sendOtp,
}) => {
  const { code } = useParams();
  const [pinCode, setPinCode] = useState('');
  const [isSendBtnClicked, setIsSendBtnClicked] = useState(false);
  const [otpMode, setOtpMode] = useState();
  const data = useSelector((state) => state?.authSlice);

  const handleSubmit = () => {
    onSubmit({
      uuid: code,
      email: data[code]?.email,
      verification_code: pinCode,
      temp_photo_match: false,
    });
  };

  const onChangeCodeInput = (code) => {
    setPinCode(code);
  };

  const onClickResend = async () => {
    // await resendCodeProcess();
    await sendOtp(otpMode ? otpMode : 'email', true);
    setIsSendBtnClicked(false);
  };

  const handleCountdownComplete = () => {
    setOtpCountdown(false);
  };

  const inputStyleInvalid = {
    border: '1px solid red',
    margin: '4px',
    boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px 0px',
  };

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className='modal-wrapper'>
            <Box variant='div' component='div' className='title'>
              <h3 className='fw-700'>Personal token code</h3>
              {bothAvail ? (
                <div>
                  <div className='otp-mode-radio d-flex flex-raw align-items-center mt-8'>
                    <span className='f-14 fw-550'>Select where to receive code:</span>
                    <RadioGroup
                      className='ml-16'
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      row
                      value={otpMode}
                      onChange={(e) => setOtpMode(e.target.value)}
                    >
                      <FormControlLabel
                        value='email'
                        control={<Radio />}
                        label={maskEmail(data?.[code]?.email)}
                      />
                      <FormControlLabel
                        value='phone'
                        control={<Radio />}
                        label={`xxx-xxx-${data?.[code]?.last_four_mobile}`}
                      />
                    </RadioGroup>
                  </div>
                  <Button
                    disabled={!otpMode}
                    onClick={() => {
                      sendOtp(otpMode, false);
                    }}
                    variant='contained'
                    className='mt-8'
                    style={{ height: '30px' }}
                  >
                    Get Code
                  </Button>
                </div>
              ) : (
                <p style={{ width: '500px' }}>
                  Please enter the 6 digit security code we have sent to your{' '}
                  {otpMode
                    ? otpMode === 'phone'
                      ? `phone number xxx-xxx-${data?.[code]?.last_four_mobile}`
                      : `email ${maskEmail(data?.[code]?.email)}`
                    : data?.[code]?.last_four_mobile &&
                      data?.[code]?.last_four_mobile !== 'NOT PROVIDED'
                    ? `phone number xxx-xxx-${data?.[code]?.last_four_mobile}`
                    : data?.[code]?.email
                    ? `email ${maskEmail(data?.[code]?.email)}`
                    : 'email'}
                  .
                </p>
              )}
            </Box>
            <Box variant='div' component='div' className='body v-code-modal'>
              <Grid container spacing={1} mt={1}>
                <Grid item xs={12}>
                  <Box variant='div' component='div'>
                    <Box variant='div' component='div' className='verification-code-wrap'>
                      <ReactCodeInput
                        name='token-code-input'
                        // inputMode='numeric'
                        type='text'
                        fields={6}
                        onChange={onChangeCodeInput}
                        required
                        isValid={true}
                        inputStyleInvalid={inputStyleInvalid}
                        disabled={bothAvail}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box variant='div' component='div' className='footer'>
              <Box variant='div' component='div' className='btn-group'>
                <Button
                  variant='contained'
                  size='large'
                  className='primary-btn btn'
                  disableRipple
                  onClick={handleSubmit}
                  disabled={loading || bothAvail}
                  endIcon={loading && <CircularProgress size={20} />}
                >
                  {' '}
                  Submit
                </Button>
              </Box>
              <Box variant='div' component='div' className='mt-8'>
                {!isSendBtnClicked ? (
                  <div className='f-14'>
                    Did not receive the code?
                    {!otpCountDown ? (
                      <Button
                        variant='text'
                        size=''
                        className=''
                        disableRipple
                        onClick={() => {
                          setIsSendBtnClicked(true);
                        }}
                        disabled={loading || bothAvail}
                      >
                        {' '}
                        Get a New Code
                      </Button>
                    ) : (
                      <>
                        <Countdown
                          date={Date.now() + 60000} // 1 minute in milliseconds
                          onComplete={handleCountdownComplete}
                          renderer={({ seconds }) => (
                            <span className='fw-450 color-p f-14' disabled>
                              {' '}
                              GET A NEW CODE IN {seconds}
                            </span>
                          )}
                        />
                        <div className='color-s mt-8 f-13-5 fw-500'>
                          The code has been sent to your{' '}
                          {otpMode
                            ? otpMode === 'phone'
                              ? `phone number xxx-xxx-${data?.[code]?.last_four_mobile}`
                              : `email ${maskEmail(data?.[code]?.email)}`
                            : data?.[code]?.last_four_mobile &&
                              data?.[code]?.last_four_mobile !== 'NOT PROVIDED'
                            ? `phone`
                            : 'email'}{' '}
                          and will expire in 1 hour.{' '}
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  ''
                )}
                {isSendBtnClicked && (
                  <div className='f-14'>
                    New code will be sent to{' '}
                    {otpMode
                      ? otpMode === 'phone'
                        ? `phone number xxx-xxx-${data?.[code]?.last_four_mobile}`
                        : `email ${maskEmail(data?.[code]?.email)}`
                      : data?.[code]?.last_four_mobile &&
                        data?.[code]?.last_four_mobile !== 'NOT PROVIDED'
                      ? `phone number xxx-xxx-${data?.[code]?.last_four_mobile}`
                      : data?.[code]?.email
                      ? `email ${maskEmail(data?.[code]?.email)}`
                      : 'email'}
                    <Button
                      variant='text'
                      size=''
                      className='p-0'
                      style={{ minWidth: '50px' }}
                      disableRipple
                      onClick={() => {
                        onClickResend();
                      }}
                      disabled={loading}
                    >
                      Send
                    </Button>
                    <Button
                      variant='text'
                      size=''
                      className='p-0'
                      style={{ minWidth: '50px' }}
                      disableRipple
                      onClick={() => {
                        setIsSendBtnClicked(false);
                      }}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default LogincodeModal;
