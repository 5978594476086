import { EmailOutlined } from '@mui/icons-material';
import { generateAvatarText } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';

const ProfileWidget = () => {
  const { admin } = useSelector((state) => state?.authSlice);

  return (
    <div className='user-profile-widget'>
      <Avatar sx={{ width: '60px', height: '60px' }} className='my-8 cursor-pointer bg-p'>
        {generateAvatarText(
          admin?.first_name
            ? admin?.first_name?.split(' ')?.[0] + ' ' + admin?.last_name?.split(' ')?.[0]
            : admin?.client_name
        )}
      </Avatar>
      <h4 className='text-center'>
        {admin?.first_name ? admin?.first_name + ' ' + admin?.last_name : admin?.client_name}
      </h4>
      <div className='d-flex align-items-center justify-center'>
        {' '}
        <EmailOutlined /> <span className='px-4'>{admin?.email}</span>
      </div>
    </div>
  );
};

export default ProfileWidget;
