import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  TextField,
  Button,
  Grid,
  CardContent,
  Box,
  Typography,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loader';
import { useDispatch } from 'react-redux';
import { adminChangePassword, orderChangePassword } from '../../services/auth/auth';

const ChangePassword = () => {
  const { state, pathname } = useLocation();
  const { admin } = useSelector((state) => state?.authSlice);
  const { order } = useSelector((state) => state?.authSlice);
  const detail = pathname?.split('/')?.[1] === 'admin' ? admin : order;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const schema = yup.object().shape({
    old_password: yup.string().required('Old password is required'),
    new_password: yup
      .string()
      .required('New password is required')
      .min(8, 'New password must be at least 8 characters long'),
    new_password_confirmation: yup
      .string()
      .required('Password confirmation is required')
      .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const res =
      pathname?.split('/')?.[1] === 'admin'
        ? await adminChangePassword(data, detail?.token)
        : await orderChangePassword(data, detail?.token);
    if (res?.success) {
      toast.success(res?.message);
      navigate(pathname?.split('/')?.[1] === 'admin' ? '/admin' : '/orders');
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center'>
                <Typography variant='h4' className='mb-0'>
                  Change Password
                </Typography>
              </Grid>
            </CardContent>
            <Box sx={{ mt: 2 }} className='client-user-form'>
              <CardContent>
                <form onSubmit={handleSubmit(onSubmit)} className='user-form'>
                  <fieldset
                    disabled={state?.disabled ? true : false}
                    className='m-0 p-0 border-none'
                  >
                    <Grid container spacing={window.innerWidth < 768 ? 1 : 2}>
                      <Grid item xs={6}>
                        <label>Old Password</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='old_password'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type={togglePassword ? 'text' : 'password'}
                              placeholder='Enter Old Password'
                              variant='outlined'
                              fullWidth
                              error={!!errors.old_password}
                              helperText={errors.old_password?.message}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    {togglePassword ? (
                                      <Visibility
                                        fontSize={'small'}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setTogglePassword((toggle) => !toggle)}
                                      />
                                    ) : (
                                      <VisibilityOff
                                        fontSize={'small'}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setTogglePassword((toggle) => !toggle)}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>New Password</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='new_password'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter New Password'
                              type={newPassword ? 'text' : 'password'}
                              variant='outlined'
                              fullWidth
                              error={!!errors.new_password}
                              helperText={errors.new_password?.message}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    {newPassword ? (
                                      <Visibility
                                        fontSize={'small'}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setNewPassword((toggle) => !toggle)}
                                      />
                                    ) : (
                                      <VisibilityOff
                                        fontSize={'small'}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setNewPassword((toggle) => !toggle)}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>Confirm Password</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='new_password_confirmation'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter Confirm Password'
                              variant='outlined'
                              type={confirmPassword ? 'text' : 'password'}
                              fullWidth
                              error={!!errors.new_password_confirmation}
                              helperText={errors.new_password_confirmation?.message}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    {confirmPassword ? (
                                      <Visibility
                                        fontSize={'small'}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setConfirmPassword((toggle) => !toggle)}
                                      />
                                    ) : (
                                      <VisibilityOff
                                        fontSize={'small'}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setConfirmPassword((toggle) => !toggle)}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div className='d-flex justify-end mt-8'>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => {
                              reset();
                              navigate(
                                pathname?.split('/')?.[1] === 'admin' ? '/admin' : '/orders'
                              );
                            }}
                            className='mr-8'
                          >
                            Cancel
                          </Button>
                          <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={loading}
                          >
                            Change Password
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </fieldset>
                </form>
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ChangePassword;
