import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../common/Loader';
import { LIEN_SECTION_ID, UTILITY_SECTION_ID } from '../../../utils/constants';
import lien from '../../../utils/lien.json';
import utility from '../../../utils/utility.json';
import { cloneDeep } from 'lodash';
import { randomUUID } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const CreateTemplateModal = ({ open, setOpen, label, section_id, onSubmit }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const handleSave = async (data) => {
    let fields = {};
    if (section_id === LIEN_SECTION_ID) {
      fields = cloneDeep(lien);
    } else if (section_id === UTILITY_SECTION_ID) {
      fields = cloneDeep(utility);
    }
    fields.element_id = randomUUID();
    fields.element_name = 'participant_field_' + Math.floor(10000 + Math.random() * 90000);
    fields.user_value = 'Yes';
    fields.label = data?.service;
    fields.by_participant = 'Y';
    fields?.options?.forEach((option) => {
      option.option_id = randomUUID();
      if (option?.option_name === 'No') {
        return;
      }
      option?.elements?.forEach((element) => {
        element.by_participant = 'Y';
        element.element_id = randomUUID();
        element.element_name = 'participant_field_' + Math.floor(10000 + Math.random() * 90000);
      });
    });
    onSubmit(fields);
    reset();
  };

  return (
    <div>
      {loading && <Loader />}
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          className='customized-dialog-title'
          style={{ fontSize: '16px' }}
        >
          {label}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <div className='f-12 color-g mt-4 mb-8'>{t('noteAdditionalService')}</div>
          <Box component='form' onSubmit={handleSubmit(handleSave)} noValidate>
            <label htmlFor='service' className='f-13-5 fw-700'>
              {t('serviceLabel')}
            </label>
            <label style={{ color: 'red' }}>*</label>
            <TextField
              fullWidth
              name='service'
              style={{ marginTop: '4px', height: '35px', marginBottom: '8px' }}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              {...register('service', { required: true })}
            />
            {errors.service && <span className='error f-13 mt-4'>{t('serviceLabelRequired')}</span>}

            <DialogActions className='mt-8'>
              <Button
                autoFocus
                onClick={handleClose}
                className='p-2'
                color='secondary'
                style={{ color: 'grey' }}
              >
                Close
              </Button>
              <Button
                autoFocus
                type='submit'
                variant='contained'
                color='primary'
                className='p-2 btn-participentType'
              >
                Save
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
export default CreateTemplateModal;
