import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  TextField,
  MenuItem,
  Select,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { addParticipantType } from '../../../services/admin/participantType';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { addTemplate } from '../../../services/admin/templates';
import Loader from '../../common/Loader';
import { getOrderTypes } from '../../../services/order/order';
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../../../utils/constants';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: '500px',
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const CreateTemplateModal = ({ open, setOpen, fetchTemplates, offices, isSameQuestions }) => {
  const { admin } = useSelector((state) => state?.authSlice);
  const isAdmin =
    admin?.user_role_name === SUPER_ADMIN_ROLE || admin?.user_role_name === ADMIN_ROLE
      ? true
      : false;
  const [loading, setLoading] = useState(false);
  const [orderTypes, setOrderTypes] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState(offices?.[0]?.client_office_id);

  useEffect(() => {
    fetchOrderTypes();
  }, [open, selectedOffice]);

  useEffect(() => {
    if (!isAdmin) setSelectedOffice(offices?.[0]?.client_office_id);
  }, [open, offices]);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const handleSave = async (data) => {
    setLoading(true);
    let payload = { ...data };
    if (payload.client_office_id) {
      if (!isSameQuestions && !isAdmin) {
        payload.client_office_id = +payload.client_office_id;
      } else {
        delete payload.client_office_id;
      }
    }

    if (admin.client_id) payload.client_id = admin.client_id;
    const resData = await addParticipantType(payload, admin?.token);
    if (resData?.success) {
      let templateData = {
        user_participant_type_id: resData?.response?.user_participant_type_id,
        template_json: {
          question: {
            sections: [],
          },
        },
      };
      if (payload?.client_id) templateData.client_id = payload?.client_id;
      if (payload?.client_office_id) templateData.client_office_id = payload?.client_office_id;
      const result = await addTemplate(templateData, admin?.token);
      if (result?.success) {
        reset();
        toast.success(resData?.message);
        handleClose();
        navigate('/admin/templates');
        fetchTemplates();
      } else {
        toast.error(result?.error || result?.message || 'Something went wrong!');
      }
    } else {
      toast.error(resData?.error || resData?.message || 'Something went wrong!');
    }
    setLoading(false);
  };

  const fetchOrderTypes = async () => {
    setLoading(true);
    const res = await getOrderTypes(admin?.token, admin?.client_id || '', selectedOffice || '');
    if (res?.success) {
      setOrderTypes(res?.response || []);
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && <Loader />}
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          className='customized-dialog-title'
          style={{ fontSize: '16px' }}
        >
          Add Participant Type
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Box
            component='form'
            onSubmit={handleSubmit(handleSave)}
            noValidate
            className='client-user-form order-form'
          >
            {!isSameQuestions && !isAdmin ? (
              <>
                <label htmlFor='participantType' className='f-13 mt-16'>
                  Office
                </label>
                <label style={{ color: 'red' }}>*</label>
                <Select
                  fullWidth
                  name='client_office_id'
                  style={{
                    marginTop: '4px',
                    marginBottom: errors.client_office_id ? '0px' : '10px',
                  }}
                  defaultValue={selectedOffice}
                  {...register('client_office_id', {})}
                  onChange={(e) => {
                    trigger('client_office_id');
                    setSelectedOffice(e.target.value);
                  }}
                >
                  {offices?.map((item) => {
                    return <MenuItem value={item?.client_office_id}>{item?.office_name}</MenuItem>;
                  })}
                </Select>
                {errors.client_office_id && (
                  <span className='error' style={{ fontSize: '13px' }}>
                    Office is required
                  </span>
                )}
              </>
            ) : (
              ''
            )}

            <label htmlFor='participantType' className='f-13 mt-16'>
              Participant Type{' '}
            </label>
            <label style={{ color: 'red' }}>*</label>
            <TextField
              fullWidth
              name='participantType'
              style={{ marginTop: '4px', marginBottom: errors.name ? '0px' : '10px' }}
              {...register('name', { required: true })}
            />
            {errors.name && (
              <span className='error' style={{ fontSize: '13px', width: '100%', display: 'block' }}>
                Participant type is required
              </span>
            )}
            <label htmlFor='participantType' className='f-13 mt-16'>
              Order Type{' '}
            </label>
            <label style={{ color: 'red' }}>*</label>
            <Select
              fullWidth
              name='order_type_id'
              style={{ marginTop: '4px', lineHeight: '0.4375' }}
              {...register('order_type_id', { required: true })}
            >
              {orderTypes?.map((item) => {
                return <MenuItem value={item?.id}>{item?.name}</MenuItem>;
              })}
            </Select>
            {errors.order_type_id && (
              <span className='error' style={{ fontSize: '13px' }}>
                Order type is required
              </span>
            )}

            <DialogActions className='mt-8'>
              <Button
                autoFocus
                onClick={handleClose}
                className='p-2'
                color='secondary'
                style={{ color: 'grey' }}
              >
                Close
              </Button>
              <Button
                autoFocus
                type='submit'
                variant='contained'
                color='primary'
                className='p-2 btn-participentType'
              >
                Save
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
export default CreateTemplateModal;
