import { authorizedApi } from '../../utils/api';

export const getClients = (token, currPage = 1, per_page = 10) => {
  return authorizedApi(token).get(
    `/admin/clients?page=${currPage}&per_page=${per_page}&is_listing=yes`
  );
};

export const getClient = (id, token) => {
  return authorizedApi(token).get(`/admin/clients/${id}`);
};

export const addClient = (data, token, config) => {
  return authorizedApi(token).post(`/admin/clients`, data, config);
};

export const addClientOffices = (data, token, config) => {
  return authorizedApi(token).post(`/admin/clients/add/office`, data, config);
};

export const updateClientOffice = (id, data, token, config) => {
  return authorizedApi(token).post(`/admin/clients/update/office/${id}`, data, config);
};

export const updateClient = (id, data, token, config) => {
  return authorizedApi(token).post(`/admin/clients/${id}`, data, config);
};
export const updateOfficeStatus = (data, token) => {
  return authorizedApi(token).post(`/admin/clients/change/office/status`, data);
};

export const deleteClient = (id, token) => {
  return authorizedApi(token).delete(`/admin/clients/${id}`);
};
