import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import Loader from '../../common/Loader';
import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': { maxWidth: 'unset' },
  '& .MuiDialogContent-root': {
    width: '1200px',
    height: '90vh',
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const MobyUploadModal = ({ open, setOpen, url, code }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  // below logic is to close out popup from iframe
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === `closeModal-${code}`) {
        setOpen(false);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div>
      {isLoading && <Loader />}
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          className='customized-dialog-title'
          style={{ fontSize: '16px' }}
        >
          {t('uploadIdModalHeader')}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers style={{ overflowX: 'hidden' }}>
          <iframe
            title='Upload Government ID'
            sandbox='allow-scripts allow-same-origin allow-popups allow-modals allow-forms allow-downloads allow-top-navigation allow-popups-to-escape-sandbox'
            src={url}
            loading='lazy'
            onLoadStart={() => {
              setIsLoading(true);
            }}
            onLoad={() => {
              setIsLoading(false);
            }}
            className='iframe-moby'
          />
        </DialogContent>
        <DialogActions className='mt-8'>
          <Button
            autoFocus
            onClick={handleClose}
            className='p-2'
            color='secondary'
            style={{ color: 'grey' }}
          >
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default MobyUploadModal;
