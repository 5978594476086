import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  TextField,
  Button,
  Grid,
  CardContent,
  Box,
  Typography,
  MenuItem,
  Select,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loader';
import { login } from '../../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import { getUserProfile, updateUserProfile } from '../../services/auth/userProfile';
import { getTimeZones } from '../../services/admin/users';

const UserProfile = () => {
  const { pathname } = useLocation();
  const { admin } = useSelector((state) => state?.authSlice);
  const { order } = useSelector((state) => state?.authSlice);
  const detail = pathname?.split('/')?.[1] === 'admin' ? admin : order;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [timeZones, setTimeZones] = useState([]);

  useEffect(() => {
    fetchUser();
    fetchTimeZones();
  }, []);

  const schema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    timezone: yup.string().required('Timezone is required'),
  });

  const isAdminPath = pathname?.split('/')?.[1] === 'admin';
  // let updatedSchema = baseSchema;

  // if (isAdminPath || detail?.user_role_name === 'Admin' || detail?.user_id) {
  //   updatedSchema = updatedSchema.shape({
  //     first_name: yup.string().required('First name is required'),
  //     last_name: yup.string().required('Last name is required'),
  //   });
  // }
  // else if (!detail?.user_id && detail?.user_role_name !== 'Admin') {
  //   updatedSchema = updatedSchema.shape({
  //     comp_name: yup.string().required('Company Name is required'),
  //     address_1: yup.string().required('Address 1 is required'),
  //     city: yup.string().required('City is required'),
  //     state: yup.string().required('State is required'),
  //     zip_code: yup.string().required('ZIP Code is required'),
  //   });
  // }

  // const schema = updatedSchema;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchTimeZones = async () => {
    setLoading(true);
    const res = await getTimeZones(detail?.token);
    if (Object.keys(res || {})?.length) {
      setTimeZones(Object.keys(res || {})?.map((tz) => res[tz]) || []);
    }
    setLoading(false);
  };

  const fetchUser = async () => {
    setLoading(true);
    reset();
    const res = await getUserProfile(detail?.token);
    if (res.success) {
      reset({ ...res.response, timezone: res.response.user_timezone });
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await updateUserProfile({ ...data, phone_ext: '' }, detail?.token);
    if (res?.success) {
      dispatch(
        login({
          data: { ...res?.response, token: detail?.token },
          code: isAdminPath ? 'admin' : 'order',
        })
      );
      fetchUser();
      toast.success(res?.message);
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center'>
                <Typography variant='h4' className='mb-0'>
                  {'Edit Profile'}
                </Typography>
              </Grid>
            </CardContent>
            <Box sx={{ mt: 2 }} className='client-user-form'>
              <CardContent>
                <form onSubmit={handleSubmit(onSubmit)} className='user-form'>
                  <fieldset className='m-0 p-0 border-none'>
                    <Grid container spacing={window.innerWidth < 768 ? 1 : 2}>
                      {/* {!detail?.user_id && detail?.user_role_name !== 'Admin' && (
                        <Grid item xs={12} sm={6}>
                          <label>Client Name</label>
                          <label className='required'>{'*'}</label>
                          <Controller
                            name='client_name'
                            control={control}
                            defaultValue=''
                            render={({ field }) => (
                              <TextField
                                {...field}
                                placeholder='Enter client name'
                                variant='outlined'
                                fullWidth
                                error={!!errors.client_name}
                                helperText={errors.client_name?.message}
                              />
                            )}
                          />
                        </Grid>
                      )} */}

                      {detail?.user_id && (
                        <>
                          <Grid item xs={6}>
                            <label>First Name</label>
                            <label className='required'>{'*'}</label>
                            <Controller
                              name='first_name'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder='Enter first name'
                                  variant='outlined'
                                  fullWidth
                                  error={!!errors.first_name}
                                  helperText={errors.first_name?.message}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <label>Last name</label>
                            <label className='required'>{'*'}</label>
                            <Controller
                              name='last_name'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder='Enter last name'
                                  variant='outlined'
                                  fullWidth
                                  error={!!errors.last_name}
                                  helperText={errors.last_name?.message}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={6}>
                        <label>Email</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='email'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter email'
                              variant='outlined'
                              fullWidth
                              error={!!errors.email}
                              helperText={errors.email?.message}
                              autoComplete='off'
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>Timezone</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='timezone'
                          control={control}
                          defaultValue=''
                          render={({ field, fieldState }) => (
                            <>
                              <Select fullWidth error={!!errors.timezone} {...field}>
                                {timeZones?.map((tz) => (
                                  <MenuItem value={tz} key={tz}>
                                    {tz}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Typography className='order-error f-12' color='error'>
                                {fieldState.error ? fieldState.error.message : ''}
                              </Typography>
                            </>
                          )}
                        />
                      </Grid>
                      {/* {detail?.user_id && detail?.user_role_name !== 'Admin' && (
                        <Grid item xs={6}>
                          <label>Client</label>
                          <label className='required'>{'*'}</label>
                          <FormControl fullWidth variant='outlined' error={!!errors.client_id}>
                            <Select
                              onChange={(e) => {
                                setClient(e.target.value);
                              }}
                              value={client}
                              disabled={true}
                            >
                              {clientList?.map((client) => (
                                <MenuItem value={client.id} key={client.id}>
                                  {client.client_name}
                                </MenuItem>
                              ))}
                              {clientList?.length === 0 && (
                                <MenuItem value={''}>Client Not Found</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      )} */}
                      {/* {!detail?.user_id && detail?.user_role_name !== 'Admin' && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <label>Phone No </label>
                            <label className='required'>{'*'}</label>
                            <Controller
                              name='phone'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <InputMask mask={'+1 (999) 999-9999'} {...field}>
                                  {() => (
                                    <TextField
                                      {...field}
                                      name='phone'
                                      placeholder='Enter phone'
                                      variant='outlined'
                                      fullWidth
                                      error={!!errors.phone}
                                      helperText={errors.phone?.message}
                                    />
                                  )}
                                </InputMask>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <label>Fax </label>
                            <Controller
                              name='fax'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  name='fax'
                                  placeholder='Enter Fax'
                                  variant='outlined'
                                  fullWidth
                                  error={!!errors.fax}
                                  helperText={errors.fax?.message}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <label>Company Name</label>
                            <label className='required'>{'*'}</label>
                            <Controller
                              name='comp_name'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder='Enter company name'
                                  variant='outlined'
                                  fullWidth
                                  error={!!errors.comp_name}
                                  helperText={errors.comp_name?.message}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <label>Address</label>
                            <label className='required'>{'*'}</label>
                            <Controller
                              name='address_1'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder='Enter address'
                                  variant='outlined'
                                  fullWidth
                                  error={!!errors.address_1}
                                  helperText={errors.address_1?.message}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <label>Address 2</label>
                            <Controller
                              name='address_2'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder='Enter address 2'
                                  variant='outlined'
                                  fullWidth
                                  error={!!errors.address_2}
                                  helperText={errors.address_2?.message}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <label>City</label>
                            <label className='required'>{'*'}</label>
                            <Controller
                              name='city'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder='Enter city'
                                  variant='outlined'
                                  fullWidth
                                  error={!!errors.city}
                                  helperText={errors.city?.message}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <label>State</label>
                            <label className='required'>{'*'}</label>
                            <Controller
                              name='state'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder='Enter state'
                                  variant='outlined'
                                  fullWidth
                                  error={!!errors.state}
                                  helperText={errors.state?.message}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <label>Zip</label>
                            <label className='required'>{'*'}</label>
                            <Controller
                              name='zip_code'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder='Enter ZIP Code'
                                  variant='outlined'
                                  fullWidth
                                  error={!!errors.zip_code}
                                  helperText={errors.zip_code?.message}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )} */}
                      <Grid item xs={12}>
                        <div className='d-flex justify-end mt-8'>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => {
                              reset();
                              navigate(
                                pathname?.split('/')?.[1] === 'admin' ? '/admin' : '/orders'
                              );
                            }}
                            className='mr-8'
                          >
                            Cancel
                          </Button>
                          <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={loading ? true : false}
                          >
                            Save
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </fieldset>
                </form>
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default UserProfile;
