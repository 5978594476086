import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs({ open, setOpen, haveDocError, handleSave }) {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          style={{ fontSize: '16px' }}
          className='font-family-main'
        >
          {t('dReview')}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Typography
            gutterBottom
            className='d-flex align-center ml-2 font-family-main fw-550'
            style={{ fontSize: '13px' }}
          >
            {haveDocError && <ErrorOutlineIcon color='error' fontSize='small' className='mr-8' />}
            {haveDocError ? t('dReviewMessage1') : t('dReviewMessage2')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            className='p-2'
            color='secondary'
            style={{ color: 'grey' }}
          >
            Close
          </Button>
          {!haveDocError ? (
            <Button
              autoFocus
              onClick={handleSave}
              style={{ padding: '2px 8px' }}
              variant='contained'
            >
              Continue
            </Button>
          ) : (
            ''
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
