import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, CardContent, Grid, Typography } from '@mui/material';
import Table from '../../../components/common/Table';
import { Edit, Visibility, PersonAdd, Delete } from '@mui/icons-material';
import { getAdmins, deleteAdmin } from '../../../services/admin/adminUsers';
import DeleteModal from '../../../components/common/DeleteModal';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import ActionMenu from '../../../components/common/ActionMenu';

const Users = () => {
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state?.authSlice);
  const [loading, setLoading] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [perPageRecords, setPerPageRecords] = useState(100);
  const [id, setId] = useState('');
  const [total, setTotal] = useState(0);
  const [currPage, setCurrPage] = useState(0);

  const tableData = useMemo(() => {
    if (adminList?.length) {
      return adminList?.map((user) => {
        return {
          ...user,
          name: (props) => <div>{props.first_name + ' ' + props.last_name}</div>,
          actions: (props) => (
            <div>
              <div>
                <ActionMenu
                  actions={[
                    {
                      label: 'Edit Admin',
                      logo: <Edit className='action-menu-item' />,
                      onClick: () => navigate(`/admin/list/${props.id}`),
                    },
                    {
                      label: 'View Admin',
                      logo: <Visibility className='action-menu-item' />,
                      onClick: () =>
                        navigate(`/admin/list/${props.id}/view`, { state: { disabled: true } }),
                    },
                    {
                      label: 'Delete Admin',
                      logo: <Delete className='action-menu-item' color='error' />,
                      onClick: () => {
                        setId(props?.id);
                        setShowDeleteModal(true);
                      },
                    },
                  ]}
                />
              </div>
            </div>
          ),
          user_status: () => (
            <div
              className={`${
                user?.status !== 'inactive' ? 'color-p text-capitalize' : 'error text-capitalize'
              }`}
            >
              {user?.status ?? ''}
            </div>
          ),
        };
      });
    }
    return [];
  }, [adminList]);

  useEffect(() => {
    fetchAdminList();
  }, [currPage, perPageRecords]);

  const fetchAdminList = async () => {
    setLoading(true);
    const res = await getAdmins(admin?.token, currPage + 1, perPageRecords);
    if (res?.success) {
      setAdminList(res?.response?.data || []);
      setTotal(res?.response?.meta?.total);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };
  const columns = [
    { id: 'actions', label: 'Actions', type: 'jsx' },
    { id: 'name', label: 'Name', type: 'jsx' },
    { id: 'email', label: 'Email' },
    { id: 'user_status', label: 'Status', type: 'jsx' },
  ];

  const deleteHandler = async () => {
    setLoading(true);
    const res = await deleteAdmin(id, admin?.token);
    if (res.success) {
      fetchAdminList();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    setShowDeleteModal(false);
    setLoading(false);
  };

  const onRowClick = (row) => {
    navigate(`/admin/list/${row.id}`);
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center'>
                <Typography variant='h4' className='mb-0'>
                  Admins
                </Typography>
                <Button
                  autoFocus
                  onClick={() => {
                    navigate('/admin/list/add');
                  }}
                  variant='contained'
                  color='primary'
                  style={{ padding: '4px 12px' }}
                >
                  <PersonAdd fontSize='small' className='mr-8' /> ADD Admin
                </Button>
              </Grid>
            </CardContent>
            <Box>
              <CardContent>
                <Table
                  loader={loading}
                  columns={columns}
                  data={tableData}
                  count={total}
                  current_page={currPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                  isClickable={true}
                  onRowClick={onRowClick}
                />
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        item={'Admin'}
        submitFunction={deleteHandler}
      />
    </Box>
  );
};
export default Users;
