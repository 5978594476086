import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import theme from './utils/theme';
import { ThemeProvider } from '@mui/material/styles';
import './styles/main.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector, useDispatch } from 'react-redux';
import { getConfigurations } from './services/auth/auth';
import { configuration } from './store/slices/authSlice';
import { useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const App = () => {
  const url = window.location.origin;
  const dispatch = useDispatch();
  const config = useSelector((state) => state.authSlice)?.configuration?.[url];

  useEffect(() => {
    getConfigurationDetails();
  }, []);

  const getConfigurationDetails = async () => {
    let body = { domain: url, office_name: config?.office_name?.replace(/ /g, '') };
    const res = await getConfigurations(body);
    if (res.success) {
      dispatch(
        configuration({
          url,
          data: {
            ...res?.response?.[0],
          },
        })
      );
    } else {
      dispatch(
        configuration({
          url,
          data: {},
        })
      );
    }
  };

  return (
    <ThemeProvider theme={theme(config?.branding_json || {})}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <ToastContainer
        style={{
          width: window.innerWidth < 500 ? '300px' : '350px',
          fontSize: '15px',
          fontWeight: 500,
          marginLeft: window.innerWidth < 500 ? 'calc(50% - 150px)' : '',
          marginBottom: window.innerWidth < 500 ? '8px' : '',
        }}
        position='bottom-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
      />
    </ThemeProvider>
  );
};

export default App;
