import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Grid, CardContent, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { getUser } from '../../../services/admin/users';
import { CLIENT_ADMIN_ROLE } from '../../../utils/constants';

const ViewUser = () => {
  const { admin } = useSelector((state) => state?.authSlice);
  const isClientAdmin = admin?.user_role_name === CLIENT_ADMIN_ROLE ? true : false;

  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    if (id) fetchUser();
  }, [id]);

  const fetchUser = async () => {
    setLoading(true);
    const res = await getUser(id, admin?.token);
    if (res.success) {
      setUserDetails(res?.response);
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <Grid item md={12} className='d-flex justify-between align-center sub-header'>
              <Typography variant='h4' className='mb-0'>
                {'View User'}
              </Typography>
            </Grid>
            <Box sx={{ mt: 2 }} className='client-user-form'>
              <CardContent>
                <form className='view-client'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <label>First Name</label>
                      <div className='f-13 mt-12 fw-500 color-g'>
                        {userDetails?.first_name ?? '-'}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label>Last Name</label>
                      <div className='f-13 mt-12 fw-500 color-g'>
                        {userDetails?.last_name ?? '-'}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <label>Email</label>
                      <div className='f-13 mt-12 fw-500 color-g'>{userDetails?.email ?? '-'}</div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <label>Client</label>
                      <div className='f-13 mt-12 fw-500 color-g'>
                        {userDetails?.company_name ?? '-'}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label>Office</label>
                      <div className='f-13 mt-12 fw-500 color-g'>
                        {userDetails?.offices?.length
                          ? userDetails?.offices?.length === 1
                            ? userDetails?.offices[0]?.office_name
                            : 'All Offices'
                          : '-'}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <label>Role</label>
                      <div className='f-13 mt-12 fw-500 color-g'>
                        {userDetails?.user_role_name ?? '-'}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label>Timezone</label>
                      <div className='f-13 mt-12 fw-500 color-g'>
                        {userDetails?.user_timezone ?? '-'}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label>Status</label>
                      <div className='f-13 mt-12 fw-500 color-g'>{userDetails?.status ?? '-'}</div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div className='d-flex justify-end mt-16'>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                          navigate('/admin/users');
                        }}
                        className='mr-8'
                      >
                        Back
                      </Button>
                    </div>
                  </Grid>
                </form>
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ViewUser;
