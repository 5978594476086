import React from 'react';

const MediaField = ({ element, disabled }) => {
  return (
    <>
      <div className='video ml-8'>
        {element.options.map((option, idx) => {
          var mediaSource = option.option_name;
          if (mediaSource.indexOf('http://') === 0 || mediaSource.indexOf('https://') === 0)
            mediaSource += 'controls=0&amp;autoplay=1';
          else mediaSource = `https://${mediaSource}?controls=0&amp;autoplay=1`;
          return (
            <object key={idx}>
              <embed className='media-frame' src={mediaSource} disabled={disabled} />
            </object>
          );
        })}
      </div>
    </>
  );
};
export default MediaField;
