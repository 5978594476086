import { useParams } from 'react-router-dom';
import { Home, ShoppingCart, Translate } from '@mui/icons-material';
import { Box, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { RiBuildingLine, RiFileList3Line, RiPhoneLine } from 'react-icons/ri';
import { languages } from '../../../utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import { addLanguage } from '../../../store/slices/questionSlice';
import { useTranslation } from 'react-i18next';
import { addTranslations, resources } from '../../../i18n';

const SideBar = ({ contents, companyName, translate }) => {
  const { t, i18n } = useTranslation();
  const { code } = useParams();
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code] || 'en';
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  const handleLanguageChange = (e) => {
    if (e.target.value !== 'en') {
      translate(e.target.value);
    } else {
      dispatch(addLanguage({ tx_id: code, language: e.target.value }));
    }
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    if (Object.keys(translations?.[selectedLanguage]?.static || {})) {
      addTranslations(selectedLanguage, translations?.[selectedLanguage]?.static);
    }
  }, [selectedLanguage]);

  const address =
    (contents?.property_unit_no ? contents?.property_unit_no + ', ' : '') +
    (contents?.property_address_1 ? contents?.property_address_1 + ', ' : '') +
    (contents?.property_address_2 ? contents?.property_address_2 + ', ' : '') +
    (contents?.property_city ? contents?.property_city + ', ' : '') +
    (contents?.property_state ? contents?.property_state + ', ' : '') +
    (contents?.property_zipcode ? contents?.property_zipcode : '');

  return (
    <div className='d-flex flex-column'>
      <Box variant='div' component='div' className='file-detail-block'>
        <Box
          variant='div'
          component='div'
          className='detail-group ps-24 pt-16 participant-header-bg'
          style={{ borderRadius: '10px 10px 0px 0px' }}
        >
          <h4 className='f-14 participant-header-color'>
            <Translate fontSize='large' />
            {t('language')}
            {/* Language */}
          </h4>
        </Box>

        <Box variant='div' component='div' className='detail-group ps-24 pb-16'>
          <div className='mt-8 language'>
            <div className='f-12 fw-500 color-g'>
              {t('languageMessage')}
              {/* You can view the participant form in various available languages. */}
            </div>
            <Select
              style={{ width: '100%', marginTop: '8px' }}
              value={selectedLanguage}
              onChange={handleLanguageChange}
            >
              {languages?.map((language) => (
                <MenuItem
                  value={language.value}
                  // className={!resources[language?.value] ? 'disable-language' : ''}
                >
                  {language.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </Box>
      </Box>
      <Box variant='div' component='div' className='file-detail-block mt-8'>
        <Box
          variant='div'
          component='div'
          className='detail-group ps-24 pt-16 participant-header-bg'
          style={{ borderRadius: '10px 10px 0px 0px' }}
        >
          <h4 className='f-14 participant-header-color'>
            <Home fontSize='large' />
            {/* Property Address */}
            {t('propertyAddress')}
          </h4>
        </Box>

        <Box variant='div' component='div' className='detail-group ps-24 pb-16'>
          <p className='f-13-5 fw-500'>{address || ''}</p>
        </Box>
      </Box>
      <Box variant='div' component='div' className='file-detail-block mt-8'>
        <Box
          variant='div'
          component='div'
          className='detail-group ps-24 pt-16 participant-header-bg'
          style={{ borderRadius: '10px 10px 0px 0px' }}
        >
          <h4 className='f-14 participant-header-color'>
            <ShoppingCart fontSize='large' />
            {t('orderDetails')}
            {/* Order Details */}
          </h4>
        </Box>
        <Box variant='div' component='div' className='detail-group ps-24'>
          <h4 className='f-14'>
            {' '}
            <RiFileList3Line />
            {t('fileNumber')}
            {/* File Number */}
          </h4>
          <p className='f-13-5 fw-500'>{contents?.file_number || '-'}</p>
          <hr />
        </Box>

        <Box variant='div' component='div' className='detail-group ps-24'>
          <h4 className='f-14'>
            <RiBuildingLine />
            {t('title')}
            {/* Title / Escrow Company */}
          </h4>
          <p className='f-13-5 fw-500'>{contents?.escrow_company || 'Voxtur Title'}</p>
          <hr />
        </Box>

        <Box variant='div' component='div' className='detail-group ps-24 pb-16'>
          <h4 className='f-14'>
            <RiPhoneLine />
            {t('contact')}
            {/* Contact Information */}
          </h4>
          <p className='f-13-5 fw-500'>{contents?.contact_name || '-'}</p>
          <p className='f-13-5 fw-500'>Ph1: {contents?.contact_phone || '-'}</p>
          <p className='f-13-5 fw-500'>Ph2: {contents?.contact_alt_phone || '-'}</p>
          <p className='f-13-5 fw-500'>{contents?.contact_email || '-'}</p>
          <hr />
        </Box>
      </Box>
    </div>
  );
};

export default SideBar;
