import React, { useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';
import { SortableItem } from './SortableItem'; // Custom component for Sortable Columns
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { login } from '../../store/slices/authSlice';

const DraggableTable = ({
  staticColumns,
  data,
  rowClassNames = () => {
    return '';
  },
  isClickable = false,
  count,
  current_page = 0,
  perPageRecord = 10,
  setPerPageRecords = () => {},
  setCurrPage,
  hidePagination = false,
  onRowClick = () => {},
}) => {
  const { order } = useSelector((state) => state?.authSlice);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(current_page);

  const handleChangePage = (event, newPage) => {
    setCurrPage(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setCurrPage(0);
    setPerPageRecords(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (staticColumns) setColumns(staticColumns);
  }, [staticColumns]);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      const oldIndex = columns.findIndex((col) => col.id === active?.id);
      const newIndex = columns.findIndex((col) => col.id === over?.id);
      if (oldIndex !== -1 && newIndex !== -1) {
        let newColOrder = arrayMove(columns, oldIndex, newIndex);
        setColumns(newColOrder);
        dispatch(
          login({
            data: { ...order, orderColumns: newColOrder?.map((col) => col.id) },
            code: 'order',
          })
        );
      }
    }
  };

  return (
    <div>
      <div className='table-div'>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <Table>
            <TableHead>
              <SortableContext
                items={columns.map((col) => col.id)}
                strategy={verticalListSortingStrategy}
              >
                <TableRow className={isClickable ? `cursor-pointer` : ``}>
                  {columns.map((column) => (
                    <SortableItem
                      column={column}
                      header={true}
                      key={column.id}
                      id={column.id}
                      label={column.label}
                      rowClassNames={rowClassNames}
                    />
                  ))}
                </TableRow>
              </SortableContext>
            </TableHead>

            <TableBody>
              <SortableContext
                items={columns.map((col) => col.id)}
                strategy={verticalListSortingStrategy}
              >
                {data.map((row) => (
                  <TableRow
                    onDoubleClick={() => {
                      onRowClick(row);
                    }}
                    className={isClickable ? `cursor-pointer` : ``}
                  >
                    {columns.map((column) => (
                      <SortableItem
                        column={column}
                        prevent={true}
                        row={row}
                        rowClassNames={rowClassNames}
                        key={column.id}
                        id={column.id}
                      />
                    ))}
                  </TableRow>
                ))}
              </SortableContext>
            </TableBody>
          </Table>
        </DndContext>
      </div>
      {!hidePagination && (
        <div>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 200]}
            component='div'
            count={count || data?.length}
            rowsPerPage={perPageRecord}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className='pagination-container'
          />
        </div>
      )}
    </div>
  );
};

export default DraggableTable;
