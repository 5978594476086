import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  addFormValue,
  addErrorDetails,
  clearErrorDetail,
  addTouchedFields,
} from '../../store/slices/questionSlice';

export const useRedux = () => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const formValues = useSelector((state) => state?.questionSlice?.formValues)?.find(
    (value) => value.tx_id === code
  )?.values;

  const touchedFields = useSelector((state) => state?.questionSlice?.touchedFields)?.find(
    (value) => value.tx_id === code
  )?.fields;

  const errorValues = useSelector((state) => state?.questionSlice?.errors)?.find(
    (value) => value.tx_id === code
  )?.values;

  const documents = useSelector((state) => state?.questionSlice?.documents)?.[code] || [];

  const setFormValue = ({ id, value }) => {
    dispatch(addFormValue({ id, value, tx_id: code }));
  };

  const getFormValue = (id) => {
    return formValues?.[id];
  };

  const setError = ({ id, error, error_message, type }) => {
    dispatch(
      addErrorDetails({ id, error, error_message, tx_id: code, error_type: type ? type : 'error' })
    );
  };

  const getErrorDetails = (id) => {
    return errorValues?.[id];
  };

  const removeError = (id) => {
    dispatch(clearErrorDetail({ id, tx_id: code }));
  };

  const getDocument = (name) => {
    return documents?.filter((doc) => doc.form_element_name === name);
  };

  const setTouched = (id) => {
    dispatch(addTouchedFields({ id, tx_id: code }));
  };

  const findIsTouched = (id) => {
    return touchedFields?.[id] || false;
  };

  return {
    setFormValue,
    getFormValue,
    getErrorDetails,
    setError,
    removeError,
    getDocument,
    setTouched,
    findIsTouched,
  };
};

export default useRedux;
