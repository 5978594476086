import React, { useEffect, useState } from 'react';
import {
  Drawer,
  TextField,
  IconButton,
  Grid,
  Typography,
  Divider,
  Button,
  Switch,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { arrayMoveImmutable } from 'array-move';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';
import { randomUUID } from '../../../utils/helpers';
import DeleteModal from '../../common/DeleteModal';
import { useForm } from 'react-hook-form';
import { Close } from '@mui/icons-material';
import { cloneDeep } from 'lodash';

const ManageGroupDrawer = (props) => {
  const [groups, setGroups] = useState(props.groups || []);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [id, setId] = useState('');

  useEffect(() => {
    setGroups(cloneDeep(props.groups));
  }, [props.groups]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  const handleGroupChange = (e, index) => {
    let tempGroups = [...groups];
    tempGroups[index].group_label = e.target.value;
    setGroups(tempGroups);
  };

  const handleGroupHideChange = (checked, index) => {
    let tempGroups = [...groups];
    tempGroups[index].is_hidden = checked;
    setGroups(tempGroups);
  };

  const handleGroups = () => {
    setGroups((groups) => [
      ...groups,
      {
        group_id: randomUUID(),
        group_type: 'nested',
        group_label: '',
        elements: [],
      },
    ]);
  };

  const handleDrag = (result) => {
    let tempGroups = [...groups];
    if (tempGroups.length > 1) {
      tempGroups = arrayMoveImmutable(tempGroups, result.source.index, result.destination.index);
      setGroups(tempGroups);
    }
  };

  const handleDeleteGroup = () => {
    setGroups((groups) => groups.filter((group) => group.group_id !== id));
    setId('');
    setShowDeleteModal(false);
  };
  const handleSave = () => {
    props.setData((data) => {
      let tempData = data;
      tempData.sections[props.currentSection].groups = groups;
      return tempData;
    });
    props.setCurrentGroup(0);
    props.setVisible(false);
  };

  const isUnique = (list, id, name) => {
    const filteredOptions = list?.filter((option) => option?.group_id !== id);
    return !filteredOptions?.some((option) => option?.group_label === name);
  };

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Drawer
        sx={{
          width: 500,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 500,
            boxSizing: 'border-box',
          },
        }}
        anchor='right'
        open={props.visible}
        onClose={() => {
          setGroups(cloneDeep(props.groups));
          props.setVisible(false);
        }}
      >
        <DrawerHeader>
          <Typography variant='h6'>Update Groups</Typography>
          <Close
            sx={{ position: 'absolute', right: '10px', top: '20px', color: 'grey' }}
            fontSize='small'
            onClick={() => {
              setGroups(cloneDeep(props.groups));
              props.setVisible(false);
            }}
            className='cursor-pointer'
          />
        </DrawerHeader>
        <Divider />
        <form onSubmit={handleSubmit(handleSave)} className='drawer-form'>
          <Droppable droppableId='test'>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ overflow: 'scroll', height: '570px' }}
              >
                <Grid container spacing={2} className='mt-4 f-12 fw-550'>
                  <Grid item className='flex-center' md={1}></Grid>
                  <Grid item md={5}>
                    Group Name
                  </Grid>
                  <Grid item md={4}>
                    Included in Participant Form
                  </Grid>
                  <Grid item md={2}>
                    Remove
                  </Grid>
                </Grid>
                {groups?.map((group, index) => (
                  <Draggable
                    draggableId={`${group.group_id}`}
                    index={index}
                    key={`${group.group_id}`}
                  >
                    {(provided, snapshot) => (
                      <Grid
                        container
                        spacing={2}
                        ref={provided.innerRef}
                        key={`${group.group_id}`}
                        className='mt-0'
                        {...provided.draggableProps}
                      >
                        <Grid item className='flex-center' md={1}>
                          <div
                            {...provided.dragHandleProps}
                            className='drag-icon'
                            style={{ cursor: 'grab' }}
                            key={`${group.group_id}`}
                          >
                            <DragIndicatorIcon fontSize='small' />
                          </div>
                        </Grid>
                        <Grid item md={5}>
                          <TextField
                            id='outlined-basic'
                            value={group?.group_label}
                            name={group?.group_id}
                            className='full-width'
                            variant='outlined'
                            inputProps={{
                              role: 'presentation',
                              autoComplete: 'off',
                            }}
                            // style={{ width: '280px' }}
                            {...register(group?.group_id, {
                              required: true,
                              validate: {
                                isUnique: (value) => isUnique(groups, group.group_id, value),
                              },
                            })}
                            onChange={(e) => handleGroupChange(e, index)}
                          />
                          {errors[group?.group_id] && (
                            <span className='error' style={{ fontSize: '13px' }}>
                              Group label is required
                            </span>
                          )}
                        </Grid>
                        <Grid item md={4}>
                          <Switch
                            checked={group?.is_hidden ? false : true}
                            onChange={(e) => {
                              handleGroupHideChange(!e.target.checked, index);
                            }}
                            color='primary'
                            size='small'
                          />
                        </Grid>
                        <Grid item md={2}>
                          <IconButton
                            className='icon-btn'
                            onClick={() => {
                              setId(group.group_id);
                              setShowDeleteModal(true);
                            }}
                          >
                            <DeleteIcon color='error' fontSize='small' />
                          </IconButton>
                          {index === groups.length - 1 && (
                            <IconButton
                              className='icon-btn'
                              onClick={() => {
                                handleGroups();
                              }}
                            >
                              <AddIcon color='primary' fontSize='small' />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Divider className='mt-20' />
          <Grid item className='p-8 d-flex justify-end mr-8'>
            {/* <Button
              variant='outlined'
              className='p-4'
              onClick={() => {
                setGroups(props.groups);
                props.setVisible(false);
              }}
            >
              Close
            </Button> */}
            {!groups?.length && (
              <Button
                variant='outlined'
                className='p-4 ml-8'
                onClick={() => {
                  handleGroups();
                }}
              >
                + Add
              </Button>
            )}
            <Button variant='contained' type='submit' className='ml-8 p-4'>
              Save
            </Button>
          </Grid>
        </form>
      </Drawer>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        item={'group'}
        submitFunction={handleDeleteGroup}
      />
    </DragDropContext>
  );
};

export default ManageGroupDrawer;
