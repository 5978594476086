import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutFormModal';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Stripe = ({ open, setOpen }) => {
  return (
    <>
      <Elements stripe={stripePromise}>
        <CheckoutForm open={open} setOpen={setOpen} />
      </Elements>
    </>
  );
};
export default Stripe;
