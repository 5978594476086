import { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { InputAdornment, Button, Typography, Box, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { resetForgotPassword } from '../../services/auth/auth';
import logo from '../../../src/assets/images/Voxtur-verify.png';
import Loader from '../../components/common/Loader';
import { useSelector } from 'react-redux';

const ResetForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const { token } = useParams();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const url = window.location.origin;
  const config = useSelector((state) => state.authSlice)?.configuration?.[url];
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const email = decodeURIComponent(new URLSearchParams(search).get('email'));
    const panel = new URLSearchParams(search).get('panel');
    const resData = await resetForgotPassword({
      ...data,
      token,
      email: email.replace(' ', '+'),
      panel,
    });
    if (resData?.success) {
      toast.success(resData?.message);
      config?.office_name
        ? navigate(`/${config?.office_name.replace(/ /g, '')}/login`)
        : navigate('/login');
    } else {
      toast.error(resData?.message || 'Something went wrong!');
    }
    setLoading(false);
  };

  return (
    <div>
      <Box className='admin-box-wrapper'>
        {loading && <Loader />}
        <Box
          sx={{
            borderRadius: 2,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className='admin-box'
        >
          <Box variant='div' component='div' className='admin-logo'>
            <img
              src={config?.company_logo_url ? config?.company_logo_url : logo}
              alt='logo-main'
              loading='lazy'
              className='logo-size'
            />
          </Box>
          <Typography component='h1' variant='h4' className='login-head-txt'>
            Reset Password
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
            className='reset-pwd'
            style={{ width: '100%' }}
          >
            <div className='mt-8'>
              <label className='login-label'>New Password</label>
              <label className='required'>{'*'}</label>
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                className='mt-4 mb-0'
                type={showNewPassword ? 'text' : 'password'}
                id='password'
                autoComplete='current-password'
                {...register('password', {
                  required: 'New Password is required.',
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {showNewPassword ? (
                        <Visibility
                          fontSize={'small'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        />
                      ) : (
                        <VisibilityOff
                          fontSize={'small'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && <span className='error'>{errors.password.message}</span>}
            </div>
            <div className='mt-8'>
              <label className='login-label'>Confirm New Password</label>
              <label className='required'>{'*'}</label>
              <TextField
                margin='normal'
                required
                fullWidth
                name='password_confirmation'
                className='mt-4 mb-0'
                type={showConfirmPassword ? 'text' : 'password'}
                id='password'
                autoComplete='current-password'
                {...register('password_confirmation', {
                  required: 'Confirm Password is required.',
                  validate: (value) => value === getValues('password') || 'Passwords do not match',
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {showConfirmPassword ? (
                        <Visibility
                          fontSize={'small'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      ) : (
                        <VisibilityOff
                          fontSize={'small'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password_confirmation && (
                <span className='error'>{errors.password_confirmation.message}</span>
              )}
            </div>
            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
              RESET
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default ResetForgotPassword;
