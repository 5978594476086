import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  MenuItem,
  Select,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: '600px',
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AddElementModal = ({ open, setOpen, handleSave }) => {
  const [selectedCategory, setSelectedCategory] = useState('regular');

  useEffect(() => {
    if (open) {
      setSelectedCategory('regular');
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          className='customized-dialog-title'
          style={{ fontSize: '16px' }}
        >
          Add Element
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Box component='form' noValidate className='client-user-form order-form'>
            <div className='f-13 fw-450'>
              If you want to add fields for a bank account, select <b>Bank Account Fields</b>. We
              will add a set of fields related to the bank account, and these fields will be
              eligible for automatic bank account verification on the participant form. Otherwise,
              if you want to add a normal field, select <b>Regular Field</b>.
            </div>
            <div className='mt-8'>
              {/* <label htmlFor='participantType' className='f-13 mt-16 fw-600'>
                Form Field Category
              </label> */}

              <Select
                fullWidth
                style={{
                  marginTop: '4px',
                }}
                value={selectedCategory}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                }}
              >
                <MenuItem value={'regular'}>{'Regular Field'}</MenuItem>
                <MenuItem value={'bank'}>{'Bank Account Fields'}</MenuItem>;
              </Select>
            </div>

            <DialogActions className='mt-8'>
              <Button
                autoFocus
                onClick={handleClose}
                className='p-2'
                color='secondary'
                style={{ color: 'grey' }}
              >
                Cancel
              </Button>
              <Button
                autoFocus
                variant='contained'
                color='primary'
                className='p-2 btn-participentType'
                onClick={() => {
                  handleSave(selectedCategory);
                }}
              >
                Add
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
export default AddElementModal;
