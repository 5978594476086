import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Help } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: '500px',
    padding: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ConfirmationModal = ({ open, setOpen, handleSave }) => {
  const [value, setValue] = React.useState('separate');

  const handleModalClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          className='customized-dialog-title'
          style={{ fontSize: '16px' }}
        >
          Multiple Sellers
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleModalClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>

        <DialogContent dividers className='mul-seller-radio'>
          <div className='f-13-5 fw-500 pl-8 pr-8 mt-8' style={{ lineHeight: '1.5' }}>
            You have added multiple sellers for the orders. Please select the flow you want to
            follow for handling multiple sellers.
          </div>
          <Grid container style={{ maxHeight: '40vh', overflowY: 'auto' }} className='pl-8 pr-8'>
            <Grid item xs={12}>
              {' '}
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              >
                <FormControlLabel
                  value='separate'
                  control={<Radio />}
                  label={
                    <div className='d-flex align-items-center'>
                      Separate Form Flow{' '}
                      <Tooltip title='A completely separate form will be sent to each seller included in the order. The primary seller will no longer be able to add new sellers from the participant form. If they want to add a new seller, they can contact an admin user, who can add it from the order details screen.'>
                        <Help className='ml-4' style={{ fontSize: '14px' }} />
                      </Tooltip>{' '}
                    </div>
                  }
                />
                <FormControlLabel
                  value='single'
                  control={<Radio />}
                  label={
                    <div className='d-flex align-items-center'>
                      {' '}
                      Sequential Flow
                      <Tooltip title='The primary seller completes the form first, then additional sellers will fill out the form. The additional sellers will receive a partially completed form (completed by the primary seller) and can continue filling in details like DL uploads and bank information.'>
                        <Help className='ml-4' style={{ fontSize: '14px' }} />
                      </Tooltip>{' '}
                    </div>
                  }
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='mt-8'>
          <Button
            autoFocus
            onClick={handleModalClose}
            className='p-2'
            color='secondary'
            style={{ color: 'grey' }}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={() => {
              handleSave(value);
            }}
            variant='contained'
            color='primary'
            className='pt-4 pb-4 pl-8 pr-8 btn-participentType'
          >
            Create
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default ConfirmationModal;
