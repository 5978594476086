import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  TextField,
  Button,
  FormControl,
  Select,
  Grid,
  CardContent,
  Box,
  Typography,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import {
  getSource,
  updateSource,
  addSource,
  getSourceList,
  getFieldList,
} from '../../../services/admin/sources';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/slices/breadcrumbSlice';

const AddEditSource = () => {
  const { state, pathname } = useLocation();
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state?.authSlice);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [sourceList, setSourceList] = useState([]);
  const [fieldList, setFieldList] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [selectedField, setSelectedField] = useState([]);
  const [sourceDetails, setSourceDetails] = useState({});

  useEffect(() => {
    if (id) fetchSourceData();
  }, [id]);

  useEffect(() => {
    fetchSourceList();
    fetchFieldList();
  }, []);

  useEffect(() => {
    if (sourceDetails?.source_form_field) {
      dispatch(
        setBreadcrumb({
          data: [
            {
              title: 'Source Mapping',
              url: '/admin/sources',
            },
            {
              title: sourceDetails?.source_form_field,
              url: '/admin/sources/:id',
            },
          ],
          key: pathname,
        })
      );
    }
  }, [sourceDetails?.source_form_field]);

  const schema = yup.object().shape(
    {
      source_path: yup.string().when('return_value_path', {
        is: (returnValuePath) => !returnValuePath || returnValuePath.length === 0,
        then: () =>
          yup.string().required('Source path is required when Return path is not provided'),
      }),
      return_value_path: yup.string().when('source_path', {
        is: (sourcePath) => !sourcePath || sourcePath.length === 0,
        then: () =>
          yup.string().required('Return path is required when Source path is not provided'),
      }),

      additional_condition: yup.string().nullable(),
      compare_value: yup.string().nullable(),
      compare_path: yup.string().nullable(),
      return_true: yup.string().nullable(),
      return_false: yup.string().nullable(),
    },
    ['source_path', 'return_value_path']
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchSourceData = async () => {
    setLoading(true);
    reset();
    const res = await getSource(id, admin?.token);
    if (res.success) {
      reset({ ...res.response });
      setSourceDetails({ ...res.response });
      setSelectedSource(res.response.source);
      setSelectedField(res.response.source_form_field);
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const fetchSourceList = async () => {
    const res = await getSourceList(admin?.token);
    if (res.success) {
      let sources = Object.keys(res?.response).map((key) => {
        return { value: key, label: res.response[key] };
      });
      setSourceList(sources);
      if (!id) setSelectedSource(sources?.[0]?.value);
    }
  };

  const fetchFieldList = async () => {
    setLoading(true);
    const res = await getFieldList(admin?.token);
    if (res.success) {
      setFieldList(res?.response);
      if (!id) setSelectedField(res?.response?.[0]?.element_name);
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    if (!selectedField?.length || !selectedSource?.length) {
      return;
    }
    setLoading(true);
    data['source'] = selectedSource;
    data['source_form_field'] = selectedField;
    const res = id
      ? await updateSource(id, data, admin?.token)
      : await addSource(data, admin?.token);
    if (res?.success) {
      toast.success(res?.message);
      if (id) {
        fetchSourceData();
      } else {
        navigate(`/admin/sources/${res?.response?.participant_condition_id}`);
      }
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <Grid item md={12} className='d-flex justify-between align-center sub-header'>
              <Typography variant='h4' className='mb-0'>
                {id ? (state?.disabled ? 'View Mapping' : 'Edit Mapping') : 'Add Mapping'}
              </Typography>
            </Grid>
            <Box sx={{ mt: 2 }} className='client-user-form'>
              <CardContent>
                <form onSubmit={handleSubmit(onSubmit)} className='user-form'>
                  <fieldset
                    disabled={state?.disabled ? true : false}
                    className='m-0 p-0 border-none'
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <label>Form Field</label>
                        <label className='required'>{'*'}</label>
                        <FormControl fullWidth variant='outlined'>
                          <Select
                            disabled={state?.disabled ? true : false}
                            onChange={(e) => {
                              setSelectedField(e.target.value);
                            }}
                            value={selectedField}
                          >
                            {fieldList?.map((option) => (
                              <MenuItem value={option.element_name}>
                                <Tooltip title={option?.label ?? option.label}>
                                  <div style={{ width: '100%' }}>{option.element_name}</div>
                                </Tooltip>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {!loading && !selectedField && (
                          <span className='error mb-8 f-12 mt-4' style={{ display: 'inherit' }}>
                            Form field is required.
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <label>Source</label>
                        <label className='required'>{'*'}</label>
                        <FormControl fullWidth variant='outlined'>
                          <Select
                            disabled={state?.disabled ? true : false}
                            onChange={(e) => {
                              setSelectedSource(e.target.value);
                            }}
                            value={selectedSource}
                          >
                            {sourceList?.map((option) => (
                              <MenuItem value={option?.value}>{option?.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {!loading && !selectedSource && (
                          <span className='error mb-8 f-12 mt-4' style={{ display: 'inherit' }}>
                            Source is required.
                          </span>
                        )}
                      </Grid>

                      <Grid item xs={6}>
                        <label>Source Path</label>
                        {/* {!getValues('return_value_path')?.length ? (
                          <label className='required'>{'*'}</label>
                        ) : (
                          ''
                        )} */}
                        <Controller
                          name='source_path'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter source path'
                              variant='outlined'
                              fullWidth
                              error={!!errors.source_path}
                              helperText={errors.source_path?.message}
                              autoComplete='off'
                              onChange={(e) => {
                                setValue('source_path', e.target.value);
                                trigger('return_value_path');
                                trigger('source_path');
                              }}
                            />
                          )}
                        />
                        <div
                          style={{
                            fontSize: '12px',
                            color: 'green',
                            marginTop: '4px',
                          }}
                        >
                          For example : $."re-sale".BUYER.BUYER_1_CORPORATE_INDICATOR
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <label>Additional condition</label>
                        <Controller
                          name='additional_condition'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter additional condition'
                              variant='outlined'
                              fullWidth
                              error={!!errors.additional_condition}
                              helperText={errors.additional_condition?.message}
                              autoComplete='off'
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>Compare value</label>
                        <Controller
                          name='compare_value'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter compare value'
                              variant='outlined'
                              fullWidth
                              error={!!errors.compare_value}
                              helperText={errors.compare_value?.message}
                              autoComplete='off'
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>Compare path</label>
                        <Controller
                          name='compare_path'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter compare path'
                              variant='outlined'
                              fullWidth
                              error={!!errors.compare_path}
                              helperText={errors.compare_path?.message}
                              autoComplete='off'
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>Return Path</label>
                        {/* {!getValues('source_path')?.length ? (
                          <label className='required'>{'*'}</label>
                        ) : (
                          ''
                        )} */}
                        <Controller
                          name='return_value_path'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter return path'
                              variant='outlined'
                              fullWidth
                              onChange={(e) => {
                                setValue('return_value_path', e.target.value);
                                trigger('source_path');
                                trigger('return_value_path');
                              }}
                              error={!!errors.return_value_path}
                              helperText={errors.return_value_path?.message}
                              autoComplete='off'
                            />
                          )}
                        />
                        <div
                          style={{
                            fontSize: '12px',
                            color: 'green',
                            marginTop: '4px',
                          }}
                        >
                          For example : <>$.primary_first_name</>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <label>Return value for true</label>
                        <Controller
                          name='return_true'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter return value for true'
                              variant='outlined'
                              fullWidth
                              error={!!errors.return_true}
                              helperText={errors.return_true?.message}
                              autoComplete='off'
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>Return value for false</label>
                        <Controller
                          name='return_false'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder='Enter return value for false'
                              variant='outlined'
                              fullWidth
                              error={!!errors.return_false}
                              helperText={errors.return_false?.message}
                              autoComplete='off'
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <div className='d-flex justify-end mt-8'>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => {
                              reset();
                              navigate('/admin/sources');
                            }}
                            className='mr-8'
                          >
                            Cancel
                          </Button>
                          <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={state?.disabled ? true : false}
                          >
                            {'Save'}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </fieldset>
                </form>
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddEditSource;
