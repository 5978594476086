import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { addCard } from '../../../services/questions/home';
import Loader from '../../common/Loader';
import { addCreditCardInfo } from '../../../store/slices/questionSlice';
import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const CheckoutFormModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { code } = useParams();
  const dispatch = useDispatch();
  const order_part_participant_id = useSelector(
    (state) => state.questionSlice.questionDetails
  )?.find((que) => que.tx_id === code)?.order_part_participant_id;
  const authData = useSelector((state) => state?.authSlice);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const cardElement = elements.getElement('card');
    const res = await stripe.createToken(cardElement);
    if (res?.error?.message?.length) {
      setLoading(false);
      return toast.error(res?.error?.message);
    }
    const cardData = await addCard(
      {
        order_part_participant_id: order_part_participant_id,
        card_token: res?.token?.id,
      },
      authData?.[code]?.token
    );
    if (cardData?.success) {
      dispatch(addCreditCardInfo({ show: true, info: cardData?.response || {}, tx_id: code }));
      toast.success(t('creditCardSuccess'));
    } else {
      toast.error(cardData?.message);
    }
    setLoading(false);
    handleClose();
  };

  return (
    <div>
      {loading && <Loader />}
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title' style={{ fontSize: '16px' }}>
          {t('creditCardLabel')}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <form onSubmit={handleSubmit}>
          <DialogContent dividers className='p-16'>
            <CardElement className='mt-8 mb-8 stripe-card-elem' />
            <div className='f-12 color-g mt-24'>{t('creditCardMessage')}</div>
          </DialogContent>
          <DialogActions className='mt-8'>
            <Button
              autoFocus
              onClick={handleClose}
              className='p-2'
              color='secondary'
              style={{ color: 'grey' }}
            >
              Close
            </Button>
            <Button
              autoFocus
              type='submit'
              variant='contained'
              color='primary'
              className='p-2 btn-participentType'
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default CheckoutFormModal;
