import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  ListItemIcon,
  Button,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { EmailOutlined, ExitToApp } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { adminLogout } from '../../services/auth/auth';
import { resetBreadcrumb } from '../../store/slices/breadcrumbSlice';
import Loader from './Loader';
import { logout } from '../../store/slices/authSlice';
import { CLIENT_USER_ROLE, OFFICE_USER_ROLE } from '../../utils/constants';

const ProfileDrawer = ({ open, onClose, type }) => {
  const dispatch = useDispatch();
  const url = window.location.origin;
  const { admin } = useSelector((state) => state?.authSlice);
  const { order } = useSelector((state) => state?.authSlice);
  const config = useSelector((state) => state.authSlice)?.configuration?.[url];
  const isUser =
    order?.user_role_name === OFFICE_USER_ROLE || order?.user_role_name === CLIENT_USER_ROLE
      ? true
      : false;
  const [loading, setLoading] = useState(false);
  const detail = type === 'admin' ? admin : order;
  const navigate = useNavigate();
  const handleProfileClick = () => {
    if (type === 'admin') {
      navigate(`/admin/profile`, {
        state: { disabled: detail?.user_role_name !== 'Admin' },
      });
    } else {
      navigate(`/orders/profile`, {
        state: { disabled: detail?.user_role_name !== 'Admin' },
      });
    }

    onClose();
  };

  const handleChangePasswordClick = () => {
    if (type === 'admin') {
      navigate(`/admin/changePassword`);
    } else {
      navigate(`/orders/changePassword`);
    }

    onClose();
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      const resData = await adminLogout(isUser ? order?.token : admin?.token);
      if (resData?.success) {
        dispatch(
          logout({
            code: 'admin',
            url,
          })
        );
        dispatch(resetBreadcrumb());
        config?.office_name
          ? navigate(`/${config?.office_name.replace(/ /g, '')}/login`)
          : navigate('/login');
      } else {
        toast.error(resData?.error || resData?.message || 'Something went wrong!');
      }
    } catch (error) {
      toast.error('Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      anchor='right'
      PaperProps={{
        sx: { width: `${window.innerWidth < 768 ? '250px' : '380px'}` },
      }}
      open={open}
      onClose={onClose}
      className='profile-drawer-main'
    >
      {loading && <Loader />}
      <List>
        <div className='user-profile-desc pt-16 pb-20'>
          <CloseIcon
            size={20}
            sx={{ position: 'absolute', right: '10px', top: '10px' }}
            onClick={onClose}
            className='cursor-pointer'
          />
          <Avatar sx={{ width: '60px', height: '60px' }} className='my-8 cursor-pointer bg-p'>
            {/* {generateAvatarText(
              detail?.first_name
                ? detail?.first_name?.split(' ')?.[0] + ' ' + detail?.last_name?.split(' ')?.[0]
                : detail?.client_name
            )} */}
            <PersonIcon fontSize='large' style={{ color: 'white' }} />
          </Avatar>
          <h4 className='text-center'>
            {detail?.first_name
              ? detail?.first_name + ' ' + detail?.last_name
              : detail?.client_name}
          </h4>
          <div className='d-flex align-items-center justify-center'>
            {' '}
            <EmailOutlined />{' '}
            <span
              className='px-4'
              style={{ fontSize: `${window.innerWidth < 768 ? '14px' : '16px'}` }}
            >
              {detail?.email}
            </span>
          </div>
        </div>
        <Divider />
        <div className='mt-16'>
          <ListItem button onClick={handleProfileClick} className='sidebar-item-drawer'>
            <ListItemIcon>
              <PersonIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText
              primary='Profile'
              className='profile-item'
              // style={{ marginTop: '1px', fontWeight: '400', fontSize: '13px', color: 'grey' }}
            />
          </ListItem>
          <ListItem button onClick={handleChangePasswordClick} className='sidebar-item-drawer'>
            <ListItemIcon>
              <KeyIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Change Password' className='profile-item' />
          </ListItem>
          <div style={{ position: 'fixed', bottom: '2px' }}>
            <ListItem button className='sidebar-item'>
              {' '}
              <Button variant='outlined' onClick={handleLogout} style={{ width: '345px' }}>
                {' '}
                <ExitToApp fontSize='small' className='mr-4' />
                Logout{' '}
              </Button>
            </ListItem>
          </div>
        </div>
      </List>
    </Drawer>
  );
};

export default ProfileDrawer;
