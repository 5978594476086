import { Tooltip } from '@mui/material';
import '../../styles/CustomStepper.scss';

const CustomStepper = ({ current, message }) => {
  return (
    <div className='container'>
      <div className='wrapper'>
        <div className='arrow-steps clearfix'>
          <Tooltip title={message[1]}>
            <div className={`step ${current === 1 ? 'current' : ''} ${current > 1 ? 'done' : ''}`}>
              <span>Collecting</span>{' '}
            </div>
          </Tooltip>

          <Tooltip title={message[2]}>
            <div className={`step ${current === 2 ? 'current' : ''} ${current > 2 ? 'done' : ''}`}>
              <span>Reviewing</span>{' '}
            </div>
          </Tooltip>

          <Tooltip title={message[3]}>
            <div className={`step ${current === 3 ? 'current' : ''} ${current > 3 ? 'done' : ''}`}>
              <span>Completed</span>{' '}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default CustomStepper;
