import React, { useEffect, useState } from 'react';
import { Drawer, TextField, IconButton, Grid, Typography, Divider, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { randomUUID } from '../../../utils/helpers';
import DeleteModal from '../../common/DeleteModal';
import { useForm } from 'react-hook-form';
import { Close } from '@mui/icons-material';

const ManageOptionsDrawer = (props) => {
  const [options, setOptions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [id, setId] = useState('');

  useEffect(() => {
    reset();
    setOptions(props.options);
  }, [props.options]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm();

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  const handleOptionChange = (e, index) => {
    let tempOptions = [...options];
    tempOptions[index].option_name = e.target.value;
    setOptions(tempOptions);
  };

  const handleOptions = () => {
    setOptions((options) => [
      ...options,
      {
        option_id: randomUUID(),
        option_name: '',
        elements: [],
      },
    ]);
  };

  const handleDeleteOption = () => {
    setOptions((options) => options.filter((option) => option.option_id !== id));
    setShowDeleteModal(false);
  };

  const handleSave = () => {
    props.setAllElements((prevElements) => {
      let tempElements = [...prevElements];
      const optionsIds = options.map((option) => option.option_id);
      const deletedOptions = tempElements[props.currentIndex].options
        .filter((option) => !optionsIds.includes(option.option_id))
        .map((option) => option.option_name);
      tempElements.forEach((element) => {
        if (element?.parent_value && deletedOptions.includes(element.parent_value)) {
          element.parent_value = options[0].option_name;
        }
      });

      tempElements[props.currentIndex].options = options;
      return tempElements;
    });
    props.setVisible(false);
  };

  const isUnique = (list, id, name) => {
    const filteredOptions = list?.filter((option) => option?.option_id !== id);
    return !filteredOptions?.some((option) => option?.option_name === name);
  };

  return (
    <Drawer
      sx={{
        width: 500,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 500,
          boxSizing: 'border-box',
        },
      }}
      anchor='right'
      open={props.visible}
      onClose={() => {
        setOptions(props.options);
        props.setVisible(false);
      }}
    >
      <DrawerHeader>
        <Typography variant='h6'>Manage Options</Typography>
        <Close
          sx={{ position: 'absolute', right: '10px', top: '20px', color: 'grey' }}
          fontSize='small'
          onClick={() => {
            setOptions(props.options);
            props.setVisible(false);
          }}
          className='cursor-pointer'
        />
      </DrawerHeader>
      <Divider />
      <form onSubmit={handleSubmit(handleSave)} className='drawer-form'>
        <div style={{ overflow: 'scroll', height: '570px' }}>
          {options?.map((option, index) => (
            <Grid container spacing={2} key={`${option.option_id}`} className='mt-0'>
              <Grid item md={7} className='ml-12'>
                <TextField
                  id='outlined-basic'
                  value={option?.option_name}
                  name={option?.option_id}
                  className='full-width'
                  variant='outlined'
                  inputProps={{
                    role: 'presentation',
                    autoComplete: 'off',
                  }}
                  {...register(option?.option_id, {
                    required: true,
                    validate: {
                      isUnique: (value) => isUnique(options, option.option_id, value),
                    },
                  })}
                  style={{ width: '280px' }}
                  onChange={(e) => handleOptionChange(e, index)}
                />
                {errors[option?.option_id] && (
                  <span className='error' style={{ fontSize: '13px' }}>
                    {errors[option?.option_id]?.type === 'isUnique'
                      ? 'Option label must be unique'
                      : 'Option label is required'}
                  </span>
                )}
              </Grid>

              <Grid item md={3}>
                <IconButton
                  className='icon-btn'
                  onClick={() => {
                    setId(option.option_id);
                    setShowDeleteModal(true);
                  }}
                  disabled={options?.length < 2}
                >
                  <DeleteIcon color='error' fontSize='small' />
                </IconButton>
                {index === options.length - 1 && (
                  <IconButton
                    className='icon-btn'
                    onClick={() => {
                      handleOptions();
                    }}
                  >
                    <AddIcon color='primary' fontSize='small' />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </div>

        <Divider className='mt-20' />
        <Grid item className='p-8 d-flex justify-end mr-8'>
          {/* <Button
            variant='outlined'
            className='p-4'
            onClick={() => {
              setOptions(props.options);
              props.setVisible(false);
            }}
          >
            Close
          </Button> */}
          {!options?.length && (
            <Button
              variant='outlined'
              className='p-4 ml-8'
              onClick={() => {
                handleOptions();
              }}
            >
              + Add
            </Button>
          )}
          <Button variant='contained' className='ml-8 p-4' type='submit'>
            Save
          </Button>
        </Grid>
      </form>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        item={'section'}
        submitFunction={handleDeleteOption}
      />
    </Drawer>
  );
};

export default ManageOptionsDrawer;
