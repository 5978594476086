import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Table from '../../../components/common/Table';
import EditIcon from '@mui/icons-material/Edit';
import CreateTemplateModal from '../../../components/admin/templates/CreateTemplateModal';
import { getTemplates } from '../../../services/admin/templates';
import Loader from '../../../components/common/Loader';
import { getClient } from '../../../services/admin/clients';
import ActionMenu from '../../../components/common/ActionMenu';
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../../../utils/constants';

const Templates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { clientId } = useParams();
  const { admin } = useSelector((state) => state?.authSlice);
  const isAdmin =
    admin?.user_role_name === SUPER_ADMIN_ROLE || admin?.user_role_name === ADMIN_ROLE
      ? true
      : false;
  const [loading, setLoading] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [addEditModalShow, setAddEditModalShow] = useState(false);
  const [perPageRecords, setPerPageRecords] = useState(100);
  const [total, setTotal] = useState(0);
  const [client, setClient] = useState({});
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [offices, setOffices] = useState([]);
  const [currPage, setCurrPage] = useState(0);

  useEffect(() => {
    fetchClient();
  }, [clientId]);

  useEffect(() => {
    if (isAdmin) {
      fetchTemplates();
    } else if (selectedOffice) {
      fetchTemplates();
    } 
  }, [currPage, perPageRecords, pathname, selectedOffice]);

  useEffect(() => {
    if (offices?.length) {
      if (offices?.length > 1) {
        let primaryOffice = offices.find((office) => office.is_primary);
        if (primaryOffice && (isAdmin ? (clientId ? true : false) : true))
          setSelectedOffice(primaryOffice?.client_office_id);
      } else {
        if (isAdmin ? (clientId ? true : false) : true) {
          setSelectedOffice(offices?.[0]?.client_office_id);
        }
      }
    }
  }, [offices]);

  const fetchClient = async () => {
    setClientLoading(true);
    const res = await getClient(clientId || admin?.client_id, admin?.token);
    if (res.success) {
      setClient(res.response);
      if (res?.response?.offices?.length) {
        setOffices(res.response.offices || []);
      }
    }
    setClientLoading(false);
  };

  const tableData = useMemo(() => {
    if (templates?.length) {
      return templates?.map((template) => {
        return {
          id: template?.id || '-',
          createdBy: template?.created_by_user || '-',
          participantType: template?.participant_type_name || '-',
          orderType: template?.order_type_name || '-',
          actions: (props) => (
            <div>
              <ActionMenu
                actions={[
                  {
                    label: 'Edit Template',
                    logo: <EditIcon className='action-menu-item' />,
                    onClick: () =>
                      navigate(
                        clientId
                          ? `/admin/clients/templates/${clientId}/${props.id}`
                          : `/admin/templates/${props.id}`
                      ),
                  },
                ]}
              />
            </div>
          ),
        };
      });
    }
    return [];
  }, [templates]);

  const fetchTemplates = async () => {
    setLoading(true);
    const res = await getTemplates(
      admin?.token,
      currPage + 1,
      perPageRecords,
      clientId || admin?.client_id,
      selectedOffice
    );
    if (res?.success) {
      setTemplates(res?.response?.data || []);
      setTotal(res?.response?.meta?.total);
    } else {
      setTemplates([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const columns = [
    { id: 'actions', label: 'Actions', type: 'jsx' },
    { id: 'id', label: 'ID' },
    { id: 'participantType', label: 'Participant Type' },
    { id: 'orderType', label: 'Order Type' },

    { id: 'createdBy', label: 'Created By' },
  ];

  const onRowClick = (row) => {
    navigate(
      clientId ? `/admin/clients/templates/${clientId}/${row.id}` : `/admin/templates/${row.id}`
    );
  };

  return (
    <Box className='dashboard-main'>
      {(loading || clientLoading) && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center'>
                <Typography variant='h4' className='mb-0'>
                  {clientId && client?.company_name
                    ? `Participant Form Configuration for ${client?.company_name}`
                    : isAdmin
                    ? 'Master Form Configuration'
                    : 'Participant Form Configuration'}
                </Typography>
                <div className='flex-row'>
                  {!client?.is_form_template_same_for_all_offices && offices?.length > 1 ? (
                    <div className='mr-12 table-select-filter'>
                      <label id='demo-simple-select-label'>Office : </label>
                      <Select
                        sx={{
                          // Reset common styles
                          marginLeft: '4px',
                          width: '200px',
                          // Add any additional styles you need
                        }}
                        defaultValue={selectedOffice}
                        displayEmpty={true}
                        value={selectedOffice}
                        onChange={(e) => {
                          setSelectedOffice(e.target.value);
                        }}
                      >
                        {offices?.map((office) => (
                          <MenuItem value={office.client_office_id} key={office.client_office_id}>
                            {office.office_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    ''
                  )}
                  {!clientId && (
                    <Button
                      autoFocus
                      onClick={() => {
                        setAddEditModalShow(true);
                      }}
                      variant='contained'
                      color='primary'
                      style={{ padding: '4px 12px' }}
                    >
                      + Add Participant Type
                    </Button>
                  )}
                </div>
              </Grid>
            </CardContent>
            <Box>
              <CardContent>
                {client?.is_form_template_same_for_all_offices ? (
                  <Alert
                    severity='info'
                    className='f-13 mb-8 fw-450 '
                    style={{ padding: '2px 10px' }}
                  >
                    All offices within your client follow the same participant templates. Therefore,
                    if you add or update participant form templates, the changes will be reflected
                    for all offices within your client.
                  </Alert>
                ) : (
                  ''
                )}
                <Table
                  loader={loading}
                  columns={columns}
                  data={tableData}
                  count={total}
                  current_page={currPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                  isClickable={true}
                  onRowClick={onRowClick}
                />
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
      <CreateTemplateModal
        open={addEditModalShow}
        setOpen={setAddEditModalShow}
        fetchTemplates={fetchTemplates}
        // offices={}
        offices={offices}
        isSameQuestions={client?.is_form_template_same_for_all_offices}
      />
    </Box>
  );
};
export default Templates;
