import { FilterAlt } from '@mui/icons-material';
import { IconButton, Popper, styled, css, Button, ClickAwayListener } from '@mui/material';
import FilterComponent from './FilterComponent';

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledPopperDiv = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: ${theme.palette.mode === 'dark'
      ? `0px 4px 8px rgb(0 0 0 / 0.7)`
      : `0px 4px 8px rgb(0 0 0 / 0.1)`};
    padding: 5px;
    color: ${theme.palette.mode === 'dark' ? blue[200] : blue[800]};
    font-size: 0.875rem;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    // opacity: 1;
    margin: 0.25rem 0;
  `
);

const FilterPopper = ({
  anchor,
  setAnchor,
  field,
  label,
  filters,
  setFilters,
  dateRange,
  setDateRange,
  fetchFormData = () => {},
  type = 'text',
  options = [],
  isDateAdded = false,
}) => {
  return (
    <div>
      <IconButton
        aria-describedby={'simple-popper'}
        type='button'
        onClick={(event) => {
          setAnchor(anchor ? null : event.currentTarget);
        }}
      >
        <FilterAlt
          className={
            String(filters[field] ? filters[field] : '')?.length || isDateAdded
              ? 'color-p'
              : 'color-g'
          }
          style={{
            fontSize: '14px',
          }}
        />
      </IconButton>
      <Popper id={'simple-popper'} open={Boolean(anchor)} anchorEl={anchor}>
        <ClickAwayListener
          onClickAway={(e) => setAnchor(null)}
          // onClickAway={(e) => (type === 'select' ? '' : Boolean(anchor) ? setAnchor(null) : '')}
        >
          <StyledPopperDiv>
            <FilterComponent
              values={filters}
              setValues={setFilters}
              field={field}
              setAnchor={setAnchor}
              anchor={anchor}
              label={label}
              dateRange={dateRange}
              setDateRange={setDateRange}
              type={type}
              options={options}
            />
            <div className='d-flex justify-end'>
              {setDateRange && (
                <Button
                  color='secondary'
                  onClick={() => {
                    setDateRange([{ startDate: '', endDate: '', key: 'selection' }]);
                    fetchFormData(true);
                  }}
                  style={{ fontSize: '13px', color: 'grey' }}
                >
                  Clear
                </Button>
              )}
            </div>
          </StyledPopperDiv>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};
export default FilterPopper;
