import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Link } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import { downloadOtherDocuments } from '../../../../services/questions/home';
import { toast } from 'react-toastify';
import { getDocument } from '../../../../services/admin/templates';
import DocumentViewer from '../../DocumentViewer';
import { useTranslation } from 'react-i18next';

// need to optimize this in future
const allowedTypes = [
  'privacy_policy',
  'third_party_auth',
  'release_authorization',
  'disbursement_seller_proceeds',
  'disbursement_buyer_proceeds',
  'disbursement_borrower_proceeds',
  'demand_request_1',
  'demand_request_2',
  'demand_request_3',
  'demand_request_4',
  'demand_request_5',
  'demand_request_6',
  'demand_request_7',
  'demand_request_8',
  'demand_request_9',
  'demand_request_10',
  'demand_request_11',
  'demand_request_12',
  'demand_request_13',
  'demand_request_14',
  'demand_request_15',
  'demand_request_16',
  'demand_request_17',
  'borrower_authorization',
];
const MediaLink = ({
  element,
  getQuestionData,
  getDocs = () => {},
  skip = false,
  setLoading = () => {},
}) => {
  const { code, participantNo } = useParams();
  const { t } = useTranslation();
  const template_id = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.template_id;
  const authData = useSelector((state) => state?.authSlice);
  const [docModalOpen, setDocModalOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [originalUrl, setOriginalUrl] = useState('');

  const onClickHandler = async () => {
    setLoading(true);
    if (element?.disabled) {
      toast.error(t('signRequired2'));
      return setLoading(false);
    }

    if (allowedTypes.includes(element.name)) {
      const res = await downloadOtherDocuments(
        code,
        authData?.[code]?.token,
        element.name,
        participantNo || 1
      );
      if (res?.success && res?.response?.file) {
        fetch(res?.response?.file)
          .then((response) => {
            return response.blob();
          })
          .then((blobData) => {
            const blobUrl = URL.createObjectURL(blobData);
            setUrl(blobUrl);
            setOriginalUrl(res?.response?.file);
            setDocModalOpen(true);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
        if (!skip) {
          await getQuestionData(code);
          await getDocs();
        }
      } else {
        toast.error('Document is not available');
      }
    } else {
      const res = await getDocument(template_id, element.element_name, authData?.[code]?.token);
      if (res?.success && res?.response?.files?.[0]?.url) {
        fetch(res?.response?.files?.[0]?.url)
          .then((response) => {
            return response.blob();
          })
          .then((blobData) => {
            const blobUrl = URL.createObjectURL(blobData);
            setUrl(blobUrl);
            setOriginalUrl(res?.response?.files?.[0]?.url);
            setDocModalOpen(true);
          });

        // window.open(res?.response?.files?.[0]?.url, '_blank');
      } else {
        toast.error('Document is not available');
      }
    }
    setLoading(false);
  };

  return (
    <>
      <DocumentViewer
        open={docModalOpen}
        setOpen={setDocModalOpen}
        fileUrl={url}
        originalUrl={originalUrl}
        title={element?.label || 'Document'}
      />
      <Grid container spacing={1} className='grid-container mt-4'>
        <Grid xs={12} item={true} style={{ paddingTop: '0px' }}>
          <Link
            href={''}
            className='media-link d-flex'
            onClick={(e) => {
              e.preventDefault();
              onClickHandler();
            }}
            style={{ textDecoration: 'none' }}
          >
            <ArticleIcon fontSize='small' />
            <div className='f-13 fw-600 ml-8'>{element.label}</div>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};
export default MediaLink;
