import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import logo from '../../../assets/images/Voxtur-verify.png';

const WelcomeModal = ({ startAuth, loading, isWelcomeModal, logoUrl, companyName }) => {
  return (
    <>
      <Box
        variant='div'
        component='div'
        className={`${!isWelcomeModal ? 'd-none' : ''} welcome-box-wrapper`}
      >
        <Box variant='div' component='div' className='welcome-box'>
          <Box variant='div' component='div' className='welcome-logo'>
            <img
              src={logoUrl ? logoUrl : logo}
              alt='logo-main'
              loading='lazy'
              className='logo-size'
            />
          </Box>
          <Box variant='div' component='div' className='welcome-heading'>
            <Typography variant='h4' className='head-txt'>
              Welcome to {companyName?.length ? companyName : 'Voxtur'} Verify
            </Typography>

            <Box variant='div' component='div' className='msg-body'>
              <Typography className='body-txt'>
                Congratulations on your recent real estate transaction. As part of the Title
                insurance application process, your lender has requested that you complete the
                Seller Statement of Information form to expedite your process.
              </Typography>

              <Typography className='mt-12 body-txt'>
                We will guide you through an easy step-by-step process that verifies who you are and
                some important information about your property.
              </Typography>
            </Box>
            <Button
              variant='contained'
              size='large'
              className='primary-btn btn mt-20 start-btn'
              disableRipple
              onClick={startAuth}
              disabled={loading}
              endIcon={loading && <CircularProgress size={20} />}
            >
              {' '}
              LET'S GET STARTED
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WelcomeModal;
