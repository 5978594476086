import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: '500px',
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ConfirmationModal = ({ open, setOpen, handleSave }) => {
  const handleModalClose = () => setOpen(false);
  return (
    <div>
      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          className='customized-dialog-title'
          style={{ fontSize: '16px' }}
        >
          Confirmation
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleModalClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>

        <DialogContent dividers>
          <div className='f-13 mt-8 mb-8 fw-500' style={{ lineHeight: '1.5' }}>
            This action is not reversible. You cannot check this checkbox again once the
            questionnaires are specifically configured for each office. Are you sure you want to
            continue?
          </div>
        </DialogContent>
        <DialogActions className='mt-8'>
          <Button
            autoFocus
            onClick={handleModalClose}
            className='p-2'
            color='secondary'
            style={{ color: 'grey' }}
          >
            No
          </Button>
          <Button
            autoFocus
            onClick={() => {
              handleSave(true);
            }}
            variant='contained'
            color='primary'
            className='p-2 btn-participentType'
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default ConfirmationModal;
