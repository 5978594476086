import React, { useState, useEffect } from 'react';
import { TextField, Grid } from '@mui/material';
import useRedux from '../../../../utils/hooks/useRedux';
import { errorChecker } from '../../../../utils/helpers';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TextArea = ({ element, disabled }) => {
  const { code } = useParams();
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code];
  const languageForLabel = selectedLanguage && selectedLanguage !== 'en' ? selectedLanguage : '';
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  const {
    setFormValue,
    getFormValue,
    getErrorDetails,
    removeError,
    setError,
    setTouched,
    findIsTouched,
  } = useRedux();
  const formValue = getFormValue(element.element_id);
  const { error, error_message } = getErrorDetails(element.element_id) || {
    error: false,
    error_message: '',
  };
  const [value, setValue] = useState('');

  useEffect(() => {
    if (formValue !== undefined) setValue(formValue || null);
    else if (element.user_value) setValue(element.user_value);
  }, []);

  const handleChange = (event) => {
    console.log(event.target.value);
    setTouched(element.element_id);
    setValue(event.target.value);
    setFormValue({ id: element.element_id, value: event.target.value });
  };

  const handleError = () => {
    console.log('in error state');
    const { isError, message } = errorChecker(
      element,
      findIsTouched(element.element_id) ? formValue : element.user_value
    );
    if (!isError) {
      removeError(element.element_id);
    } else {
      setError({ id: element.element_id, error: true, error_message: message });
    }
  };

  return (
    <>
      {element.label !== '' && (
        <Grid xs={12} md={12} spacing={1} className='grid-container plt-8 pr-8'>
          <Grid xs={12} item={true}>
            <label
              dangerouslySetInnerHTML={{
                __html: languageForLabel
                  ? translations?.[languageForLabel]?.elements?.[element.element_id]
                    ? translations?.[languageForLabel]?.elements?.[element.element_id]
                    : element?.label
                  : element?.label,
              }}
            />
            <label className='required'>{element.required && '*'}</label>
          </Grid>
          <Grid xs={12} item={true} className='mt-4'>
            {/* <TextField
              error={error}
              className='full-width'
              multiline
              rows={1}
              variant='outlined'
              onChange={handleChange}
              value={value}
              style={{ width: '100%' }}
              helperText={error_message}
              onBlur={handleError}
              disabled={disabled}
            /> */}
            <textarea
              rows={4}
              onChange={handleChange}
              value={value}
              onBlur={handleError}
              disabled={disabled}
              style={{ width: '100%', borderRadius: '10px', borderColor: '#cac9c9' }}
            />
            {/* <div className='f-13 error'>{error_message}</div> */}
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default TextArea;
