import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getConfigurations } from '../../../services/auth/auth';
import { Alert, Box, Chip, Grid } from '@mui/material';
import Table from '../../../components/common/Table';
import Loader from '../../../components/common/Loader';

const Offices = () => {
  const url = window.location.origin;
  const navigate = useNavigate();
  const [companyLoading, setCompanyLoading] = useState(false);
  const [offices, setOffices] = useState([]);
  const tableData = useMemo(() => {
    if (offices?.length) {
      return offices.map((ofc) => {
        return {
          office: (props) => (
            <div>
              {ofc.office_name}
              {ofc.is_primary ? (
                <Chip
                  label={'Primary'}
                  style={{
                    height: '20px',
                    padding: '2px',
                    borderRadius: '5px',
                    marginLeft: '8px',
                  }}
                  className='bg-p'
                />
              ) : (
                ''
              )}
            </div>
          ),
          office_url: () => (
            <div
              className='mt-4 f-13-5 fw-500 cursor-pointer color-p'
              onClick={() => navigate(`/${ofc?.office_name?.replace(/ /g, '')}`)}
            >{`${url}/${ofc?.office_name?.replace(/ /g, '')}`}</div>
          ),
          logo: () => (
            <Box variant='div' component='div' className='admin-logo'>
              <img
                src={ofc?.company_logo_url ? ofc?.company_logo_url : ''}
                alt='logo-main'
                loading='lazy'
                className='logo-size'
              />
            </Box>
          ),
        };
      });
    }
    return [];
  }, [offices]);

  useEffect(() => {
    getConfigurationDetails();
  }, []);

  const getConfigurationDetails = async () => {
    setCompanyLoading(true);
    let body = { domain: url };
    const res = await getConfigurations(body);
    if (res.success) {
      setOffices(res?.response?.filter((ofc) => ofc.status !== 'disabled'));
      setCompanyLoading(false);
    } else {
      setOffices([]);
    }
  };

  const columns = [
    {
      id: 'logo',
      label: 'Logo',
      type: 'jsx',
    },
    {
      id: 'office',
      label: 'Office Name',
      type: 'jsx',
    },
    {
      id: 'office_url',
      label: 'Office URL',
      type: 'jsx',
    },
  ];

  return (
    <Box className='offices'>
      {companyLoading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}></Grid>
        <Grid item md={12}>
          <div className='office-table' style={{ width: '50%', margin: '0 auto' }}>
            <Alert severity='info' className='f-12 fw-550 mb-8' style={{ textAlign: 'justify' }}>
              Your company operates from multiple offices. Here is a list of all the offices. You
              can log in using one of the URLs mentioned below for your respective office.
            </Alert>{' '}
            <Table columns={columns} data={tableData} hidePagination={true} />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Offices;
