import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '8px 16px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

const AutoCompleteModal = ({ open, setOpen, onVerify }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          style={{ fontSize: '16px' }}
          className='p-12'
        >
          <div className='d-flex align-items-center'>
            <div>Verification Confirmation</div>
          </div>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers className='p-16'>
          <div className='f-13-5 fw-500'>
            All form fields for the participant have been verified. Would you like to complete
            verification and mark this participant's form as verified?
            <b>
              {' '}
              Please note that after completing verification, you won't be able to make any further
              modifications.
            </b>
          </div>
        </DialogContent>
        <DialogActions className='p-10'>
          <Button
            autoFocus
            onClick={handleClose}
            className='p-2'
            color='secondary'
            style={{ color: 'grey', marginRight: '4px' }}
          >
            No
          </Button>
          <Button
            autoFocus
            color='primary'
            variant='contained'
            className='p-4'
            onClick={() => {
              onVerify();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default AutoCompleteModal;
