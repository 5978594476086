import { Article } from '@mui/icons-material';
import { Grid, Link } from '@mui/material';

const DocumentLink = ({ handleClick, label }) => {
  return (
    <Grid container spacing={1} className='mt-4'>
      <Grid xs={12} item={true}>
        <Link
          href={''}
          className='media-link d-flex'
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
          style={{ textDecoration: 'none', width: 'fit-content' }}
        >
          <Article fontSize='small' />
          <div className='f-13 fw-500 ml-8'>{label}</div>
        </Link>
      </Grid>
      <hr className='mt-4 mb-4 w-100p' style={{ backgroundColor: '#d2cece' }} />
    </Grid>
  );
};

export default DocumentLink;
