import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import Loader from '../../common/Loader';
import { getOrdersList } from '../../../services/admin/users';
import { getUsersOptions } from '../../../services/order/order';
import { OFFICE_ADMIN_ROLE, OFFICE_USER_ROLE } from '../../../utils/constants';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: '600px',
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AssignModal = ({ open, setOpen, record, onSave, onOtherOperations, token, btnName, note }) => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [options, setOptions] = useState([]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (record.id && open) getOrders();
  }, [record.id, open]);

  useEffect(() => {
    if (open) fetchUsers();
  }, [open, record?.id]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOtherOperations = () => {
    onOtherOperations();
  };

  const handleSave = async (data) => {
    let payload = { ...data, order_id: orders?.map((order) => order?.order_id) };
    await onSave(payload);
    reset();
  };

  const getOrders = async () => {
    setLoading(true);
    const res = await getOrdersList(record.id, token);
    if (res.success) {
      setOrders(res.response || []);
    }
    setLoading(false);
  };

  const fetchUsers = async () => {
    setLoading(true);
    let payload = {};
    if (record?.client_id) payload.client_id = record?.client_id;
    if (record?.user_role_name === OFFICE_ADMIN_ROLE || record?.user_role_name === OFFICE_USER_ROLE)
      payload.client_office_id = record?.offices[0]?.client_office_id;
    const res = await getUsersOptions(payload, token);
    if (res.success) {
      setOptions(res.response || []);
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && <Loader />}
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          className='customized-dialog-title'
          style={{ fontSize: '16px' }}
        >
          Assign Order for : {record.first_name + ' ' + record.last_name}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <div
            className='f-13 mt-8 fw-500 mb-8 color-g'
            dangerouslySetInnerHTML={{ __html: note }}
          ></div>
          <Grid container style={{ maxHeight: '40vh', overflowY: 'auto' }}>
            {orders?.length ? (
              orders?.map((order) => (
                <Grid
                  item
                  md={3}
                  style={{ borderBottom: '1px solid rgb(229 229 229)' }}
                  className='p-8 f-13'
                >
                  {order.escrow_no}
                </Grid>
              ))
            ) : (
              <div className='f-13 color-p'>User has not created any order.</div>
            )}
          </Grid>
          <Box component='form' onSubmit={handleSubmit(handleSave)} style={{ marginTop: '12px' }}>
            <label className='f-13'>Select User To Assign</label>
            <label className='required'>{'*'}</label>
            <Controller
              name={`user_id`}
              control={control}
              rules={{ required: 'User is required' }}
              defaultValue=''
              render={({ field, fieldState }) => (
                <FormControl fullWidth>
                  <Select {...field} disabled={!orders?.length}>
                    {options?.map((item) => {
                      return (
                        <MenuItem
                          value={item?.user_id}
                          disabled={item?.user_id === record?.user_id}
                        >
                          {item?.full_name}
                        </MenuItem>
                      );
                    })}
                    {options?.length === 0 && <MenuItem value=''>User not available.</MenuItem>}
                  </Select>
                  <Typography className='order-error' color='error' style={{ fontSize: '12px' }}>
                    {fieldState.error ? fieldState.error.message : ''}
                  </Typography>
                </FormControl>
              )}
            />

            <DialogActions className='mt-8'>
              <Button autoFocus onClick={handleOtherOperations} className='p-2' color='secondary'>
                {btnName ? btnName : 'Delete'}
              </Button>
              <Button
                autoFocus
                type='submit'
                variant='contained'
                color='primary'
                style={{ padding: '4px 8px' }}
                disabled={!orders?.length}
              >
                Assign & {btnName ? btnName : 'Delete'}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
export default AssignModal;
