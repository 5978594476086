import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const ListItemComponent = ({ label, route, icon, isMenuOpen = true }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ListItem
      key={label}
      title={!isMenuOpen ? label : ''}
      button
      onClick={() => navigate(route)}
      className={`${
        route?.split('/')?.[2] === location?.pathname?.split('/')?.[2] ? 'selected-item' : ''
      } sidebar-item`}
    >
      {<ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={label} style={{ fontSize: '14px' }} />
    </ListItem>
  );
};
export default ListItemComponent;
