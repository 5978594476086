import { authorizedApi } from '../../utils/api';

export const getOrderUserProfile = (token) => {
  return authorizedApi(token).get(`/user/profile`);
};

export const updateOrderUserProfile = (data, token) => {
  return authorizedApi(token).post(`/user/profile`, data);
};

export const getUserProfile = (token) => {
  return authorizedApi(token).get(`/user/profile`);
};

export const updateUserProfile = (data, token) => {
  return authorizedApi(token).post(`/user/profile`, data);
};
