import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, CardContent, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Table from '../../../components/common/Table';
import { getList } from '../../../services/admin/auditTrails';
import Loader from '../../../components/common/Loader';
import moment from 'moment';
import FilterPopper from '../../../components/common/FilterPopper';
import { CLIENT_ADMIN_ROLE, OFFICE_ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../../../utils/constants';

const AuditTrails = () => {
  const { admin } = useSelector((state) => state?.authSlice);
  const isSuperAdmin = admin?.user_role_name === SUPER_ADMIN_ROLE ? true : false;
  const isOfficeAdmin = admin?.user_role_name === OFFICE_ADMIN_ROLE ? true : false;
  const isClientAdmin = admin?.user_role_name === CLIENT_ADMIN_ROLE ? true : false;
  const [loading, setLoading] = useState(false);
  const [perPageRecords, setPerPageRecords] = useState(100);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [anchor1, setAnchor1] = useState(null);
  const [anchor2, setAnchor2] = useState(null);
  const [anchor3, setAnchor3] = useState(null);
  const [anchor4, setAnchor4] = useState(null);
  const [anchor5, setAnchor5] = useState(null);
  const [dateRange, setDateRange] = useState([{ startDate: '', endDate: '', key: 'selection' }]);
  const [isDateAdded, setIsDateAdded] = useState(false);

  const tableData = useMemo(() => {
    if (list?.length) {
      return list?.map((user) => {
        return {
          ...user,
          user_name: () => <div>{user?.first_name + ' ' + user?.last_name}</div>,
          office_name: () => (
            <div>
              {user?.role_name === 'client admin'
                ? 'All Offices'
                : user?.office_name
                ? user?.office_name
                : '-'}
            </div>
          ),
          activity_date: () => (
            <div>{moment(user?.activity_timestamp).format('MM/DD/YYYY HH:mm:ss A')}</div>
          ),
        };
      });
    }
    return [];
  }, [list]);

  useEffect(() => {
    setTimeout(() => {
      if (
        dateRange[0]?.startDate &&
        dateRange[0]?.startDate !== 'Invalid date' &&
        dateRange[0]?.endDate &&
        dateRange[0]?.endDate !== 'Invalid date'
      ) {
        setIsDateAdded(true);
        fetchList();
      } else {
        setIsDateAdded(false);
      }
    }, [1000]);
  }, [dateRange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (Object.keys(filters || {})?.length) {
        fetchList();
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [filters]);

  useEffect(() => {
    fetchList();
  }, [currPage, perPageRecords]);

  const fetchList = async (isClear = false) => {
    setLoading(true);
    let str = `page=${currPage + 1}&per_page=${perPageRecords}`;
    Object.keys(filters).forEach((key) => {
      if (filters?.[key]?.length) {
        str = str + `&${key}=${encodeURIComponent(filters?.[key])}`;
      }
    });
    if (
      dateRange[0]?.startDate &&
      dateRange[0]?.startDate !== 'Invalid date' &&
      dateRange[0]?.endDate &&
      dateRange[0]?.endDate !== 'Invalid date' &&
      !isClear
    ) {
      str =
        str +
        `&from_date=${moment(
          dateRange?.[0]?.startDate,
          'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
        ).format('YYYY-MM-DD')}&to_date=${moment(
          dateRange?.[0]?.endDate,
          'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
        ).format('YYYY-MM-DD')}`;
    }
    const res = await getList(admin?.token, str);
    if (res?.success) {
      // setList(res?.response || []);
      setList(res?.response?.data || []);
      setTotal(res?.response?.meta?.total);
    } else {
      setList([]);
      setTotal(0);
    }
    setLoading(false);
  };
  const superAdminColumns = [
    {
      id: 'user_name',
      label: 'User Name',
      width: '170px',
      type: 'jsx',
      // render: (props) => {
      //   return (
      //     <div className='d-flex justify-start align-items-center'>
      //       <div>User Name</div>
      //       <FilterPopper
      //         anchor={anchor}
      //         setAnchor={setAnchor}
      //         field={props?.id}
      //         label={props?.label}
      //         filters={filters}
      //         setFilters={setFilters}
      //         type='text'
      //       />
      //     </div>
      //   );
      // },
    },
    {
      id: 'company_name',
      label: 'Company Name',
      width: '190px',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Company Name</div>
            <FilterPopper
              anchor={anchor1}
              setAnchor={setAnchor1}
              field={'client_name'}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='text'
            />
          </div>
        );
      },
    },
    {
      id: 'office_name',
      label: 'Office Name',
      type: 'jsx',
      width: '190px',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Office Name</div>
            <FilterPopper
              anchor={anchor3}
              setAnchor={setAnchor3}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='text'
            />
          </div>
        );
      },
    },
    {
      id: 'email',
      label: 'Email',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Email</div>
            <FilterPopper
              anchor={anchor2}
              setAnchor={setAnchor2}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='text'
            />
          </div>
        );
      },
    },
    {
      id: 'activity',
      label: 'Activity',
      width: '120px',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Activity</div>
            <FilterPopper
              anchor={anchor5}
              setAnchor={setAnchor5}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        );
      },
    },
    { id: 'description', label: 'Description', width: '280px' },
    {
      id: 'activity_date',
      label: 'Activity Date',
      type: 'jsx',
      width: '250px',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Activity Date</div>
            <FilterPopper
              anchor={anchor4}
              setAnchor={setAnchor4}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              dateRange={dateRange}
              setDateRange={setDateRange}
              fetchFormData={fetchList}
              type='date'
              isDateAdded={isDateAdded}
            />
          </div>
        );
      },
    },
  ];

  const columns = isSuperAdmin
    ? superAdminColumns
    : isClientAdmin
    ? superAdminColumns?.filter((col) => col.id !== 'company_name')
    : isOfficeAdmin
    ? superAdminColumns?.filter((col) => col.id !== 'company_name' && col.id !== 'office_name')
    : superAdminColumns;

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center'>
                <Typography variant='h4' className='mb-0'>
                  Audit Trails
                </Typography>
              </Grid>
            </CardContent>
            <Box>
              <CardContent>
                <Table
                  loader={loading}
                  columns={columns}
                  data={tableData}
                  count={total}
                  current_page={currPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                />
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AuditTrails;
