import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, CardContent, Chip, Grid, MenuItem, Select, Typography } from '@mui/material';
import Table from '../../../components/common/Table';
import { Edit, Visibility, PersonAdd, Delete } from '@mui/icons-material';
import { getUsers, deleteUser, getClients, getOfficeList } from '../../../services/admin/users';
import Loader from '../../../components/common/Loader';
import { toast } from 'react-toastify';
import { ADMIN_ROLE, CLIENT_ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../../../utils/constants';
import ActionMenu from '../../../components/common/ActionMenu';
import FilterPopper from '../../../components/common/FilterPopper';
import AssignOrderModal from '../../../components/admin/users/AssignOrderModal';
import { reassignOrder } from '../../../services/order/order';

const Users = () => {
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state?.authSlice);
  const isSuperAdmin = admin?.user_role_name === SUPER_ADMIN_ROLE ? true : false;
  const isClientAdmin = admin?.user_role_name === CLIENT_ADMIN_ROLE ? true : false;
  const isAdmin =
    admin?.user_role_name === ADMIN_ROLE || admin?.user_role_name === SUPER_ADMIN_ROLE
      ? true
      : false;
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [record, setRecord] = useState({});
  const [perPageRecords, setPerPageRecords] = useState(100);
  const [id, setId] = useState('');
  const [total, setTotal] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [anchor, setAnchor] = useState(null);
  const [anchor1, setAnchor1] = useState(null);
  const [anchor2, setAnchor2] = useState(null);
  const [anchor3, setAnchor3] = useState(null);
  const [filters, setFilters] = useState({});
  const [clientList, setClientList] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [offices, setOffices] = useState([]);

  useEffect(() => {
    if (offices?.length) {
      if (offices?.length > 1) {
        let primaryOffice = offices.find((office) => office.is_primary);
        if (primaryOffice && !isAdmin) setSelectedOffice(primaryOffice?.office_id);
      } else {
        if (!isAdmin) {
          setSelectedOffice(offices?.[0]?.office_id);
        }
      }
    }
  }, [offices]);

  useEffect(() => {
    if (selectedOffice && isClientAdmin)
      setFilters((prev) => {
        return { ...prev, client_office_id: String(selectedOffice) };
      });
  }, [selectedOffice]);

  useEffect(() => {
    if (admin?.client_id && isClientAdmin) fetchClient();
  }, []);

  const fetchClient = async () => {
    setLoading(true);
    const res = await getOfficeList(admin?.token, admin?.client_id);
    if (res.success) {
      setOffices(res.response || []);
    }
    setLoading(false);
  };

  const tableData = useMemo(() => {
    if (userList?.length) {
      return userList?.map((user) => {
        return {
          ...user,
          name: (props) => <div>{props.first_name + ' ' + props.last_name}</div>,
          office: (props) => (
            <div>
              {props?.offices?.length
                ? props?.offices?.length > 1
                  ? 'All Offices'
                  : props?.offices?.[0]?.office_name
                : '-'}
            </div>
          ),
          // user_status: () => (
          //   <div>
          //     <Chip
          //       label={user?.status}
          //       style={{
          //         height: '20px',
          //         padding: '2px',
          //         borderRadius: '5px',
          //         textTransform: 'capitalize',
          //       }}
          //       className={`${user?.status !== 'inactive' ? 'bg-p' : 'bg-r'}`}
          //     />
          //   </div>
          // ),
          user_status: () => (
            <div
              className={`${
                user?.status !== 'inactive' ? 'color-p text-capitalize' : 'error text-capitalize'
              }`}
            >
              {user?.status ?? ''}
            </div>
          ),
          actions: (props) => (
            <div>
              <ActionMenu
                actions={[
                  {
                    label: 'Edit User',
                    logo: <Edit className='action-menu-item' />,
                    onClick: () => navigate(`/admin/users/${props.id}`),
                  },
                  {
                    label: 'View User',
                    logo: <Visibility className='action-menu-item' />,
                    onClick: () =>
                      navigate(`/admin/users/${props.id}/view`, { state: { disabled: true } }),
                  },
                  {
                    label: 'Delete User',
                    logo: <Delete className='action-menu-item' color='error' />,
                    onClick: () => {
                      setId(props?.id);
                      setRecord(user);
                      setShowDeleteModal(true);
                    },
                  },
                ]}
              />
            </div>
          ),
        };
      });
    }
    return [];
  }, [userList]);

  useEffect(() => {
    fetchUserList();
    fetchClientList();
  }, [currPage, perPageRecords]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (Object.keys(filters || {})?.length) {
        fetchUserList();
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [filters]);

  const fetchUserList = async () => {
    setLoading(true);
    const res = await getUsers(admin?.token, currPage + 1, perPageRecords, filters);
    if (res?.success) {
      setUserList(res?.response?.data || []);
      setTotal(res?.response?.meta?.total);
    } else {
      setUserList([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const fetchClientList = async () => {
    const res = await getClients(admin?.token);
    if (res?.success) {
      setClientList(
        res?.response.map((client) => {
          return { label: client.full_name, value: client.id };
        }) || []
      );
    }
  };
  const columns = [
    { id: 'actions', label: 'Actions', type: 'jsx' },

    {
      id: 'name',
      label: 'Name',
      type: 'jsx',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Name</div>
            <FilterPopper
              anchor={anchor}
              setAnchor={setAnchor}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='text'
            />
          </div>
        );
      },
    },
    {
      id: 'company_name',
      label: 'Company Name',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Company Name</div>
            <FilterPopper
              anchor={anchor1}
              setAnchor={setAnchor1}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='select'
              options={clientList}
            />
          </div>
        );
      },
    },
    {
      id: 'office',
      label: 'Office Name',
      type: 'jsx',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Office Name</div>
            <FilterPopper
              anchor={anchor3}
              setAnchor={setAnchor3}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        );
      },
    },
    { id: 'user_role_name', label: 'Role' },
    {
      id: 'email',
      label: 'Email',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Email</div>
            <FilterPopper
              anchor={anchor2}
              setAnchor={setAnchor2}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='text'
            />
          </div>
        );
      },
    },
    { id: 'user_status', label: 'Status', type: 'jsx' },
  ];

  const clientAdminColumns = [
    { id: 'actions', label: 'Actions', type: 'jsx' },

    {
      id: 'name',
      label: 'Name',
      type: 'jsx',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Name</div>
            <FilterPopper
              anchor={anchor}
              setAnchor={setAnchor}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='text'
            />
          </div>
        );
      },
    },
    {
      id: 'office',
      label: 'Office Name',
      type: 'jsx',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Office Name</div>
            <FilterPopper
              anchor={anchor3}
              setAnchor={setAnchor3}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        );
      },
    },
    { id: 'user_role_name', label: 'Role' },
    {
      id: 'email',
      label: 'Email',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Email</div>
            <FilterPopper
              anchor={anchor1}
              setAnchor={setAnchor1}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='text'
            />
          </div>
        );
      },
    },
    { id: 'user_status', label: 'Status', type: 'jsx' },
  ];

  const deleteHandler = async () => {
    setLoading(true);
    const res = await deleteUser(id, admin?.token);
    if (res.success) {
      fetchUserList();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    setShowDeleteModal(false);
    setLoading(false);
  };

  const onReassignAndDelete = async (payload) => {
    setLoading(true);
    const res = await reassignOrder(payload, admin?.token);
    if (res.success) {
      await deleteHandler();
    } else {
      setLoading(false);
      toast.error(res.message || 'Something went wrong!');
    }
  };
  const onRowClick = (row) => {
    navigate(`/admin/users/${row.id}`);
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center'>
                <Typography variant='h4' className='mb-0'>
                  Users
                </Typography>
                <div className='flex-row'>
                  {offices?.length > 1 ? (
                    <div className='mr-12 table-select-filter'>
                      <label id='demo-simple-select-label'>Office : </label>
                      <Select
                        sx={{
                          // Reset common styles
                          marginLeft: '4px',
                          width: '150px',
                          // Add any additional styles you need
                        }}
                        defaultValue={selectedOffice}
                        displayEmpty={true}
                        value={selectedOffice}
                        onChange={(e) => {
                          setSelectedOffice(e.target.value);
                        }}
                      >
                        {offices?.map((office) => (
                          <MenuItem value={office.office_id} key={office.office_id}>
                            {office.office_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    ''
                  )}
                  <Button
                    autoFocus
                    onClick={() => {
                      navigate('/admin/users/add');
                    }}
                    variant='contained'
                    color='primary'
                    style={{ padding: '4px 12px' }}
                  >
                    <PersonAdd fontSize='small' className='mr-8' /> ADD User
                  </Button>
                </div>
              </Grid>
            </CardContent>
            <Box>
              <CardContent>
                <Table
                  loader={loading}
                  columns={
                    isSuperAdmin
                      ? columns
                      : isClientAdmin
                      ? clientAdminColumns
                      : clientAdminColumns.filter((column) => column.id !== 'office')
                  }
                  data={tableData}
                  count={total}
                  current_page={currPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                  isClickable={true}
                  onRowClick={onRowClick}
                />
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
      <AssignOrderModal
        open={showDeleteModal}
        record={record}
        setOpen={setShowDeleteModal}
        onSave={onReassignAndDelete}
        onOtherOperations={deleteHandler}
        token={admin?.token}
        note={`<b>Note:</b> You can assign below listed orders(Escrow No.) created by
            ${record.first_name + ' ' + record.last_name} to other available users after deleting
            this user.`}
      />
    </Box>
  );
};
export default Users;
