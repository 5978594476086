import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  Button,
  Typography,
  Box,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Loader from '../../../components/common/Loader';
import logo from '../../../../src/assets/images/Voxtur-verify.png';

const OptAuth = () => {
  const { code } = useParams();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const { control, handleSubmit } = useForm();

  useEffect(() => {
    if (code) fetchData();
  }, [code]);

  const onSubmit = async (data) => {};

  const fetchData = async () => {};

  return (
    <div>
      <Box className='admin-box-wrapper'>
        {loading && <Loader />}
        <Box
          sx={{
            borderRadius: 2,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className='admin-box'
          style={{ width: '470px' }}
        >
          <Box variant='div' component='div' className='admin-logo'>
            <img src={logo} alt='logo-main' loading='lazy' />
          </Box>
          <Typography component='h1' variant='h4' className='login-head-txt'>
            Participant Invite
          </Typography>
          <FormProvider>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ mt: 1 }}
              className='participant-invite-consent'
              style={{ width: '100%' }}
            >
              <Grid container className='mt-16' spacing={1}>
                <Grid item sm={6} md={6} xs={12}>
                  <label>Participant First Name</label>
                  <Controller
                    name={`first_name`}
                    control={control}
                    defaultValue=''
                    render={({ field, fieldState }) => (
                      <>
                        <TextField
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : ''}
                          fullWidth
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item sm={6} md={6} xs={12}>
                  <label>Participant Last Name</label>
                  <Controller
                    name={`last_name`}
                    control={control}
                    defaultValue=''
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12} md={12} xs={12}>
                  <label>Phone Number</label>
                  <Controller
                    name={`mobile_phone`}
                    rules={{
                      pattern: {
                        value: /\(\d{3}\) \d{3}-\d{4}/, // Validate the phone number pattern
                        message: 'Invalid phone number format (e.g., (123) 456-7890)',
                      },
                    }}
                    control={control}
                    defaultValue=''
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <InputMask mask={'(999) 999-9999'} {...field}>
                        {() => (
                          <TextField
                            {...field}
                            fullWidth
                            autoFocus
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : ''}
                          />
                        )}
                      </InputMask>
                    )}
                  />
                </Grid>
                <Grid item sm={12} md={12} xs={12}>
                  <FormControlLabel
                    className='concent-form-checkbox'
                    label={
                      'I consent to receiving One-Time Passwords (OTPs) from VoxturVerify for secure account access. By checking this box, I acknowledge that these OTPs will be sent to my registered mobile number displayed above. Reply STOP to opt-out'
                    }
                    checked={checked}
                    onChange={(event) => {
                      setChecked(event.target.checked);
                    }}
                    style={{ alignItems: 'self-start' }}
                    control={<Checkbox color='primary' />}
                  />
                </Grid>
              </Grid>
              <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                Submit
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Box>
    </div>
  );
};
export default OptAuth;
