import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Download } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: '80vw',
    padding: '0px',
    paddingBottom: '8px',
  },
  '& .MuiDialogActions-root': {
    padding: '0px',
    paddingBottom: '8px',
  },
}));
const DocumentViewer = ({ open, setOpen, title, fileUrl, originalUrl }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = originalUrl;
    link.setAttribute('download', title + '.pdf');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      className='doc-model'
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id='customized-dialog-title'
        className='customized-dialog-title'
        style={{ fontSize: '16px' }}
      >
        {title}
      </DialogTitle>
      {originalUrl?.length ? (
        <Tooltip title='Download document.'>
          <IconButton
            aria-label='close'
            onClick={handleDownload}
            sx={{
              position: 'absolute',
              right: 48,
              top: 8,
              color: 'black',
            }}
          >
            <Download />
          </IconButton>
        </Tooltip>
      ) : (
        ''
      )}
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>

      <DialogContent dividers>
        {fileUrl?.length ? (
          <DocViewer
            documents={[
              {
                uri: fileUrl,
              },
            ]}
            config={{
              pdfZoom: { defaultZoom: window.innerWidth < 500 ? 1.25 : 1 },
            }}
            pluginRenderers={DocViewerRenderers}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        ) : (
          <div className='f-13-5 color-g fw-550 p-16'>Document not available.</div>
        )}
      </DialogContent>
      <DialogActions className='mt-8'>
        <Button
          autoFocus
          onClick={handleClose}
          className='p-2'
          color='secondary'
          style={{ color: 'grey' }}
        >
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default DocumentViewer;
