import { authorizedApi } from '../../utils/api';

export const getOrders = (token, paramString) => {
  return authorizedApi(token).get(`/orders?${paramString}`);
};

export const getParticipantForm = (id, token) => {
  return authorizedApi(token).get(`/admin/order-participant/${id}`);
};

export const getParticipantFormDocuments = (id, token) => {
  return authorizedApi(token).get(`/order-participant-document/${id}`);
};

export const getParticipants = (id, token) => {
  return authorizedApi(token).get(`/admin/order/${id}`);
};

export const getParticipant = (id, token) => {
  return authorizedApi(token).get(`/participant/${id}`);
};

export const verifyElement = (id, data, token) => {
  return authorizedApi(token).put(`/verify/participant/data/${id}`, data);
};

export const resendLink = (data, token) => {
  return authorizedApi(token).post(`/resendorderlink`, data);
};
