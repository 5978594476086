import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: '500px',
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs({ open, setOpen, item, submitFunction }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title' style={{ fontSize: '16px' }}>
          Delete Confirmation
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Typography
            gutterBottom
            className='d-flex align-center ml-2'
            style={{ fontSize: '14px' }}
          >
            <ErrorOutlineIcon color='error' fontSize='small' className='mr-8' />
            Are you sure you want to delete {item || ''}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            className='p-2'
            color='secondary'
            style={{ color: 'grey' }}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={submitFunction}
            variant='contained'
            color='error'
            className='p-2'
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
