import { useNavigate } from 'react-router-dom';

const DashBoardListItem = ({ icon, title, route }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`file-preview mt-16`}
      style={{ background: 'white', cursor: 'pointer', flexDirection: 'column', width: '100%' }}
      onClick={() => {
        navigate(route);
      }}
    >
      <div className='file-icon d-flex align-items-center' style={{ marginLeft: '5px' }}>
        {icon}
      </div>
      <div className='file-details' style={{ cursor: 'pointer' }}>
        <div>{title}</div>
      </div>
    </div>
  );
};
export default DashBoardListItem;
