import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { checkAuthToken } from '../../../services/auth/auth';
import { CLIENT_USER_ROLE, OFFICE_USER_ROLE } from '../../../utils/constants';

const Office = () => {
  const navigate = useNavigate();
  const { office } = useParams();
  const data = useSelector((state) => state?.authSlice);

  useEffect(() => {
    checkAuthenticated();
  }, []);

  const checkAuthenticated = async () => {
    let user_id = '';
    let token = '';

    token = data['admin']?.token;
    user_id = data['admin']?.id;

    if (!token) {
      return navigate(`/${office}/login`, { replace: true });
    }
    let payload = { user_id };

    const res = await checkAuthToken(payload, token);
    if (res?.success) {
      if (data?.user_role_name === OFFICE_USER_ROLE || data?.user_role_name === CLIENT_USER_ROLE) {
        return navigate(`/orders/order`);
      } else {
        return navigate(`/admin/dashboard`);
      }
    } else {
      return navigate(`/${office}/login`, { replace: true });
    }
  };
  return <></>;
};

export default Office;
