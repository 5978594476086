import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

const Label = ({ element }) => {
  const { code } = useParams();
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code];
  const languageForLabel = selectedLanguage && selectedLanguage !== 'en' ? selectedLanguage : '';
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  return (
    <Grid xs={12} md={12} spacing={1} className='grid-container label-padding pr-8 light-bg-field'>
      <Grid xs={12} item={true}>
        <label
          dangerouslySetInnerHTML={{
            __html: languageForLabel
              ? translations?.[languageForLabel]?.elements?.[element.element_id]
                ? translations?.[languageForLabel]?.elements?.[element.element_id]
                : element?.label
              : element?.label,
          }}
        ></label>
      </Grid>
    </Grid>
  );
};
export default Label;
