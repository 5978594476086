import { authorizedApi } from '../../utils/api';

export const getUsers = (token, currPage = 1, per_page = 10, filters) => {
  let str = `page=${currPage}&per_page=${per_page}`;
  if (filters?.company_name) str += `&client_id=${filters?.company_name}`;
  if (filters?.name) str += `&name=${filters?.name}`;
  if (filters?.email) str += `&email=${encodeURIComponent(filters?.email)}`;
  if (filters?.office) str += `&office_name=${filters?.office}`;
  if (filters?.client_office_id) str += `&client_office_id=${filters?.client_office_id}`;
  return authorizedApi(token).get(`/admin/client-user?${str}`);
};

export const getUser = (id, token) => {
  return authorizedApi(token).get(`/admin/client-user/${id}`);
};

export const addUser = (data, token) => {
  return authorizedApi(token).post(`/admin/client-user`, data);
};

export const updateUser = (id, data, token) => {
  return authorizedApi(token).put(`/admin/client-user/${id}`, data);
};

export const deleteUser = (id, token) => {
  return authorizedApi(token).delete(`/admin/client-user/${id}`);
};

export const getClientAdmins = (token, id) => {
  return authorizedApi(token).get(`/get/client/admins/${id}`);
};

export const getClients = (token) => {
  return authorizedApi(token).get(`/admin/get/clients`);
};

export const getTimeZones = (token) => {
  return authorizedApi(token).get(`/admin/timezones`);
};

export const getOfficeList = (token, client_id, parent_admin_id) => {
  let url = `/get/offices/${client_id}`;
  if (parent_admin_id) url += `?client_admin_user_id=${parent_admin_id}`;
  return authorizedApi(token).get(url);
};

export const getOrdersList = (user_id, token) => {
  return authorizedApi(token).get(`/admin/get/user/orders?user_id=${user_id}`);
}; 