import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TableCell } from '@mui/material';

export const SortableItem = ({
  id,
  prevent = false,
  header = false,
  column,
  row,
  rowClassNames,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Translate.toString(transform), // Apply basic transform
    transition: transition, // Smooth transition
    background: isDragging ? 'lightblue' : '', // Highlight dragged column
    cursor: prevent ? 'pointer' : isDragging ? 'grabbing' : 'grab', // Change cursor when dragging
    opacity: isDragging ? 0.9 : 1, // Keep dragged column visible
    fontWeight: 'inherit', // Maintain original font style
    zIndex: isDragging ? 999 : 'auto', // Higher zIndex to keep dragged item on top
    position: isDragging ? 'relative' : 'static', // Ensure positioning doesn't conflict
    boxShadow: isDragging ? '0 2px 5px rgba(0, 0, 0, 0.15)' : 'none',
    padding: '8px',
  };
  return header ? (
    <TableCell ref={setNodeRef} style={style}>
      <div className='d-flex flex-row' style={{ width: column?.width }}>
        <div
          className='handle'
          {...listeners} // Apply drag listeners only to this part
          {...attributes}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            cursor: 'grab',
            width: 'max-content',
          }}
        >
          {column.label}
        </div>
        {column?.render ? column['render'](column) : ''}
      </div>
    </TableCell>
  ) : (
    <TableCell ref={setNodeRef} style={style} className={`${rowClassNames(row)}`}>
      {column.type === 'jsx' ? row[column.id](row) : row[column.id] ? row[column.id] : '-'}
    </TableCell>
  );
};
