import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Drawer,
  Divider,
  styled,
  Tooltip,
  Grid,
  TextField,
  Alert,
  InputAdornment,
  Button,
  Tab,
  Tabs,
} from '@mui/material';
import {
  Close,
  GppMaybeOutlined,
  Help,
  HelpOutline,
  History,
  TaskAlt,
  VerifiedUserOutlined,
} from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import moment from 'moment/moment';
import { SSN_FIELDS } from '../../../utils/constants';
import Table from '../../common/Table';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const SourceDetailsDrawerUpdated = ({
  open,
  setOpen,
  curData,
  sourceList,
  handleBlur,
  formValues,
  setFormValues,
  disabled,
}) => {
  const { pathname } = useLocation();
  const { admin } = useSelector((state) => state?.authSlice);
  const { order } = useSelector((state) => state?.authSlice);
  const user =
    pathname.split('/')[1] === 'admin'
      ? admin?.first_name + ' ' + admin?.last_name
      : order?.client_name && !order?.parent_id
      ? order?.client_name
      : order?.first_name + ' ' + order?.last_name;
  const [elementData, setElementData] = useState(cloneDeep(curData));
  const [notes, setNotes] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const historyDetails = cloneDeep(elementData?.history || []);

  useEffect(() => {
    setElementData(
      cloneDeep({ ...curData, user_value: curData.user_value ? curData.user_value : '' })
    );
    setNotes('');
  }, [curData]);

  const handleChange = (e) => {
    setElementData((data) => {
      return { ...data, user_value: e.target.value };
    });
  };

  let columns = [
    {
      label: 'User',
      id: 'created_by',
    },

    {
      label: 'Note',
      id: 'note',
    },
    {
      label: 'Date',
      id: 'created_at',
    },
  ];

  return (
    <Drawer
      sx={{
        width: window.innerWidth > 800 ? 700 : window.innerWidth - 50,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: window.innerWidth > 800 ? 700 : window.innerWidth - 20,
          boxSizing: 'border-box',
        },
      }}
      anchor='right'
      open={open}
      onClose={() => {
        setTabValue(0);
        setOpen(false);
      }}
    >
      <DrawerHeader style={{ minHeight: '55px' }}>
        <div className='f-14 fw-500'>
          Verification Details :{' '}
          <span className='color-p'>{curData?.label ? curData?.label?.replace(':', '') : ''}</span>{' '}
        </div>
        {/* {elementData?.is_verified_with_source || elementData?.verified_with_source_by_user_id ? (
          <Tooltip title='This field is verified.'>
            <VerifiedUserOutlined fontSize='small' className='color-s cursor-pointer ml-4' />
          </Tooltip>
        ) : (
          <Tooltip title='This field is not verified.'>
            <GppMaybeOutlined fontSize='small' className='color-w cursor-pointer ml-4' />
          </Tooltip>
        )} */}
        <Close
          sx={{ position: 'absolute', right: '10px', top: '14px', color: 'grey' }}
          fontSize='small'
          onClick={() => {
            setTabValue(0);
            setOpen(false);
          }}
          className='cursor-pointer'
        />
      </DrawerHeader>
      <Divider />
      <div style={{ overflowY: 'scroll', height: '80vh' }}>
        <div>
          <div>
            {/* {(elementData?.verified_with_source_by_user_id ||
              elementData?.is_verified_with_source) && (
              <Alert
                severity='success'
                // variant='outlined'
                className='mt-24'
                style={{
                  fontSize: '13.5px',
                  padding: '0px 10px',
                }}
              >
                This field is verified
                {elementData?.is_verified_with_source
                  ? ' by ' +
                    (sourceList[elementData?.verifiedSource]
                      ? sourceList[elementData?.verifiedSource]
                      : elementData?.verifiedSource) +
                    ' source'
                  : ' manually'}
              </Alert>
            )}
            {elementData?.rejected_by && (
              <Alert
                severity='warning'
                // variant='outlined'
                className='mt-24'
                style={{
                  fontSize: '13.5px',
                  padding: '0px 10px',
                }}
              >
                This field is rejected by Admin.
              </Alert>
            )} */}
            <div className='p-16 source-details-modal'>
              <Divider textAlign='left' className='f-13 color-g'>
                Public Information
              </Divider>
              <div className='f-13-5 mt-5 fw-600'>Note : </div>
              <div className='f-13 mt-8 mb-16 color-g' style={{ fontStyle: 'italic' }}>
                {elementData?.sources?.length
                  ? `These below listed values fetched from different sources and you can use this values for reviewing the field`
                  : `No public information found for this field. You need to verify this field manually.`}
              </div>

              <Grid
                container
                className='client-user-form form-field-container'
                columnSpacing={1}
                rowSpacing={1}
              >
                {elementData?.sources?.length
                  ? elementData?.sources?.map((data, index) => {
                      return (
                        <Grid
                          container
                          className={index !== 0 ? 'mt-8' : 'mt-16'}
                          style={{ marginLeft: '8px' }}
                        >
                          <Grid
                            item
                            md={3}
                            sm={3}
                            xs={3}
                            className='f-13 fw-500 p-8 d-flex align-items-center'
                            border={'1px solid rgba(50, 50, 93, 0.25)'}
                            borderRight={'0px'}
                          >
                            {sourceList[data?.source] ? sourceList[data?.source] : data?.source}{' '}
                            {sourceList?.[data?.source] &&
                            sourceList?.[data?.source] === 'Provider TX' ? (
                              <Tooltip
                                title={
                                  'This is the value from the field when the order was initially created.'
                                }
                              >
                                <Help
                                  style={{ fontSize: '16px', marginLeft: '2px' }}
                                  className='cursor-pointer'
                                />
                              </Tooltip>
                            ) : (
                              ''
                            )}
                          </Grid>
                          <Grid
                            item
                            md={9}
                            sm={9}
                            xs={9}
                            className='d-flex justify-end f-13 p-8'
                            border={'1px solid rgba(50, 50, 93, 0.25)'}
                          >
                            {data?.value}
                          </Grid>
                        </Grid>
                      );
                    })
                  : ''}
              </Grid>
              <Divider textAlign='left' className='f-13 color-g mt-16'>
                Participant's Input
              </Divider>
              <Grid
                container
                className='client-user-form form-field-container p-8'
                columnSpacing={1}
                rowSpacing={1}
              >
                <div className='f-13 mt-8 mb-16 color-g' style={{ fontStyle: 'italic' }}>
                  You can update and verify the input value from the participant for this field.
                </div>
                <TextField
                  style={{ height: '30px' }}
                  className={`${
                    formValues[curData?.element_id] &&
                    formValues[curData?.element_id]?.trim(' ') !== curData?.user_value
                      ? 'updated-field'
                      : ''
                  }`}
                  value={
                    SSN_FIELDS.find((field) => elementData?.element_name?.startsWith(field))
                      ? elementData?.ssn_ein_masked_number
                      : formValues[curData?.element_id] || curData?.user_value
                  }
                  onChange={(e) => {
                    setFormValues((prev) => {
                      return { ...prev, [curData?.element_id]: e.target.value };
                    });
                  }}
                  disabled={
                    disabled ||
                    SSN_FIELDS.find((field) => elementData?.element_name?.startsWith(field))
                  }
                  onBlur={() => {
                    handleBlur(curData);
                  }}
                />
              </Grid>
            </div>
          </div>
        </div>

        <div className='p-16'>
          <Divider textAlign='left' className='f-13 color-g'>
            Notes & History
          </Divider>

          <Table
            columns={columns}
            // data={allElements?.[section]?.map((curData) => {
            //   return { label: curData?.label, value: curData?.user_value };
            // })}
            data={[...historyDetails, ...historyDetails]}
            hidePagination={true}
          />

          {/* <Grid container className='note-container' style={{ boxShadow: 'none' }}>
             <Grid item md={3} sm={3} xs={3} className='f-14 fw-500 p-4'>
              User
            </Grid>
            <Grid
              item
              md={2}
              sm={2}
              xs={2}
              className='f-14 fw-500 p-4'
              style={{ wordWrap: 'break-word' }}
            >
              Status
            </Grid>
            <Grid
              item
              md={5}
              sm={5}
              xs={5}
              className='f-14 fw-500 p-4'
              style={{ wordWrap: 'break-word' }}
            >
              Note
            </Grid>
            <Grid item md={2} sm={2} xs={2} className='f-14 fw-500 p-4'>
              Date
            </Grid>
          </Grid>
          <div>
            {historyDetails?.length ? (
              (historyDetails?.reverse() || [])?.map((note) => {
                return (
                  <Grid container className='note-container color-g'>
                    <Grid item md={3} sm={3} xs={3} className='f-13 p-4'>
                      {note?.created_by}
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sm={2}
                      xs={2}
                      className='f-13 p-4'
                      style={{ wordWrap: 'break-word' }}
                    >
                      {note?.approval ? 'Verified' : 'Rejected'}
                    </Grid>
                    <Grid
                      item
                      md={5}
                      sm={5}
                      xs={5}
                      className='f-13 p-4'
                      style={{ wordWrap: 'break-word' }}
                    >
                      {note?.note?.length ? note?.note : '-'}
                    </Grid>

                    <Grid item md={2} sm={2} xs={2} className='f-13 p-4'>
                      {note?.created_at}
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid item md={3} className='f-13 p-4 d-flex justify-center color-g mt-16'>
                No history available.
              </Grid>
            )}
          </div> */}
        </div>
      </div>
    </Drawer>
  );
};

export default SourceDetailsDrawerUpdated;
