import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { TextField, Button, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loader';
import logo from '../../../src/assets/images/Voxtur-verify.png';
import { forgotPassword } from '../../services/auth/auth';
import { useSelector } from 'react-redux';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const url = window.location.origin;
  const config = useSelector((state) => state.authSlice)?.configuration?.[url];
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    let payload = { ...data, panel: state?.portal, domain: url };
    if (config?.client_id) payload.client_id = config.client_id;
    if (config?.client_office_id) payload.client_office_id = config.client_office_id;
    const resData = await forgotPassword(payload);
    if (resData?.success) {
      toast.success(resData?.message);
      config?.office_name
        ? navigate(`/${config?.office_name.replace(/ /g, '')}/login`)
        : navigate('/login');
    } else {
      if (resData?.response?.domain?.length) {
        toast.error(
          `You are not allowed to continue with this URL. We are redirecting you to your client's URL.`
        );
        setTimeout(() => {
          window.location.replace(`${resData?.response?.domain}/login`);
          setLoading(false);
        }, 5000);
        return;
      }
      toast.error(resData?.message || 'Something went wrong!');
    }
    setLoading(false);
  };

  return (
    <div>
      <Box className='admin-box-wrapper p-0'>
        {loading && <Loader />}
        <Box
          sx={{
            borderRadius: 2,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className='admin-box'
        >
          <Box variant='div' component='div' className='admin-logo'>
            <img
              src={config?.company_logo_url ? config?.company_logo_url : logo}
              alt='logo-main'
              loading='lazy'
              className='logo-size'
            />
          </Box>
          <Typography component='h1' variant='h4' className='login-head-txt'>
            Forgot Password
          </Typography>
          <div className='f-13-5 mt-12 mb-8 fw-400 color-g'>
            Please enter your registered email here we will send you link to reset your password.
          </div>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
            className='reset-pwd'
            style={{ width: '100%' }}
          >
            <label className='login-label color-g'>Email</label>
            <label className='required'>{'*'}</label>
            <TextField
              margin='normal'
              required
              fullWidth
              name='email'
              className='mt-8 mb-0'
              type={'text'}
              id='email'
              autoComplete='current-password'
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^\S+@\S+\.\S+$/,
                  message: 'Invalid email format',
                },
              })}
            />
            {errors.email && <span className='error'>{errors.email.message}</span>}

            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default ForgotPassword;
