import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '8px 16px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

const VerificationConfirmationModal = ({
  open,
  setOpen,
  onVerify,
  selectedFields,
  setSelectedFields,
  isAll,
}) => {
  const handleClose = () => {
    setSelectedFields([]);
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          style={{ fontSize: '16px' }}
          className='p-12'
        >
          <div className='d-flex align-items-center'>
            <div>Verification Confirmation</div>
          </div>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers className='p-16'>
          {isAll ? (
            <div className='f-13-5 fw-500'>
              Are you sure you want to verify all remaining pending fields?{' '}
              <b>
                The participant form will be marked as verified, and you won't be able to make
                further changes in verifications.{' '}
              </b>
            </div>
          ) : (
            <div className='f-13-5 fw-500'>
              Are you sure you want to verify all below listed pending fields ?
            </div>
          )}
          {selectedFields?.length ? (
            <Grid container className=''>
              {selectedFields.map((field, index) => {
                return (
                  <Grid container className={index !== 0 ? 'mt-8' : 'mt-16'}>
                    <Grid
                      item
                      md={8}
                      className='f-13 fw-500 p-8'
                      border={'1px solid rgba(50, 50, 93, 0.25)'}
                      borderRight={'0px'}
                    >
                      {field?.label}
                    </Grid>
                    <Grid
                      item
                      md={4}
                      className='d-flex justify-end f-13 p-8'
                      border={'1px solid rgba(50, 50, 93, 0.25)'}
                    >
                      {field?.user_value}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions className='p-10'>
          <Button
            autoFocus
            onClick={handleClose}
            className='p-2'
            color='secondary'
            style={{ color: 'grey', marginRight: '4px' }}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color='primary'
            variant='contained'
            className='p-4'
            onClick={() => {
              onVerify(selectedFields, isAll);
            }}
          >
            Verify
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default VerificationConfirmationModal;
